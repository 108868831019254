import {
    baseApi,
    Response,
    ListResponse,
    ObjectResponse,
} from './baseApi';

export interface Firmware {
    id: number;
    name: string;
}

export interface MeterInfo {
    id: number;
    firmware: string;
    isRetrieving: boolean;
}


const prefix = 'meters';

export const meterApi = baseApi.injectEndpoints({
    endpoints: (build) => ({
        listFirmware: build.query<Array<Firmware>, any>({
            query: () => {
                return {
                    url: `${prefix}/firmwares`,
                }
            }
        }),
        retrieveFirmware: build.mutation<any, any>({
            query: (params) => {
                return {
                    url: `${prefix}/retrieveFirmware`,
                    method: 'POST',
                    body: {
                        "objectId": params.objectId,
                        "objectType": params.objectType,
                    },
                }
            }
        }),
        getInfo: build.query<MeterInfo, any>({
            query: (params) => {
                return {
                    url: `${prefix}/${params.id}/info`,
                }
            }
        }),
    }),
    overrideExisting: false,
});

export const {
    useListFirmwareQuery: useListFirmware,
    useRetrieveFirmwareMutation: useRetrieveFirmware,
    useGetInfoQuery: useGetInfo,
} = meterApi;
