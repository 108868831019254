import React, { useEffect } from 'react';
import {
  Routes,
  Route,
} from "react-router-dom";
import AuthLayout from './layout/AuthLayout';
import MainLayout from './layout/MainLayout';
import LoginScene from './scenes/LoginScene';
import DashboardScene from './scenes/DashboardScene';
import MapScene from './scenes/MapScene';
import MeterScene from './scenes/MeterScene';
import CustomerScene from './scenes/CustomerScene';
import YTLNodeComparisonScene from './scenes/YTLNodeComparisonScene';
import {
  AuthProvider,
  ComparisonProvider,
  DashboardProvider,
  EventProvider,
  MapProvider,
} from './Providers';
import { RequireAuth } from './utils/RequireAuth';

import './App.css';
import YTLRateComparisonScene2 from './scenes/YTLRateComparisonScene2';
// import YTLRateComparisonScene from './scenes/YTLRateComparisonScene';
import YTLRegionComparisonScene2 from './scenes/YTLRegionComparisonScene2';
// import YTLRegionComparisonScene from './scenes/YTLRegionComparisonScene';

const App: React.FC = () => {
  return (
    <AuthProvider>
      <EventProvider>
      <DashboardProvider>
      <MapProvider>
      <ComparisonProvider>
      <Routes>
        <Route path='/login' element={<AuthLayout />}>
          <Route index element={<LoginScene />} />
        </Route>
        <Route path='/app' element={<MainLayout />}>
          <Route path="dashboard" element={
            <RequireAuth>
              <DashboardScene />
            </RequireAuth>
            } />
          <Route path="maps" element={
            <RequireAuth>
              <MapScene />
            </RequireAuth>
            } />
          <Route path="meter" element={
            <RequireAuth>
              <MeterScene />
            </RequireAuth>
            } />
          <Route path="customer" element={
            <RequireAuth>
              <CustomerScene />
            </RequireAuth>
            } />
          <Route path="comparison" element={
            <RequireAuth>
              <YTLNodeComparisonScene />
            </RequireAuth>
            } />
          <Route path="properties/rate" element={
            <RequireAuth>
              {/* <PropertyComparisonScene /> */}
              <YTLRateComparisonScene2 />
            </RequireAuth>
            } />
          <Route path="properties/region" element={
            <RequireAuth>
              {/* <PropertyComparisonScene2 /> */}
              <YTLRegionComparisonScene2 />
            </RequireAuth>
            } />
        </Route>
        <Route path='*' element={<AuthLayout />}>
          <Route index element={<LoginScene />} />
        </Route>
      </Routes>
      </ComparisonProvider>
      </MapProvider>
      </DashboardProvider>
      </EventProvider>
    </AuthProvider>
  );
}

export default App;
