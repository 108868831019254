import React from 'react';

interface WidgetProps {
    title: string;
    renderControl?: () => React.ReactNode;
}
export const Widget: React.FC<WidgetProps> = (props) => {
    return (
        <div className="widget" style={{ height: 316 }}>
            <div className="header">
                <span className='subtitle subtitle--bigger-1'>{props.title}</span>
                {props.renderControl && props.renderControl()}
            </div>
            <div className="body">
                {props.children}
            </div>
        </div>
    );
};
