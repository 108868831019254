import {
    Spin,
    Tag,
    notification,
    Empty,
    Tabs,
} from 'antd';
import ProjectTree from './ProjectTree';
import ProjectTreeByCustType from './ProjectTreeByCustType';
import CustomerGroupFilter from './CustomerGroupFilter';
import CustomerGroupFilter2 from './CustomerGroupFilter2';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';



const { TabPane } = Tabs;


const ProjectTreeContainer: React.FC = () => {
    let navigate = useNavigate();

    return (
        <Tabs style={{flex: 1}} onChange={(activeKey) => {
            if (activeKey === "2") {
                navigate('/app/properties/rate');
            }
            if (activeKey === "1") {
                navigate('/app/dashboard');
            }
            if (activeKey === "3") {
                navigate('/app/properties/region');
            }
        }}>
        <TabPane key="1" tab="Nodes" style={{ flex: 1}}>
            <ProjectTree></ProjectTree>
        </TabPane>
        <TabPane key="2" tab="Rates"  style={{ flex: 1}}>
            <CustomerGroupFilter></CustomerGroupFilter>
        </TabPane>
        <TabPane key="3" tab="Regions"  style={{ flex: 1}}>
            <CustomerGroupFilter2></CustomerGroupFilter2>
        </TabPane>

        {/* <TabPane key="3" tab="TSIC">
        </TabPane> */}
    </Tabs>
    )
}

export default ProjectTreeContainer;