import {
    baseApi,
    Response,
    ListResponse,
} from './baseApi';


export interface Widget {
    id: number;
    name: string;
    view: string;
    x: number;
    y: number;
    w: number;
    h: number;
}

export interface Account {
    success: string;
    accountid: string;
    username: string;
    authtype: string;
    hometype: string;
    HTTP_ACCESS_TOKEN: string;
    isAdmin: boolean;
}

const prefix = '/commserver/AuthService';

export const widgetApi = baseApi.injectEndpoints({
    endpoints: (build) => ({
        listWidgets: build.query<Widget[], string | undefined>({
            query: () => '/widgets',
            providesTags: ['Widget'],
            transformResponse: (response: Widget[], meta, arg) => 
                response,
        }),
        updateWidgets: build.mutation<Widget[], Widget[]>({
            query: (widgets) => {
                return {
                    url: `/widgets`,
                    method: 'POST',
                    body: {
                        widgets: widgets.map(w => ({
                            id: w.id,
                            name: w.name,
                            view: "",
                            w: w.w,
                            h: w.h,
                            x: w.x,
                            y: w.y,
                        }))
                    },
                }
            },
            invalidatesTags: ['Widget'],
            transformResponse: (response: ListResponse<Widget>, meta, arg) => response.listitem,
        }),
        signIn: build.mutation<Account, any>({
            query: (body) => {
                return {
                    url: `${prefix}/loginIn`,
                    method: 'POST',
                    body,
                }
            },
            transformResponse: (response: Response<Account>, meta, arg) => response.data,
        })
    }),
    overrideExisting: false,
});

export const {
    useListWidgetsQuery: useListWidgets,
    useSignInMutation: useSignIn,
    useUpdateWidgetsMutation: useUpdateWidgets,
} = widgetApi;
