import {
    createApi,
} from '@reduxjs/toolkit/query/react';
import {
  BaseQueryFn,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query';
import { Mutex } from 'async-mutex';
import { useAppDispatch } from '../hooks';
import type { RootState } from '../store';
import { setIsTokenExpired  } from '../slices/authSlices';


export interface ListResponse<T> {
  readonly listitem: Array<T>;
  readonly success: number;
}

export interface ObjectResponse<T> {
  readonly item: Array<T>;
  readonly success: number;
}

export interface Response<T> {
  readonly code: number;
  readonly msg: string;
  readonly data: T;
}

export interface Pagination<T> {
  readonly objs: Array<T>;
  readonly total: number;
}

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_BASE_URL,
  prepareHeaders: (headers, { getState, endpoint }) => {
    const auth = (getState() as RootState).auth;
    if (auth && endpoint !== 'signIn') {
      headers.set('Authorization', `Bearer ${auth.token}`);
    }
    headers.set('Content-Type', 'application/json');
    headers.set('ACCEPT', 'application/json');
    return headers;
},
});

// Create a new mutex
const mutex = new Mutex();

const customFetchBase: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  // wait until the mutex is available without locking it
  await mutex.waitForUnlock();

  if (!mutex.isLocked()) {
    const release = await mutex.acquire();
    const auth = (api.getState() as RootState).auth;
    if (auth.token) {
      api.dispatch(setIsTokenExpired({ token: auth.token }));
    }
    release();
  }
  let result = await baseQuery(args, api, extraOptions);
  // if ((result.error?.data as any)?.message === 'You are not logged in') {
  //   if (!mutex.isLocked()) {
  //     const release = await mutex.acquire();
  //     const auth = (api.getState() as RootState).auth;
  //     if (auth.token) {
  //       api.dispatch(setIsTokenExpired({ token: auth.token }));
  //     }
  //     try {
  //       const refreshResult = await baseQuery(
  //         { credentials: 'include', url: 'auth/refresh' },
  //         api,
  //         extraOptions
  //       );

  //       if (refreshResult.data) {
  //         // Retry the initial query
  //         result = await baseQuery(args, api, extraOptions);
  //       } else {
  //         api.dispatch(logout());
  //         window.location.href = '/login';
  //       }
  //     } finally {
  //       // release must be called once the mutex should be released again.
  //       release();
  //     }
  //   } else {
  //     // wait until the mutex is available without locking it
  //     await mutex.waitForUnlock();
  //     result = await baseQuery(args, api, extraOptions);
  //   }
  // }

  return result;
};

export const baseApi = createApi({
  baseQuery: customFetchBase,
  tagTypes: ['Widget'],
  endpoints: () => ({}),
});

export default baseApi;
