import React, { ReactNode, useEffect, useState } from 'react';
import {
    Responsive,
    WidthProvider,
    Layout,
    Layouts,
} from 'react-grid-layout';
import {
    Button,
    Dropdown,
    Menu,
    Modal,
} from 'antd';
import {
    AlertIcon,
    ResizeLargeIcon,
    MinusIcon,
} from '../icons';
import './Grid.css';


const ResponsiveGridLayout = WidthProvider(Responsive);

export enum WidgetTypeDirection {
    horizontal,
    vertical,
}

export interface WidgetItem {
    readonly name: string;
    readonly key: string;
    // readonly render: (reload?: boolean, onStateChanged?: (isFetching: boolean) => void) => ReactNode;
    readonly render: (props?: any) => ReactNode;
    readonly renderControl?: (item: WidgetItem, index: number) => ReactNode;
    readonly x: number;
    readonly y: number;
    readonly w: number;
    readonly h: number;
    readonly hOriginal?: number;
    readonly direction: WidgetTypeDirection;
    readonly props?: any;
}

export enum GridMode {
    view,
    resizeMove,
    remove,
}

// lg: 12, md: 10, sm: 6, xs: 4, xxs: 2
export const horizontalWidgetSizeTypes: { [Key: string]: any } = {
    lg: {
        small: {
            w: 4,
            h: 2,
        },
        medium: {
            w: 6,
            h: 3,
        },
        large: {
            w: 12,
            h: 3,
        }
    },
    md: {
        small: {
            w: 4,
            h: 2,
        },
        medium: {
            w: 6,
            h: 3,
        },
        large: {
            w: 12,
            h: 3,
        }
    },
    sm: {
        small: {
            w: 3,
            h: 2,
        },
        medium: {
            w: 3,
            h: 2,
        },
        large: {
            w: 3,
            h: 2,
        }
    },
    xs: {
        small: {
            w: 3,
            h: 2,
        },
        medium: {
            w: 3,
            h: 2,
        },
        large: {
            w: 3,
            h: 2,
        }
    }
}

export const verticalWidgetSizeTypes: { [Key: string]: any } = {
    lg: {
        small: {
            w: 4,
            h: 4,
        },
        medium: {
            w: 6,
            h: 6,
        },
    },
    md: {
        small: {
            w: 4,
            h: 4,
        },
        medium: {
            w: 6,
            h: 6,
        },

    },
    sm: {
        small: {
            w: 3,
            h: 4,
        },
        medium: {
            w: 3,
            h: 4,
        },
    },
    xs: {
        small: {
            w: 3,
            h: 4,
        },
        medium: {
            w: 3,
            h: 4,
        },
    }
}

enum WidgetSizeType {
    small = 'small',
    medium = 'medium',
    large = 'large',
}

const getLayouts = (sizeType: WidgetSizeType, direction: WidgetTypeDirection, collapsed: boolean = false): { [Key: string]: {} } => {
    console.log('collapse', collapsed)
    const template = direction === WidgetTypeDirection.horizontal ? horizontalWidgetSizeTypes : verticalWidgetSizeTypes;
    return Object.keys(template).reduce((acc, key) => ({ ...acc, [key]: (collapsed ? { w: 4, h: 1 } : template[key][sizeType]) }), {});
}

interface Props {
    mode: GridMode;
    items: WidgetItem[];
    addedItems?: WidgetItem[];
    resetCount: number;
    onDelete?: (item: WidgetItem) => void;
    onChanged?: (items: WidgetItem[]) => void;
}

const WidgetGrid: React.FC<Props> = (props) =>  {
    const [widgets, setWidgets] = useState<WidgetItem[]>([]);
    const [widgetsMap, setWidgetMap] = useState<{ [Key in string]: WidgetItem }>({});
    const [layouts, setLayouts] = useState<Layouts>({});

    useEffect(() => {
        setTimeout(
            ()=>{window.dispatchEvent(new Event('resize'));},
            100,
            );
    }, []);

    useEffect(() => {
        init(props.items);
    }, [props.items, props.resetCount]);

    useEffect(() => {
        if (props.addedItems) {
            init([...widgets, ...props.addedItems]);
        }
    }, [props.addedItems])

    const init = (items: WidgetItem[]) => {
        setWidgets(items);
        setWidgetMap(items.reduce((acc, item) => ({...acc, [item.key]: item }), {}));
        const mapLayout = (item: WidgetItem) => ({
            i: item.key,
            x: item.x,
            y: item.y,
            w: item.w,
            h: item.h,
        });

        setLayouts({
            md: items.map(mapLayout),
            lg: items.map(mapLayout),
        });
    }

    const onRemoveItem = (item: WidgetItem) => {
        Modal.confirm({
            title: 'DELETE',
            icon: <AlertIcon size={24}/>,
            content: `Do you want to delete ${item.name} widget?`,
            okText: 'DELETE',
            okType: 'danger',
            cancelText: 'Cancel',
            onOk() {
                setWidgets(widgets.filter(widget => widget.key !== item.key));
                setLayouts({
                    md: layouts.md.filter(layout => layout.i !== item.key),
                    lg: layouts.lg.filter(layout => layout.i !== item.key),
                })
                if (props.onDelete) {
                    props.onDelete(item);
                }
            },
          });
    }

    const resizeLayout = (target: WidgetItem, type: WidgetSizeType) => {
        const template =  getLayouts(type, target.direction, target.props.collapsed);
        const newLayouts: Layouts = {
            md: layouts.md.map(layout => layout.i === target.key ? { ...layout, ...template['md'] } as Layout : layout),
            lg: layouts.lg.map(layout => layout.i === target.key ? { ...layout, ...template['lg'] } as Layout : layout),
        }
        setLayouts(newLayouts);
    }

    const renderControl = (item: WidgetItem, index: number) => {
        let sizeTypes = [
            WidgetSizeType.small,
            WidgetSizeType.medium,
        ];

        if (item.direction === WidgetTypeDirection.horizontal) {
            sizeTypes = [
                ...sizeTypes,
                WidgetSizeType.large,
            ]
        }

        const menu = (
            <Menu>
                {sizeTypes.map(type =>
                    <Menu.Item
                        key={`menu-type-${type}`}
                        onMouseDown={e => e.stopPropagation()}
                        onClick={() => resizeLayout(item, type)}>
                          {type.toUpperCase()}
                    </Menu.Item>)
                }
            </Menu>
        );

        switch (props.mode) {
            case GridMode.resizeMove:
                return (
                    <Dropdown overlay={menu} placement="bottomRight" trigger={['click']} arrow>
                        <Button
                            type="text"
                            icon={<ResizeLargeIcon size={16} />}
                            onMouseDown={e => e.stopPropagation()}
                            onTouchStartCapture={e => e.stopPropagation()}
                            onTouchStart={e => e.stopPropagation()}
                        />
                    </Dropdown>
                );
            case GridMode.remove:
                return (
                    <Button
                        type="text"
                        icon={<MinusIcon size={30} />}
                        onMouseDown={ e => e.stopPropagation() }
                        onClick={onRemoveItem.bind(this, item)}
                />);
            case GridMode.view:
                return item.renderControl && item.renderControl(item, index);
        }
    }

    return (
        <ResponsiveGridLayout
            className={["layout", props.mode === GridMode.view ? "readonly" : ""].join(" ")}
            breakpoints={{ lg: 992, md: 768, sm: 576, xs: 575.98, xxs: 0 }}
            cols={{ lg: 12, md: 8, sm: 1, xs: 1, xxs: 1 }}
            containerPadding={{ lg: [32, 32], md: [32, 32], sm: [16,16], xs: [16, 16], xxs: [16, 16] }}
            onLayoutChange={(layout: Layout[]) => {
                if (props.onChanged) {
                    props.onChanged(layout.map(({ i, x, y, w, h }) => ({ ...widgetsMap[i], x, y, w, h })));
                }
            }}
            isResizable={false}
            isDraggable={props.mode === GridMode.resizeMove}
            margin={{ lg: [16,16], md: [16,16], sm: [10,10], xs: [10,10], xxs: [10,10] }}
            layouts={layouts}
        >
            {widgets.map((item, index) => (
                <div
                    key={item.key}
                    className={["widget", props.mode === GridMode.view ? "readonly" : "", item.props.collapsed ? "collapsed" : ""].join(" ")}
                    >
                        <div className="header">
                            <span className='subtitle subtitle--bigger-1'>{item.name}</span>
                            {renderControl(item, index)}
                        </div>
                        <div className="body">
                            {item.render(item.props)}
                        </div>
                </div>
                )
            )}
        </ResponsiveGridLayout>
    );
}

export default WidgetGrid;
