
export const getAxisMaxValue = (vals: number[]) => {
    const maxVal = vals.reduce((max, current) => Math.max(max, current), 0);
    const YmaxVal = maxVal * 4 / 3
    return maxVal < 1 ? Math.ceil(YmaxVal * 100) / 100: Math.round(YmaxVal)
}

export const getMarkSize = (value: number) => {
    const digits = String(value).length
    if (digits > 4) {
      return 50 + (digits - 4) * 10
    } else {
      return 50;
    }
}

export const getColor = (value: number) => {
  const index = Math.max(0, value);
  const colorSet = [
    '#FF6A00',
    '#FFC366',
    '#5E2726',
    '#D2DC44',
    '#FF4200',
  ];

  return colorSet[index % colorSet.length];
}


export const getColorByStatus = (name: string) => {
  switch (name) {
      case 'online':
          return '#43B73A';
      case 'offline':
          return '#FF6A00';
      default:
          return '#FF9B00';
  }
}

const renderSeriesInfo = (name: string, value: string, detail: string, color: string) => {
  return (`
    <div style="margin: 10px 0 0;line-height:1;">
      <div style="margin: 0px 0 0;line-height:1;">
          <span
              style="display:inline-block;margin-right:4px;border-radius:10px;width:10px;height:10px;background-color:${color};"></span>
          <span style="font-size:14px;color:#666;font-weight:400;margin-left:2px">${name}</span>
          <span style="float:right;margin-left:20px;font-size:14px;color:#666;font-weight:900">${value === null ? '-' : value}</span>
          <span>[${detail}]</span>
          <div style="clear:both"></div>
      </div>
      <div style="clear:both"></div>
    </div>`
  );
}

export const renderInfo = (name: string, series: Array<any>, getDetail: (serie: any) => string) => {
  return (`
  <div style="margin: 0px 0 0;line-height:1;">
    <div style="margin: 0px 0 0;line-height:1;">
        <div style="font-size:14px;color:#666;font-weight:400;line-height:1;">${name}</div>
        <div style="margin: 10px 0 0;line-height:1;">
            ${series.map(serie => {
              return renderSeriesInfo(serie.seriesName, serie.data, getDetail(serie), serie.color);
            }).join('')}
            <div style="clear:both"></div>
        </div>
        <div style="clear:both"></div>
    </div>
    <div style="clear:both"></div>
  </div>
  `)
}


const renderSeriesInfo2 = (name: string, value: string, color: string) => {
  return (`
    <div style="margin: 10px 0 0;line-height:1;">
      <div style="margin: 0px 0 0;line-height:1;">
          <span
              style="display:inline-block;margin-right:4px;border-radius:10px;width:10px;height:10px;background-color:${color};"></span>
          <span style="font-size:14px;color:#666;font-weight:400;margin-left:2px">${name}</span>
          <span style="float:right;margin-left:20px;font-size:14px;color:#666;font-weight:900">${value === null ? '-' : value}</span>
          <div style="clear:both"></div>
      </div>
      <div style="clear:both"></div>
    </div>`
  );
}

export const renderInfo2 = (name: string, series: Array<any>, getDetail: (serie: any) => string, fomatter?:(val: number) => string) => {
  return (`
  <div style="margin: 0px 0 0;line-height:1;">
    <div style="margin: 0px 0 0;line-height:1;">
        <div style="font-size:14px;color:#666;font-weight:400;line-height:1;">${name}</div>
        <div style="margin: 10px 0 0;line-height:1;">
            ${series.map(serie => {
              const val = fomatter ? fomatter(serie.data) : serie.data
              return renderSeriesInfo2(serie.seriesName, val, serie.color);
            }).join('')}
            <div style="clear:both"></div>
        </div>
        <div style="clear:both"></div>
    </div>
    <div style="clear:both"></div>
  </div>
  `)
}

export function to2DigitFixed(val: number) {

  var matches = val.toString().match(/^-?\d+(?:\.\d{0,2})?/)
  var with2Decimals = matches && matches.length > 0 ? matches[0] : val.toFixed(2);
  return with2Decimals;
}

export function to3DigitFixed(val: number) {

  var matches = val.toString().match(/^-?\d+(?:\.\d{0,3})?/)
  var with2Decimals = matches && matches.length > 0 ? matches[0] : val.toFixed(3);
  return with2Decimals;
}

export function formatLPValue(val: number | null, lpType: string) {
  if (val === null) {
    return '-';
  }

  switch (lpType.toLowerCase()) {
    case 'kwh':
    case 'kvarh':
    case 'import kwh':
    case 'import kvarh':
    case 'export kwh':
    case 'export kvarh':
      return to2DigitFixed(val);
    case 'kw':
    case 'kvar':
    case 'import kw':
    case 'import kvar':
    case 'export kw':
    case 'export kvar':
      return to3DigitFixed(val);
  }
  return val.toString();
}

export function getDivider(data: number[]) {
  if (data.length === 0) {
    return 1;
  }
  const f = data.filter(v => v !== 0);
  if (f.length === 0) {
    return 1;
  }
  const minVal = f.reduce((min, current) => Math.min(min, current));
  if (minVal / 1000000000 > 1) {
      return 1000000000;
  }
  if (minVal / 1000000 > 1) {
      return 1000000;
  }
  if (minVal / 1000 > 1) {
      return 1000;
  }
  return 1;
}

export function getPrefix(val: number) {
  switch (val) {
    case 1000:
      return 'k';
    case 1000000:
      return 'M';
    case 1000000000:
      return 'G';
    default:
      return '';
  }
}


export function startFromZero(startVal?: number) {
  if (startVal === undefined) {
    return (r: any) => r;
  }

  function x(item: Array<number | Date | null>, index: number, arr: Array<Array<number | Date | null>>) {
    const  [ts, val, ...rest] = item;
    let prevVal = 0;
    if (index === 0) {
      prevVal = parseFloat(startVal as unknown as string);
    }
    else {
      const [pts, pval, ...r] = arr[index -  1];
      prevVal = (pval as number) ?? 0;
    }
    const diffVal = (val as number) - prevVal;
    const newVal = (diffVal >= 0 && prevVal > 0) ? Number(diffVal.toFixed(2)) : null;
    return [ts, newVal, ...rest];
  }
  return x;
}
