import React, { useEffect } from 'react';
import ReactECharts from 'echarts-for-react';
import Reloader from './Reloader';
import {
    TimeDurationType,
    listMeterEnergyByType,
    NodeParams,
} from '../services/meterDataApi';
import {
    lineChartConfig,
    loadingConfig,
 } from './chartConfig';
import {
    getAxisMaxValue,
    getMarkSize,
} from '../utils/Chart';
import { useDashboard } from '../Providers'
import { WidgetProps } from './types';


const EnergyChart: React.FC<WidgetProps> = (props) => {

    const dashboard = useDashboard();
    const params = { type: TimeDurationType.hour, ...dashboard.target } as NodeParams;

    const {
        data,
        error,
        isFetching,
        refetch,
    } = listMeterEnergyByType(params);

    useEffect(() => {
        if (props.reloadCount > 0) {
            refetch();
        }
    }, [props.reloadCount]);

    const getOption = () => {
        return {
            ...lineChartConfig,
            yAxis: {
                ...lineChartConfig.yAxis,
                name: 'kWh',
                max: getAxisMaxValue(data?.map(item => Math.max(item.consumption, item.production)) || []),
            },
            xAxis: {
                ...lineChartConfig.xAxis,
                boundaryGap: false,
                type: 'category',
                data: isFetching || !data ? [] : data.map(item => 
                    new Date(
                        item.dataTime.date.year,
                        item.dataTime.date.month - 1,
                        item.dataTime.date.day,
                        item.dataTime.time.hour,
                        item.dataTime.time.minute,
                        item.dataTime.time.second).toLocaleTimeString('en-GB', { timeStyle: 'short' })
                ),
                axisTick: {
                    alignWithLabel: true
                },
                axisLabel: {
                    fontFamily: 'IBM Plex Sans Thai Looped',
                    fontStyle: 'regular',
                    fontWeight: '400',
                    fontSize: 5,
                    lineHeight: 15,
                    color: "#433A31",
                    show: true,
                    rotate: 45,
                  },
            },
            color: [
                '#FF4200',
                '#D2DC44',
            ],
            legend: {
                data: ['Consumption', 'Production']
              },
            series: [
                {
                  name: 'Consumption',
                  type: 'line',
                  smooth: true,
                  symbol: 'none',
                  areaStyle: {
                  },
                  emphasis: {
                    focus: 'series'
                  },
                  data: isFetching || !data ? [] : data.map(item =>
                      parseFloat(item.consumption.toFixed(3))
                    ),
                    markPoint: {
                        data: [
                            { type: 'max', name: 'Max' },
                            { type: 'min', name: 'Min' }
                        ],
                        symbolSize: (value: number, params: Object) => {
                            return getMarkSize(value);
                          }
                    },
                },
                {
                    name: 'Production',
                    type: 'line',
                    smooth: true,
                    symbol: 'none',
                    areaStyle: {},
                    emphasis: {
                        focus: 'series'
                      },
                    data: isFetching || !data ? [] : data.map(item => 
                        parseFloat(item.production.toFixed(3))
                      ),
                      markPoint: {
                          data: [
                              { type: 'max', name: 'Max' },
                              { type: 'min', name: 'Min' }
                          ],
                          symbolSize: (value: number, params: Object) => {
                              return getMarkSize(value);
                            }
                      },
                  },
              ],
              tooltip: {
                trigger: 'axis',
                appendToBody: true,
            },
           };
     }
    
     const onChartReadyCallback = () => {
     }
     
     return (
        <Reloader showReload={!!error && !isFetching} reload={refetch}>
            <ReactECharts
                key="widget-1"
                style={{ height: '100%', width: '100%' }}
                option={getOption()}
                notMerge={true}
                lazyUpdate={true}
                theme={"theme_name"}
                onChartReady={onChartReadyCallback}
                showLoading={isFetching}
                loadingOption={loadingConfig}
            />
        </Reloader>
    )

}

export default EnergyChart;
