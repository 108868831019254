import moment from 'moment';
import { LPStatus, LoadProfile, MeterPowerDemandStat } from '../services/meterDataApi';
import { DateTimeComponent } from '../services/models';
import { toDate } from '../utils/converter';

export interface MeterProps {
  name: string;
  dcuId: number;
  meterId: number;
  meterNo: string;
}
export enum DateRange {
  Time = "time",
  Date = "date",
  Week = "week",
  Month = "month",
  Quarter = "quarter",
  Year = "year"
}
export type DatePickerType = DateRange.Time | DateRange.Date | DateRange.Week | DateRange.Month | DateRange.Quarter | DateRange.Year;

export const dateRageMap: {
  [key in DateRange]: (d: Date) => number;
} = {
  [DateRange.Date]: (_) => 24,
  [DateRange.Week]: (_) => 7,
  [DateRange.Month]: (d: Date) => moment(d).daysInMonth(),
  [DateRange.Year]: (_) => 12,
  [DateRange.Quarter]: (_) => 4,
  [DateRange.Time]: (_) => 96,
};
const getKeyForTimeType = (type: DateRange, dt: DateTimeComponent) => {
  switch (type) {
    case DateRange.Time:
      return dt.time.hour === 0 && dt.time.minute === 0 ? 95 : dt.time.hour * 4 + (dt.time.minute / 15) - 1;
    case DateRange.Date:
      return dt.time.hour;
    case DateRange.Week:
      return new Date(dt.date.year, dt.date.month - 1, dt.date.day).getDay();
    case DateRange.Month:
      return dt.date.day;
    case DateRange.Year:
      return dt.date.month - 1;
  }
  return 0;
};
const getIndexForDate = (range: DateRange, startDate: Date, i: number) => {
  switch (range) {
    case DateRange.Date:
      return i;
    case DateRange.Year:
      return i;
    case DateRange.Month:
      return i + 1;
    case DateRange.Week:
      return new Date(startDate.valueOf() + 86400 * 1000 * i).getDay();
    default:
      return i;
  }
};
export const getDateStringForInterval = (range: DateRange, startDate: Date, i: number, includeStartTime: boolean = false) => {
  let index = i;
  let unit: moment.unitOfTime.DurationConstructor = 'days';
  switch (range) {
    case DateRange.Time:
      unit = 'minutes';
      index = (i + (includeStartTime ? 0 : 1)) * 15;
      break;
    case DateRange.Date:
      unit = 'hours';
      index = i + 1;
      break;
    case DateRange.Week:
    case DateRange.Month:
      unit = 'days';
      break;
    case DateRange.Year:
      unit = 'months';
      break;
    default:
      unit = 'days';
  }
  return moment(startDate).add(index, unit).toDate();
};

export const getxLabel = (range: DateRange, startDate: Date, i: number, extendStart: boolean = false) => {
  switch (range) {
    case DateRange.Time:
      return moment(startDate).add((i + 1 + (extendStart ? -1 : 0)) * 15, 'minutes').format('HH:mm');
    case DateRange.Date:
      return `${i + 1 + (extendStart ? -1 : 0)}:00`.padStart(5, "0");
    case DateRange.Month:
      return String(i + 1);
    case DateRange.Year:
      return ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'][i];
    case DateRange.Week:
      return ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'][new Date(startDate.valueOf() + 86400 * 1000 * i).getDay()];
    default:
      return String(i);
  }
};

export function extendForShowInday(count: number, datePickType: DatePickerType) {
  if (datePickType ===  DateRange.Time || datePickType ===  DateRange.Date) {
    return count + 1;
  }
  return count;
}

export const filterByTariff = (dt: Date, tariff: Tariff) => {
  if (!dt) { return false; }
  const timeValue = dt.getHours() * 100 + dt.getMinutes();
  switch (tariff) {
    case Tariff.OffPeak:
      return (timeValue >= 0 && timeValue < 915) || (timeValue > 2200);
    case Tariff.OnPeak:
      return timeValue >= 915 && timeValue <= 2200;
    default:
      return true;
  }
}

export const filterXAxis = (index: number, startDate: Date, datePickType: DatePickerType, tariff: Tariff, holidayDays?: Set<number>) => {
  if (DateRange.Year === datePickType || DateRange.Week === datePickType || DateRange.Month === datePickType) {
    return true;
  }
  // else if (datePickType === DateRange.Month && tariff === Tariff.Holiday && holidayDays !== undefined) {
  //   const s = index + 1;
  //   const show = holidayDays.has(s)
  //   // console.log(index);
  //   // if (show) console.log('index',)
  //   // console.log(s, '-' ,show)
  //   return show;
  // }

  let m = moment(startDate);
  if (datePickType === DateRange.Time) {
    m.add((index + 1) * 15, 'minutes');
  }
  else if (datePickType === DateRange.Date) {
    m.add(index + 1, 'hours');
  }
  return filterByTariff(m.toDate(), tariff);
}

export function groupLoadProfile(data: LoadProfile[], datePickType: DateRange, startDate: Date, lpType: string, onlyMaxDemand: boolean = false) {

  const collectedData: {
    [key in string]: LoadProfile[];
  } = data.reduce((acc: {
    [key in number]: LoadProfile[];
  }, item) => {
    const key = getKeyForTimeType(datePickType, item.dataTime);
    if (acc[key]) {
      acc[key].push(item);
    } else {
      acc[key] = [item];
    }
    return acc;
  }, {});

  interface ShiftParam {
    insertItem: LoadProfile | undefined;
    targetKey: number | undefined;
  }

  if (datePickType !== DateRange.Time) {
    const keys = Object.keys(collectedData);
    const shiftedData: ShiftParam = keys.reduceRight((param: ShiftParam, key) => {
      const { insertItem, targetKey } = param;
      const index = collectedData[key].findIndex(item => datePickType === DateRange.Date ? item.dataTime.time.minute === 0 : item.dataTime.time.hour === 0 && item.dataTime.time.minute === 0);
      let shiftedItem;
      if (index !== -1) {
        const [item] = collectedData[key].splice(index, 1);
        shiftedItem = item;
      }

      if (insertItem !== undefined && targetKey !== undefined) {
        if (`${targetKey}` === key) {
          collectedData[key].push(insertItem);
        } else {
          collectedData[`${targetKey}`] = [insertItem];
        }
      }
      return { insertItem: shiftedItem, targetKey: parseInt(key) - 1 };
    }, { insertItem: undefined, targetKey: undefined });

    const lastIndex = keys.pop();
    if (lastIndex && shiftedData.insertItem) {
      collectedData[lastIndex].push(shiftedData.insertItem);
    }
  }

  const isIncludeStartTime = datePickType === DateRange.Time && startDate.getMinutes() !== 0;
  const computeNormal = (acc: number, current: LoadProfile) => {
    // if (datePickType === 'time') {
    //   return acc + current.val;
    // }

    // if ((lpType === 'kWh' || lpType === 'kVarh') && current.dataTime.time.minute !== 0) {
    //   return acc;
    // }
    // return acc + current.val;
    // if ((lpType === 'kWh' || lpType === 'kVarh')) {
    //   return acc + current.val;
    // }
    return acc + current.val;
  }

  const computeMaxdemand = (lpWithMax: LoadProfile, current: LoadProfile) => {
    return current.demand > lpWithMax.demand ? current : lpWithMax;
  }

  function getPickIndex(dateType: DateRange, lps: LoadProfile[]) {
    const lpSize = lps.length;
    switch (dateType) {
      case DateRange.Date:
        return (lpSize == 1 ? 0 : 3);  // Access index 3 because computed value sill store at 0-miniute
      case DateRange.Week:
      case DateRange.Month:
      case DateRange.Year:
        return lps.findIndex(lp => lp.status === LPStatus.Success) ?? 0;
      default:
        return 0;
    }
  }

  const loadProfileData = Array.from({ length: dateRageMap[datePickType](startDate) }, (v, i) => i).map(i => {
    const ts = getDateStringForInterval(datePickType, startDate, i, isIncludeStartTime);
    try {
      const index = getIndexForDate(datePickType, startDate, i);
      let raw = 0
      let ogDataTime: Date | null = null;
      let maxDemandVal: number | null = null;
      if (onlyMaxDemand) {
        const maxDemandLp = (collectedData[index] || []).filter(lp => !Number.isNaN(lp.demand)).reduce(computeMaxdemand)
        raw = maxDemandLp.val;
        ogDataTime = toDate(maxDemandLp.dataTime);
        maxDemandVal = maxDemandLp.val;
      }
      else {
        const lps = collectedData[index] || [];
        raw = lps.reduce(computeNormal, 0.0);
        const lpWithOg = lps.at(getPickIndex(datePickType, lps))
        ogDataTime = lpWithOg && lpWithOg.ogDataTime !== undefined ? toDate(lpWithOg.ogDataTime) : null;
        maxDemandVal = lpWithOg ? lpWithOg?.demand : null;
      }
      const invalid = collectedData[index].every(lp => lp.status === LPStatus.Invalid);
      return [
        ts,
        (collectedData[index] == undefined || invalid)  ? null : parseFloat(raw.toFixed(4)),
        ogDataTime,
        maxDemandVal,
      ];
    } catch (error) {
      console.log(error);
      return [ts, null, ts, null];
    }
  }
  );
  const nowTick = new Date().valueOf()
  return loadProfileData.filter(([ts, _, og]) => (ts as Date).valueOf() <= nowTick);
}

export interface MeterData {
  name: string;
  data: LoadProfile[];
  objectId?: number;
  objectType?: number;
  key?: string;
  startDate?: Date;
}

export interface MeterDataProps {
  name: string;
  dcuId: number;
  meterId: number;
  meterNo: string;
  data: MeterData[];
  error: string;
  isFetching: boolean;
  isSuccess: boolean;
  enableTimeRange?: boolean;
  timeRange?: Array<OptionItem<DateRange>>;
  showTableAsGroup?: boolean;
  refetch: () => void;
  paramChanged: (param: Record<string, any>) => void;
  onRemoveMeterData?: (objectId: number, objectType: number) => void;
  onRemoveMeterData2?: (key: string) => void;
  forcedSumEveryBlock?: boolean;
  noTransformData?: boolean;
  lpTypes?: Array<string>;
  enableTariff?: boolean;
  // Interim: flip logic to show xxx
  useShowNoCaplicableMethod1?: boolean;
}

export interface LoadProfileType {
  name: string;
  value: string;
}

export const loadProfileTypes: { [Key in string]: LoadProfileType } = {
  'kWh': { name: 'kWh', value: "kwh" },
  'kVarh': { name: 'kVarh', value: "kvarh" },
  'kW': { name: 'kW', value: "kw" },
  // 'kWmax': { name: 'kW (Max demand)', value: "kWmax" },
  'kVar': { name: 'kVar', value: "kvar" },
}

export enum Tariff {
  All = "0",
  OnPeak =  "1",
  OffPeak = "2",
  Holiday = "3",
}

export interface OptionItem<T> {
  name: string;
  value: T;
}

export const tariffTypes: Record<string, OptionItem<Tariff>> = {
  'All': { name: 'All', value: Tariff.All },
  'On-peak': { name: 'On-peak', value: Tariff.OnPeak },
  'Off-peak': { name: 'Off-peak', value: Tariff.OffPeak },
  'Holiday': { name: 'Holiday', value: Tariff.Holiday },
}

export enum LoadProfileBlockGroupType {
  OneBlock = "1",
  FourBlocks = "4",
}

export const defaultTimeRange: Array<OptionItem<DateRange>> = [
   { name: 'Date(15-minutes)', value: DateRange.Time },
   { name: 'Date(Hour)', value: DateRange.Date },
   { name: 'Week', value: DateRange.Week },
   { name: 'Month', value: DateRange.Month },
   { name: 'Year', value: DateRange.Year },
]

export const dateTimeRange = defaultTimeRange.slice(0, 2);

export const getDateRangeParam = (dr: DateRange) => {
  switch (dr) {
    case DateRange.Time:
      return 0;
    case DateRange.Date:
      return 1;
    default:
      return 2;
  }
}

export const getDateRangeParam2 = (dr: DateRange) => {
  switch (dr) {
    case DateRange.Time:
      return 0;
    case DateRange.Date:
      return 1;
    case DateRange.Month:
      return 2;
    case DateRange.Year:
      return 3;
    default:
      return 2;
  }
}

export function getPowerDemandVal(item: MeterPowerDemandStat, type: string) {
  switch (type) {
    case 'kW':
    case 'Import kW':
      return item.kW;
    case 'kWh':
    case 'Import kWh':
      return item.kWh;
    case 'kVar':
    case 'Import kVar':
      return item.kVar;
    case 'kVarh':
    case 'Import kVarh':
      return item.kVarh;
    case 'Export kW':
      return item.kWExport;
    case 'Export kWh':
      return item.kWhExport;
    case 'Export kVar':
      return item.kVarExport;
    case 'Export kVarh':
      return item.kVarhExport;
    default:
      return 0;
  }
}

export function findStartIndex(lps: Array<LoadProfile>, dataTemplate: Array<Date>) {
  if (dataTemplate.length > 1) {
    const lowerBound = dataTemplate.at(0)!.valueOf();
    const upperBound = dataTemplate.at(-1)!.valueOf();
    const si = lps.findIndex(lp => {
      const ts = toDate(lp.dataTime).valueOf()
       return ts >= lowerBound && ts <= upperBound;
    });
    if (si !== -1) {
      return si;
    }
  }
  return 0;
}