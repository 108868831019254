import {
    baseApi,
} from './baseApi';


export enum EventLevel {
    Critical = 1,
    Warning = 2,
    Info = 3,
}

export enum AlarmEventType {
    Standard = 1,
    Fraud = 2,
    Control = 3,
    PowerQuality = 4,
    Token = 5,
    MeasurementLog = 6,
    DCUParameters = 7,
    Master = 8,
}

export interface MeterEvent {
    readonly meterno: string;
    readonly pointid: number;
    readonly eventitemid: number;
    readonly eventdisc: string;
    readonly eventStartTime: Date;
    readonly eventEndTime: Date;
    readonly duration: number;
    readonly level: EventLevel;
    readonly alarmEventType: AlarmEventType
}

export interface Customer {
    readonly userid: number;
    readonly discUser: string;
    readonly puserNo: string;
    readonly address: string;
    readonly district: string;
    readonly province: string;
    readonly remark: string;
    readonly linkname: string;
    readonly linkphone: string;
    readonly linkmobile: string;
    readonly email: string;
    readonly peacode: string;
    readonly peano: string;
    readonly collection: string;
    readonly pricetype: number;
    readonly usertype: number;
    readonly objectid: number;
    readonly objecttype: number;
    readonly areaCode: string;
    readonly meterNoStr: string;
    readonly businessType: string;
    readonly peaRegion: string;
    readonly customerType: string;
    readonly voltage: string;
    readonly rate: string;
    readonly existingMeterNo: string;
    readonly lat: string;
    readonly lng: string;
    readonly vt: string;
    readonly ct: string;
    readonly obj: any[];
    readonly isMeterOnline: boolean;
    readonly installDate: string;
}

const prefix = '/customers';

export const customerApi = baseApi.injectEndpoints({
    endpoints: (build) => ({
        listCustomer: build.query<Customer[], { objectId?: number, objectType?: number, rates?: Array<number>}>({
            query: (params = {}) => {
                return {
                    url:  `${prefix}/`,
                    method: 'GET',
                    params: {
                        objType: params.objectType,
                        objId: params.objectId,
                        rate: params.rates,
                    },
                }
            },
            transformResponse: (response: Customer[], meta, arg) => response,
        }),
        listCustomerByStationArea: build.query<Customer[], { ids: number[], rates?: Array<number>, regionCode?: string }>({
            query: (params) => {
                return {
                    url:  `${prefix}/stationArea`,
                    method: 'GET',
                    params: {
                        ids: params.ids.join(','),
                        rates: params.rates,
                        region: params.regionCode,
                    },
                }
            },
            transformResponse: (response: Customer[], meta, arg) => response,
        }),
    }),
    overrideExisting: false,
});

export const {
    useListCustomerQuery: useListCustomer,
    useListCustomerByStationAreaQuery: useListCustomerByStationArea,
} = customerApi;
