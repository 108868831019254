
export const exportDataAsCSV = (data: Array<Array<string>>, filename: string) => {

    let csvContent = "data:text/csv;charset=utf-8,"
      + data.map(e => e.join(",")).join("\n");

    let link = document.createElement("a");
    link.setAttribute("href", encodeURI(csvContent));
    link.setAttribute("download", filename ?? "my_data.csv");

    document.body.appendChild(link); // Required for FF
    link.click();

}
