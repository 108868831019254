import React, { useEffect, useState } from 'react';
import {
    Space,
    Select,
    Button,
} from 'antd';
import { NodeType, Region, useListRegion } from '../services/projectApi';
import { useListRate } from '../services/meterDataApi';
import { useComparison } from '../Providers';

export interface OptionType<T> {
    name: string;
    value: T;
  }

export const customerRates: Record<string, OptionType<string>> = {
    '1114': { name: 'บ้านอยู่อาศัย ไม่เกิน 5A. 22-33KV', value: '1114' },
    '1114M': { name: 'วัด,สถานประกอบศาสนกิจ ไม่เกิน 5A.22-33KV', value: '1114M' },
    '1115': { name: 'บ้านอยู่อาศัย ไม่เกิน 5A. <22.KV', value: '1115' },
    '1115M': { name: 'วัด,สถานประกอบศาสนกิจ ไม่เกิน 5A. <22KV', value: '1115M' },
    '1124': { name: 'บ้านอยู่อาศัย เกิน 5A. 22-33KV', value: '1124' },
    '1124M': { name: 'วัด,สถานประกอบศาสนกิจ เกิน 5A. 22-33KV', value: '1124M' },
    '1125': { name: 'บ้านอยู่อาศัย เกิน 5A. <22KV', value: '1125' },
    '1125M': { name: 'วัด,สถานประกอบศาสนกิจ เกิน 5A. <22KV', value: '1125M' },
    '1214': { name: 'บ้านอยู่อาศัย TOU 22-33KV', value: '1214' },
    '1214M': { name: 'วัด,สถานประกอบศาสนกิจ TOU 22-33KV', value: '1214M' },
    '1225': { name: 'บ้านอยู่อาศัย TOU <22KV', value: '1225' },
    '1225M': { name: 'วัด,สถานประกอบศาสนกิจ TOU <22KV', value: '1225M' },
    '2114': { name: 'กิจการขนาดเล็ก 22-33KV', value: '2114' },
    '2125': { name: 'กิจการขนาดเล็ก <22KV', value: '2125' },
    '2125C': { name: 'อุปกรณ์ทวนสัญญาณ (Cell station) <22KV', value: '2125C' },
    '2125T': { name: 'ตู้โทรศัพท์สาธารณะ <22KV', value: '2125T' },
    '2214': { name: 'กิจการขนาดเล็ก TOU 22-33KV', value: '2214' },
    '2215': { name: 'กิจการขนาดเล็ก TOU <22KV', value: '2215' },
}

const { Option } = Select;


const CustomerGroupFilter: React.FC = () => {
    let comparison = useComparison();
    const [rate, setRate] = useState<string>("");
    const [regionCode, setRegionCode] = useState<string | undefined>();
    const [regionDict, setRegionDict] = useState<Record<string, string>>({});
        const {
        data: rates,
        isFetching: isFetchingRate,
        isSuccess: isSuccessRate,
        refetch: refetchRate,
      } = useListRate({})

    // const {
    //     data: regions,
    //     isFetching: isFetchingRegion,
    //     isSuccess: isSuccessRegion,
    //     refetch: refetchRegion
    //   } = useListRegion({})


    // useEffect(() => {
    //     if (isFetchingRate && rates) {
    //       setRegionDict(
    //         regions.reduce((accu, r) => ({ ...accu, [r.code] : r.name }), {})
    //       )
    //     }
    //   }, [isFetchingRegion, isSuccessRegion, regions]);

    return (
        <Space direction="vertical" size="large" style={{width: "100%"}}>
          <Select
            allowClear
            // mode="multiple"
            placeholder="Select a rate"
            // disabled={isFetching || isFetching2}
            // defaultValue={rates}
            // onChange={(vals: Array<string>) => setRates(vals)}
            onSelect={(vals: any) => setRate(vals)}
            // onDeselect={(vals: any) => comparison.removeRate(vals)}
            onClear={() => setRate("")}
            size="large"
            maxTagCount='responsive'
            style={{width: 240}}
            disabled={comparison.isRateLoading}
          >
            {isSuccessRate && rates ? rates.map(key =>
              <Option
                key={key}
                value={key}
                disabled={comparison.rates.findIndex(v => v.value === key) !== -1}>
                  {`${key}`}
              </Option>)
            :null}
          </Select>
          <Button
            disabled={comparison.isRateLoading || rate === ""}
            onClick={() => {
              comparison.addRate(rate);
              setRate("");
            }}>Add</Button>
        </Space>
    );
}

export default CustomerGroupFilter;