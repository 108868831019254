import React from 'react';


const TableTitle: React.FC = (props) => <span className='chart-text-small'>{props.children}</span>
const TableCell: React.FC = (props) => <span className='chart-text-small chart-text-small--2'>{props.children}</span>

export {
    TableTitle,
    TableCell,
};
