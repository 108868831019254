import { DateTimeComponent } from '../services/models';
import moment from 'moment';

export function toDateComponent(datetime: Date) {
    return {
        date: {
            day: datetime.getDate(),
            month: datetime.getMonth() + 1,
            year: datetime.getFullYear(),
        },
        time: {
            hour: datetime.getHours(),
            minute: datetime.getMinutes(),
            second: datetime.getSeconds(),
            nano: 0
        }
    }
}

export function toDate(component: DateTimeComponent) {
    return new Date(
        component.date.year,
        component.date.month - 1,
        component.date.day,
        component.time.hour,
        component.time.minute,
        component.time.second,
    );
}

export function formatDurtionStr(diff: number, showSection: boolean = false) {
    const days = moment.duration(diff).asDays()
    let durationStr = moment.utc(diff).format(showSection ? "HH [h] mm [m] SS [s]" : "HH [h] mm [m]");

    if (days > 1) {
      return `${Math.floor(days)} day ${durationStr}`
    }
    return durationStr
}
