import React, { useEffect, useState } from 'react';
import {
    Button,
    List,
} from 'antd';
import { Plus2Icon } from '../icons';
import { useListWidgets } from '../services/widgetsApi';
import { useAuth, useDashboard } from '../Providers';


const WidgetList: React.FC = () => {
    
    const auth = useAuth();
    const dashboard = useDashboard();

    const {
      data = [],
      isSuccess,
    } = useListWidgets(auth.account?.accountid);
    const changedWidgets = dashboard.widgets;

    const [avaiWidgets, setAvaiWidgets] = useState<WidgetMaster[]>([]);

    useEffect(() => {
      if (isSuccess) {
        const widgets = changedWidgets || data;
        const list = allWidgets.filter(({ key }) => {
            const item = widgets.find((widget) => widget.id === parseInt(key));
            return  item === undefined
        })
        setAvaiWidgets(
            list
        )
      }
    }, [isSuccess, changedWidgets]);

    return (
        <List
            className='widget-list'
            split={false}
            dataSource={avaiWidgets}
            renderItem={item =>
                <List.Item>
                    <span className='subtitle'>{item.name}</span>
                    <Button
                        type='text'
                        icon={<Plus2Icon size={30} />}
                        onClick={() => {
                            dashboard.addTempWidget(item.key);
                            setAvaiWidgets(avaiWidgets.filter(w => w.key !== item.key))
                        }}
                    />
                </List.Item>}
        />
    )
}

interface WidgetMaster {
    key: string;
    name: string;
}

const allWidgets: WidgetMaster[] = [{
    key: "1",
    name: "Energy Consumption",
  },
  // {
  //   key: "2",
  //   name: "Communication Types",
  // },
  {
    key: "3",
    name: "Energy Efficiency",
  },
  {
    key: "4",
    name: "Peak Demand Finder",
  },
  {
    key: "5",
    name: "Total Max Demand",
  },
  // {
  //   key: "6",
  //   name: "Data Usage System",
  // },
  // {
  //   key: "7",
  //   name: "Data Management System",
  // },
  // {
  //   key: "8",
  //   name: "Network Monitoring",
  // },
  // {
  //   key: "9",
  //   name: "Energy",
  // },
  // {
  //   key: "10",
  //   name: "Production",
  // },
  {
    key: "11",
    name: "OnlineRatio",
  },
  // {
  //   key: "12",
  //   name: "Energy by Max Demand"
  // }
];

export default WidgetList;
