
import React, { useEffect, useState } from 'react';
import {
    Tabs,
} from 'antd';
import moment from 'moment';
import {
  LPStatus,
  LoadProfile,
  useListMeterPowerAndDemandByRate,
  useListMetersPowerAndDemandByRate,
  MeterPowerDemandStat,
} from '../services/meterDataApi';
import {
  MeterProps,
  DateRange,
  DatePickerType,
  MeterData,
  getDateRangeParam2,
  dateRageMap,
  getDateStringForInterval,
  getPowerDemandVal,
  findStartIndex,
} from '../components/MeterProps';
import { LoadProfilePane } from '../components/LoadProfilePane';
import {
    useComparison,
    GenItem,
  } from '../Providers';
import { toDate, toDateComponent } from '../utils/converter';
import './NodeComparisonScene.css';


const { TabPane } = Tabs;


export const ComparisonLoadProfilePane: React.FC<MeterProps> = (props) => {

  const comparison = useComparison();

  let [startToLoad, setStartToLoad] = useState(false);
  let [lpParams, setLpParams] = useState<Record<string, any>>({ lpType: 'kW' });
  let [meterData, setMeterData] = useState<Record<string, MeterData>>({})
  let [datePickType, setDatePickType] = useState<DatePickerType>(DateRange.Date);
  let [name, setName] = useState(moment().startOf('day').format('YYYY-MM-DD'));
  let [startDate, setStartDate] = useState(moment().startOf('day').toDate());
  let [toFetchDataForRate, setToFetchDataForRate] = useState<GenItem>();
  let [dataTemplate, setDataTemplate] = useState<Array<Date>>([]);

  const onRemoveMeterData2 = (rate: string) => {
    comparison.removeRate(rate);
    const newMeterData = Object.values(meterData).filter(o => (o.key !== rate)).reduce((dict, obj) => ({ ...dict, [obj.key as string]: obj }), {})
    setMeterData(newMeterData);

    if (Object.keys(newMeterData).length === 0) {
      setToFetchDataForRate(undefined);
    }
  };

  const params = {
    // rate: toFetchDataForRate?.value ?? "",
    rates: comparison.rates.map(r => r.name),
    start: moment(lpParams.startDate).add(lpParams.datePickType === DateRange.Date || lpParams.datePickType === DateRange.Time? 1 : -1, 'second').utcOffset(0, true).format("YYYY-MM-DD HH:mm:ss"),
    end: moment(lpParams.endDate).add(lpParams.datePickType === DateRange.Date || lpParams.datePickType === DateRange.Time? 1 : 0, 'second').utcOffset(0, true).format("YYYY-MM-DD HH:mm:ss"),
    type: getDateRangeParam2(lpParams.datePickType),
    tariff: parseInt(lpParams.tariff),
  };

  const {
    data, error, isFetching, isSuccess, refetch,
  } = useListMetersPowerAndDemandByRate({ ...params }, { skip: !startToLoad });

  useEffect(() => {
    if (isSuccess && !isFetching && data) {

      const transdata = data.map(lps => recompute(lps, lpParams.datePickType, lpParams.lpType, dataTemplate));
      setMeterData(comparison.rates.reduce((lpData, rate, idx) => ({
        ...lpData,
        [rate.value ?? ""]: { name: rate.name, data: transdata[idx], properties: { name: 'rate', value: rate.value }, key: rate.value } as MeterData
      }), {}));
      // setMeterData({
        // ...meterData,
        // [toFetchDataForRate.value]: ({ name: toFetchDataForRate.name, data: transdata, properties: { name: 'rate', value: toFetchDataForRate.value }, key: toFetchDataForRate.value } as MeterData)
      // })
      setStartToLoad(false);
    }
  }, [isFetching, isSuccess, data, lpParams.datePickType, lpParams.lpType, dataTemplate]);

  useEffect(() => {
    if (data) {
      const transdata = data.map(lps => recompute(lps, lpParams.datePickType, lpParams.lpType, dataTemplate));
      setMeterData(comparison.rates.reduce((lpData, rate, idx) => ({
        ...lpData,
        [rate.name ?? ""]: { name: rate.name, data: transdata[idx], properties: { name: 'rate', value: rate.value }, key: rate.value } as MeterData
      }), {}));
    }
  }, [lpParams.lpType, dataTemplate, lpParams.datePickType, dataTemplate]);


  useEffect(() => {
    if (!lpParams.datePickType) {
      return;
    }

    const template = Array.from({ length: dateRageMap[lpParams.datePickType as DatePickerType](lpParams.startDate) }, (v, i) => {
      const ts = getDateStringForInterval(lpParams.datePickType, lpParams.startDate, i, false);
      return ts;
    });
    setDataTemplate(template);

  }, [lpParams.datePickType, lpParams.startDate]);

  useEffect(() => {
    setStartToLoad(comparison.rates.length > 0  && lpParams.startDate !== undefined && lpParams.endDate !== undefined && lpParams.lpType !== undefined && lpParams.tariff !== undefined);
  }, [comparison.rates, lpParams.startDate, lpParams.endDate, lpParams.tariff]);

  useEffect(() => {
    comparison.setRateLoading(isFetching);
  }, [isFetching]);

  function mapWithTemplate(lps: Array<LoadProfile>, dataTemplate: Array<Date>) {
    let nd: Array<LoadProfile> = []
    let dataIndex = findStartIndex(lps, dataTemplate);

    if (dataTemplate && lps.length > 0) {
        nd = dataTemplate.map(t => {
        if (dataIndex >= lps.length) {
          return {
            dataTime: toDateComponent(t),
            val: null,
            demand: null,
            status: LPStatus.Success,
          };
        }
        // console.log(item.name, dataIndex);
        const lp = lps[dataIndex];
        if (t.getFullYear() === lp.dataTime.date.year &&
        t.getMonth() === lp.dataTime.date.month - 1 &&
        t.getDate() == lp.dataTime.date.day &&
        t.getHours() == lp.dataTime.time.hour && 
        t.getMinutes() == lp.dataTime.time.minute) {
          dataIndex++;
          return lp;
        }
        
        return {
          dataTime: toDateComponent(t),
          val: null,
          demand: null,
          status: LPStatus.Success,
        };
      }) as Array<LoadProfile>;
    }
    return nd;
  }

  function recompute(data: Array<MeterPowerDemandStat>, datePickerType: string, lpType: string, dataTemplate: Date[]) {
    // filter case of holiday in 15 min, hour
    const isDay = (datePickerType as DatePickerType === DateRange.Time) || (datePickerType as DatePickerType === DateRange.Date);

    const filteredLps = isDay && data.length === 1 && data[0].dataTime.time.hour === 0 && data[0].dataTime.time.minute === 0 ? [] : data;
    const converted = filteredLps.map<LoadProfile>(d => {
        return {
          dataTime: d.dataTime,
          val: getPowerDemandVal(d, lpType),
          demand: getPowerDemandVal(d, lpType),
          status: LPStatus.Success,
        };
    });
    return mapWithTemplate(converted, dataTemplate);
  }

  const cprops = {
    ...props,
    data: Object.values(meterData),
    error: error !== undefined ? "Something went wrong, please try again" : "",
    isFetching,
    isSuccess,
    refetch,
    onRemoveMeterData2,
    forcedSumEveryBlock: true,
    noTransformData: true,
    // timeRange: [
    //   { name: "Date", value: DateRange.Date },
    //   { name: "Month", value: DateRange.Month },
    //   // { name: "Year", value: DateRange.Year },
    // ],
    // lpTypes: ['kWh', 'kW'],
    // enableTariff: false,
  };
  return <LoadProfilePane {...cprops} paramChanged={(params) => setLpParams(params)}></LoadProfilePane>;
};


const YTLRateComparisonScene2: React.FC = () => {
    return (
        <Tabs defaultActiveKey="1" className='node-comparison-page'>
            <TabPane tab="Load Profile by Rates" key="1" className='tab-container node-comparison-tab' style={{ flexFlow: 'column' }}>
                <ComparisonLoadProfilePane meterId={0} dcuId={0} name={"Rates"} meterNo={""} />
            </TabPane>
        </Tabs>
    );
}

export default YTLRateComparisonScene2;
