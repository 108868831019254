import React, { useEffect } from 'react';
import {
  useNavigate,
} from 'react-router-dom';
import {
  Alert,
  Form,
  Input,
  Button,
  Checkbox,
  Row,
  Col,
  Card,
  Space,
} from 'antd';
import { useAuth } from '../Providers';


const LoginScene: React.FC = () => {

  let navigate = useNavigate();
  let auth = useAuth();

  let from = "/app/dashboard";

  useEffect(() => {
    if (auth.account) {
      navigate(from, { replace: true });
    }
  }, [auth.account]);

  const onFinish = (values: any) => {
    auth.signin(values.username, values.password, () => {
    })
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <Row
      justify="center"
      align="middle"
      gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
      className="login-container"
      >
      <Col span={8} xs={{ span: 20 }} sm={{ span: 16 }} md={{ span: 16 }} lg={{ span: 10 }}>
        <Card
          title={<span className='head head-large'>AMI SYSTEM OPERATION</span>}
          headStyle={{ backgroundColor: '#FF8833', borderTopRightRadius: 16, borderTopLeftRadius: 16}}
          className="login-box-container"
          >
        <Form
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            label="Username"
            name="username"
            rules={[{ required: true, message: 'Please input your username!' }]}
          >
            <Input disabled={auth.isSigningIn} />
          </Form.Item>

          <Form.Item
            label="Password"
            name="password"
            rules={[{ required: true, message: 'Please input your password!' }]}
          >
            <Input.Password disabled={auth.isSigningIn} />
          </Form.Item>
          {auth.errorMessage
           ? <Alert message="Login Failed" type="error" />
           : null
          }
          <Form.Item name="remember" valuePropName="checked" wrapperCol={{ offset: 8, span: 16 }}>
            <Checkbox>Remember me</Checkbox>
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
            <Space size='large'>
              <Button
                htmlType="submit"
                loading={auth.isSigningIn}
                className='btn btn-action'
                style={{ width: 120 }}
                >
                Sign In
              </Button>
            </Space>
          </Form.Item>
        </Form>
        </Card>
      </Col>
    </Row>
  );
}

export default LoginScene;
