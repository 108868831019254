import React, {
    useEffect,
    useState,
} from 'react';
import Reloader from './Reloader';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import {
    NodeParams,
    useGetEnergyProduction,
} from '../services/energyApi';
import { useDashboard } from '../Providers'
import { WidgetProps } from './types';
import { Amount } from './Amount';


const EnergyProduction: React.FC<WidgetProps> = (props) => {

    const dashboard = useDashboard();
    const params = { ...dashboard.target } as NodeParams;
    const {
        data,
        error,
        isFetching,
        refetch,
    } = useGetEnergyProduction(params);

    const optionsForkWh = {
        minimumFractionDigits: 1,
        maximumFractionDigits: 1,
    };

    const optionsForSaving = {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
    };

    const [year] = useState(`Year ${new Date().getFullYear()}`);
    const [today] = useState(new Date().toLocaleDateString('en-GB'));

    useEffect(() => {
        if (props.reloadCount > 0) {
            refetch();
        }
    }, [props.reloadCount]);

    return (
        <Reloader showReload={!!error && !isFetching} reload={refetch}>
            <div className='chart amount-wrap'>
                <Amount
                    title="Today Generation"
                    value={data ? data.exportEnergy.today.toLocaleString(undefined, optionsForkWh) : "-"}
                    unit="kWh"
                    supplement={today}
                />
                <Amount
                    title="Year Generation"
                    value={data ? data.exportEnergy.year.toLocaleString(undefined, optionsForkWh) : "-"}
                    unit="kWh"
                    supplement={year}
                />
                <Amount
                    title="Today Saving"
                    amountStyle={{ color: '#FF8833' }}
                    value={data ? data.saving.today.toLocaleString(undefined, optionsForSaving) : "-"}
                    unit={data ? data.currency : undefined}
                    supplement={today}
                />
                <Amount
                    title="Year Saving"
                    amountStyle={{ color: '#FF8833' }}
                    value={data ? data.saving.year.toLocaleString(undefined, optionsForSaving) : "-"}
                    unit={data ? data.currency : undefined}
                    supplement={year}
                />
                <span className='chart-text-small chart-text-small--4 note-text'>Approximation, cannot be referreced</span>
            </div>
            <Spin spinning={isFetching} className="amount-spin" indicator={<LoadingOutlined style={{ fontSize: 28 }} spin />}/>
        </Reloader>
    )
}

export default EnergyProduction;
