import React, { useEffect, useState } from 'react';
import { List, Button } from 'antd';
import { ReloadOutlined } from '@ant-design/icons'
import Segmented from './Segmented';
import {
  ExclamationIcon,
  NotificationIndicator,
} from '../icons';
import { useListEvent, MeterEvent, EventLevel, MeterEventRaw } from '../services/eventApi';

import "./NotificationList.css";
import { useEvent } from '../Providers';


const NotificationList: React.FC = (props) => {

  const {
    data,
    isFetching,
    isSuccess,
    refetch,
  } = useListEvent({});

  let [events, setEvents] = useState<MeterEvent[]>([]);
  let [allEvents, setAllEvents] = useState<MeterEvent[]>([]);
  let [criticalEvents, setCriticalEvents] = useState<MeterEvent[]>([]);
  let [warningEvents, setWarningEvents] = useState<MeterEvent[]>([]);
  let [showEventType, setShowEventType] = useState(0);

  const eventContext = useEvent();

  useEffect(() => {
    if (isSuccess && data) {
      const events = data.map(item => ({ ...item, eventStartTime: new Date(item.eventStartTime), eventEndTime: new Date(item.eventEndTime), reportTime: new Date(item.reportTime), isNew: false }))
      setEvents(events);
      setAllEvents(events);
      setCriticalEvents(events.filter(event => event.level === EventLevel.Critical));
      setWarningEvents(events.filter(event => event.level === EventLevel.Warning));
    }
  }, [isFetching, isSuccess])

  useEffect(() => {
    if (eventContext.newEvents) {
      console.log('new events', eventContext.newEvents);
      const newEvents = eventContext.newEvents.map(item => ({ ...item, eventStartTime: new Date(item.eventStartTime), eventEndTime: new Date(item.eventEndTime), reportTime: new Date(item.reportTime), isNew: true }));
      setAllEvents(events => [...newEvents, ...events]);
      setCriticalEvents(events => [...newEvents.filter(event => event.level === EventLevel.Critical), ...events]);
      setWarningEvents(events => [...newEvents.filter(event => event.level === EventLevel.Warning), ...events]);
    }
  }, [eventContext.newEvents]);

  useEffect(() => {
    switch (showEventType) {
      case 0:
        setEvents(allEvents);
        break;
      case 1:
        setEvents(criticalEvents);
        break;
      case 2:
        setEvents(warningEvents);
        break;
    }

  }, [showEventType, allEvents, criticalEvents, warningEvents]);

  function showEvents(index: number) {
      switch (index) {
        case 0:
          setEvents(allEvents);
          break;
        case 1:
          setEvents(criticalEvents);
          break;
        case 2:
          setEvents(warningEvents);
          break;
      }
  }

  return (
    <div className='notification-list-container'>
      <div className='header-container'>
        <div className='header'>
          <span className='subtitle subtitle--bigger-2'>
            Notification
          </span>
          {isFetching ? null : <Button
            size="small"
            className="btn-reload"
            onClick={() => refetch()}
            icon={<ReloadOutlined />}
          ></Button>}
        </div>
        <Segmented
          options={['ALL', 'CRITICAL', 'WARNING',]}
          onChange={(index: number) => {
            showEvents(index);
            setShowEventType(index);
          }}/>
      </div>
      <List
        className="notification-list"
        itemLayout="horizontal"
        loading={isFetching}
        dataSource={events}
        renderItem={item => (
          <List.Item
            className={`meter-event ${item.level === EventLevel.Critical ? 'critical' : 'warning'}`}
            extra={item.isNew ? <NotificationIndicator /> : null}
            >
            <List.Item.Meta
              avatar={ item.level === EventLevel.Info ? null : <ExclamationIcon />}
              title={<span className="subtitle">{`${item.meterno} ${item.eventdisc}`}</span>}
              description={<span className="caption">{item.eventStartTime.toLocaleString('en-GB')}</span>}
            />
          </List.Item>
      )}
    />
    </div>
  );
}

export default NotificationList;
