
import React, { useEffect, useState } from 'react';
import {
    Tabs,
} from 'antd';
import moment from 'moment';
import {
  LPStatus,
  LoadProfile,
  MeterPowerDemandStat,
  useListMetersPowerAndDemandByNode
} from '../services/meterDataApi';
import {
  MeterProps,
  DateRange,
  getDateRangeParam2,
  MeterData,
  getDateStringForInterval,
  getPowerDemandVal,
  dateRageMap,
  DatePickerType,
  findStartIndex,
} from '../components/MeterProps';
import { LoadProfilePane } from '../components/LoadProfilePane';
import {
    useComparison,
  } from '../Providers';
import { toDate, toDateComponent } from '../utils/converter'
import './NodeComparisonScene.css';


const { TabPane } = Tabs;

export const ComparisonLoadProfilePane: React.FC<MeterProps> = (props) => {

  const comparison = useComparison();

  let [startToLoad, setStartToLoad] = useState(false);
  let [lpParams, setLpParams] = useState<Record<string, any>>({ lpType: 'kWh' });
  let [meterData, setMeterData] = useState<Record<string, MeterData>>({})
  let [datePickType, setDatePickType] = useState<DatePickerType>(DateRange.Date);
  let [name, setName] = useState(moment().startOf('day').format('YYYY-MM-DD'));
  let [startDate, setStartDate] = useState(moment().startOf('day').toDate());
  let [dataTemplate, setDataTemplate] = useState<Array<Date>>([]);


  const onRemoveMeterData = (objectId: number, objectType: number) => {
    comparison.removeNode(objectId, objectType);
    const newMeterData = Object.values(meterData).filter(o => !(o.objectId === objectId && o.objectType === objectType)).reduce((dict, obj) => ({ ...dict, [obj.name]: obj }), {})
    setMeterData(newMeterData);
  };

  const params = {
    nodes: comparison.nodes,
    types: lpParams.lpType,
    start: moment(lpParams.startDate).add([DateRange.Date, DateRange.Time].some(t => t === lpParams.datePickType) ? 1 : -1, 'second').format("YYYY-MM-DD HH:mm:ss"),
    end: moment(lpParams.endDate).add([DateRange.Date, DateRange.Time].some(t => t === lpParams.datePickType) ? 1 : 0, 'second').utcOffset(0, true).format("YYYY-MM-DD HH:mm:ss"),
    type: getDateRangeParam2(lpParams.datePickType),
    tariff: parseInt(lpParams.tariff),
  };

  const {
    data, error, isFetching, isSuccess, refetch,
  } = useListMetersPowerAndDemandByNode({ ...params }, { skip: !startToLoad });

  useEffect(() => {
    if (isSuccess && !isFetching && data) {

      const transdata = data.map(lps => recompute(lps, lpParams.datePickType, lpParams.lpType, dataTemplate));

      setMeterData(comparison.nodes.reduce((lpData, node, idx) =>
        ({ ...lpData, [node.meterNo ?? ""]: {
          name: node.meterNo ?? "",
          data: transdata[idx],
          objectId: node.objid,
          objectType: node.objtype
        }
        }), {}))
      setStartToLoad(false);
    }
  }, [isFetching, isSuccess, data, lpParams.datePickType, lpParams.lpType, dataTemplate]);

  useEffect(() => {
    if (!lpParams.datePickType) {
      return;
    }

    const template = Array.from({ length: dateRageMap[lpParams.datePickType as DatePickerType](lpParams.startDate) }, (v, i) => {
      const ts = getDateStringForInterval(lpParams.datePickType, lpParams.startDate, i, false);
      return ts;
    });
    setDataTemplate(template);

  }, [lpParams.datePickType, lpParams.startDate]);

  useEffect(() => {
    if (data) {

      const transdata = data.map(lps => recompute(lps, lpParams.datePickType, lpParams.lpType, dataTemplate));
      setMeterData(comparison.nodes.reduce((lpData, node, idx) =>
        ({ ...lpData, [node.meterNo ?? ""]: {
          name: node.meterNo ?? "",
          data: transdata[idx],
          objectId: node.objid,
          objectType: node.objtype
        }
        }), {}));
      }

  }, [lpParams.lpType, lpParams.datePickType, dataTemplate, dataTemplate]);

  useEffect(() => {
    setStartToLoad(comparison.nodes.length > 0 && lpParams.startDate !== undefined && lpParams.endDate !== undefined && lpParams.lpType !== undefined && lpParams.tariff !== undefined);
  }, [comparison.nodes, lpParams.startDate, lpParams.endDate, lpParams.tariff]);


  function mapWithTemplate(lps: Array<LoadProfile>, dataTemplate: Array<Date>) {
    let nd: Array<LoadProfile> = []
    let dataIndex = findStartIndex(lps, dataTemplate);

    if (dataTemplate && lps.length > 0) {
        nd = dataTemplate.map(t => {
        if (dataIndex >= lps.length) {
          return {
            dataTime: toDateComponent(t),
            val: null,
            demand: null,
            status: LPStatus.Success,
          };
        }
        // console.log(item.name, dataIndex);
        const lp = lps[dataIndex];
        if (t.getFullYear() === lp.dataTime.date.year &&
        t.getMonth() === lp.dataTime.date.month - 1 &&
        t.getDate() == lp.dataTime.date.day &&
        t.getHours() == lp.dataTime.time.hour && 
        t.getMinutes() == lp.dataTime.time.minute) {
          dataIndex++;
          return lp;
        }
        
        return {
          dataTime: toDateComponent(t),
          val: null,
          demand: null,
          status: LPStatus.Success,
        };
      }) as Array<LoadProfile>;
    }
    return nd;
  }

  function recompute(data: Array<MeterPowerDemandStat>, dateType: string, lpType: string, dataTemplate: Date[]) {
    // filter case of holiday in 15 min, hour
    const isDay = (dateType === DateRange.Time) || (dateType === DateRange.Date);

    const filteredLps = isDay && data.length === 1 && data[0].dataTime.time.hour === 0 && data[0].dataTime.time.minute === 0 ? [] : data;
    const converted = filteredLps.map<LoadProfile>(d => {
        return {
          dataTime: d.dataTime,
          val: getPowerDemandVal(d, lpType),
          demand: getPowerDemandVal(d, lpType),
          status: LPStatus.Success,
        };
    });
    return mapWithTemplate(converted, dataTemplate);
  }


  const cprops = {
    ...props,
    data: Object.values(meterData),
    error: error !== undefined ? "Something went wrong, please try again" : "",
    isFetching,
    isSuccess,
    refetch,
    onRemoveMeterData,
    noTransformData: true,
  };
  return <LoadProfilePane {...cprops} paramChanged={(params) => setLpParams(params)}></LoadProfilePane>;
};


const YTLNodeComparisonScene: React.FC = () => {
    return (
        <Tabs defaultActiveKey="1" className='node-comparison-page'>
            <TabPane tab="Load Profile Comparison" key="1" className='tab-container node-comparison-tab' style={{ flexFlow: 'column' }}>
                <ComparisonLoadProfilePane meterId={0} dcuId={0} name={"Compare nodes"} meterNo={""} />
            </TabPane>
        </Tabs>
    );
}

export default YTLNodeComparisonScene;
