import {
    baseApi,
    Response,
    ListResponse,
} from './baseApi';


export enum NodeType {
    Bureau = 30,
    SubBureau = 31,
    PowerSupply = 32,
    Line = 33,
    StationArea = 34,
    User = 35,
    DCU = 1,
    Meter = 10,
}

export enum NodeStatus {
    Online = 1,
    Offline = 0,
}

export interface Node {
    readonly objid: number;
    readonly objtype: NodeType;
    readonly disc: string;
    readonly selected: boolean;
    readonly enabled: boolean;
    readonly eventsta: number;
    readonly curstatus: NodeStatus;
    readonly parentId?: number;
    readonly dcuId?: number;
    children: Node[];
}

export interface ProjectNode {
    readonly objid: number;
    readonly objtype: NodeType;
    readonly disc: string;
    readonly subcount: number;
    readonly curstatus: NodeStatus;
    readonly parentId?: number;
}

export interface TreeItem<T> {
    readonly disabled: boolean
    readonly checked: boolean
    readonly collapsed: boolean
    readonly text: string
    readonly value: T
    children: TreeItem<T>[];
}

export interface Region {
    readonly code: string;
    readonly name: string;
}

const SmartTTCBureaId = 3;

const prefix = '/commserver/AuthService';

const hiddenMeterList = (process.env.REACT_APP_HIDDEN_METER_LIST || "").split(',');

export const projectApi = baseApi.injectEndpoints({
    endpoints: (build) => ({
        listChildren: build.query<Node[], { accountId?: string, objectId?: number, objectType?: number }>({
            query: (params = {}) => {
                return {
                    url:  `${prefix}/getChildrenByObject`,
                    method: 'POST',
                    body: {
                        accountid: params.accountId,
                        objid: params.objectId ?? SmartTTCBureaId,
                        objtype: params.objectType ?? NodeType.Bureau,
                        treetype: "1"
                    },
                }
            },
            transformResponse: (response: Response<ListResponse<Node>>, meta, arg) => response.data.listitem.map(node => ({ ...node, parentId: arg.objectId })),
        }),
        getTree: build.query<TreeItem<ProjectNode>[], void>({
            query: () => {
                return {
                    url:  `commserver/ManageService/getChildTreeData`,
                    method: 'POST',
                    body: {
                        objectid: 1,
                        ownerid: 1,
                        ownertype: 98,
                        nodetype: 4,
                        treetype: 1
                    },
                }
            },
            transformResponse: (response: Response<ListResponse<TreeItem<ProjectNode>>>, meta, arg) => response.data.listitem,
        }),
        listMeter: build.query<Node[], { accountId?: string, meterNo?: string, onlineStatus?: number }>({
            query: (params = {}) => {
                return {
                    url:  'smartami/meterDataService/meters',
                    method: 'GET',
                    params: {
                        accountid: params.accountId,
                        terms: params.meterNo,
                        status: params.onlineStatus,
                    },
                }
            },
            transformResponse: (response: Node[], meta, arg) => hiddenMeterList ? response.filter(node => !hiddenMeterList.some(objid => parseInt(objid) === node.objid)) : response,
        }),
        listRegion: build.query<Array<Region>, any>({
            query: () => {
                return {
                    url: 'regions',
                }
            },
        })
    }),
    overrideExisting: false,
});

export const {
    useListChildrenQuery: useListChildren,
    useGetTreeQuery: useGetTree,
    useListMeterQuery: useListMeter,
    useListRegionQuery: useListRegion,
} = projectApi;
