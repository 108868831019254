import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { Account } from '../services/widgetsApi';
import type { RootState } from '../store'
import jwt_decode, { JwtPayload } from 'jwt-decode';

type AuthState = {
  user: Account | null
  token: string | null
  isTokenExpired: boolean | null;
}

const slice = createSlice({
  name: 'auth',
  initialState: { user: null, token: null, isTokenExpired: null } as AuthState,
  reducers: {
    setCredentials: (
      state,
      { payload: { user, token } }: PayloadAction<{ user: Account; token: string }>
    ) => {
      state.user = user;
      if (token) {
        state.token = token;
        const paylod = jwt_decode<JwtPayload>(token);

        if (paylod && paylod.exp) {
          state.isTokenExpired = new Date().valueOf() >= paylod.exp * 1000;
        }
      }
    },
    resetCredentials: (
        state,
    ) => {
        state.user = null;
        state.token = null;
        state.isTokenExpired = null;
    },
    setIsTokenExpired: (
      state,
      { payload: { token } }: PayloadAction<{ token: string }>
    ) => {
      const paylod = jwt_decode<JwtPayload>(token);
      if (paylod.exp) {
        state.isTokenExpired = new Date().valueOf() >= paylod.exp * 1000;
      }
    },
  },
})

export const { setCredentials, resetCredentials, setIsTokenExpired } = slice.actions

export default slice.reducer

export const selectCurrentUser = (state: RootState) => state.auth.user
