import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { getTodayYesterdayPowerAndDemand, LoadProfile, LPStatus, MeterDemandStat, MeterPowerDemandStat} from '../services/meterDataApi';
import { MeterProps, DateRange, loadProfileTypes, dateTimeRange, getDateRangeParam2, MeterData,
  getPowerDemandVal,
  extendForShowInday,
  getDateStringForInterval,
  dateRageMap,
  DatePickerType,
  findStartIndex,
 } from './MeterProps';
import { LoadProfilePane } from './LoadProfilePane';
import { toDate, toDateComponent } from '../utils/converter';



export const YTLTodayYesterdayLoadProfilePane: React.FC<MeterProps> = (props) => {

  let [startToLoad, setStartToLoad] = useState(false);
  let [lpParams, setLpParams] = useState<Record<string, any>>({ lpType: 'kW' });
  let [yesterdayTodayData, setYesterdayTodayData] = useState<Array<MeterData>>([]);
  let [dataTemplate, setDataTemplate] = useState<Array<Date>>([]);
  let [dataTemplate2, setDataTemplate2] = useState<Array<Date>>([]);

  const params = {
    objid: props.meterId,
    objtype: 10,
    start: moment(lpParams.startDate).add([DateRange.Date, DateRange.Time].some(t => t === lpParams.datePickType) ? 1 : -1, 'second').utcOffset(0, true).format("YYYY-MM-DD HH:mm:ss"),
    end: moment(lpParams.endDate).add([DateRange.Date, DateRange.Time].some(t => t === lpParams.datePickType) ? 1 : 0, 'second').utcOffset(0, true).format("YYYY-MM-DD HH:mm:ss"),
    type: getDateRangeParam2(lpParams.datePickType),
    tariff: parseInt(lpParams.tariff),
    datePickType: lpParams.datePickType,
  };

  const {
    data, error, isFetching, isSuccess, refetch,
  } = getTodayYesterdayPowerAndDemand({ ...params }, { skip: !startToLoad });
  // } = useListMeterPowerAndDemandByNode({ ...params }, { skip: !startToLoad });

  useEffect(() => {
    setStartToLoad(lpParams.startDate !== undefined && lpParams.endDate !== undefined && lpParams.lpType !== undefined && lpParams.tariff !== undefined);
  }, [props.meterId, lpParams.startDate, lpParams.endDate, lpParams.lpType, lpParams.tariff]);

  useEffect(() => {
    if (data) {
      const yesterdayTodayData = recompute(data, lpParams.datePickerType, lpParams.lpType, dataTemplate, dataTemplate2);
      setYesterdayTodayData(yesterdayTodayData);
    }
  }, [lpParams.lpType, lpParams.datePickerType, dataTemplate, dataTemplate2]);

  useEffect(() => {
    if (!lpParams.datePickType) {
      return;
    }

    let unit: any = 'day';
    switch(params.datePickType) {
      case DateRange.Time:
      case DateRange.Date:
          unit = 'day';
          break;
      case DateRange.Week:
          unit = 'week';
          break;
      case DateRange.Month:
          unit = 'month';
          break;
      case DateRange.Year:
          unit = 'year';
          break;
  }
  
  const yesterdayDate = moment(lpParams.startDate).add(-1, unit).toDate();
    const template = Array.from({ length: dateRageMap[lpParams.datePickType as DatePickerType](lpParams.startDate) }, (v, i) => {
      const ts = getDateStringForInterval(lpParams.datePickType, lpParams.startDate, i, false);
      return ts;
    });
    setDataTemplate(template);

    const template2 = Array.from({ length: dateRageMap[lpParams.datePickType as DatePickerType](yesterdayDate) }, (v, i) => {
      const ts = getDateStringForInterval(lpParams.datePickType, yesterdayDate, i, false);
      return ts;
    });
    setDataTemplate2(template2);
  }, [lpParams.datePickType, lpParams.startDate]);

  useEffect(() => {
    if (isSuccess && !isFetching && data) {
      const yesterdayTodayData = recompute(data, lpParams.datePickerType, lpParams.lpType, dataTemplate, dataTemplate2);
      setYesterdayTodayData(yesterdayTodayData);
    }
  }, [isFetching, isSuccess, data, lpParams.lpType, lpParams.datePickerType, dataTemplate, dataTemplate2]);

  function mapWithTemplate(lps: Array<LoadProfile>, dataTemplate: Array<Date>) {
    let nd: Array<LoadProfile> = []
    let dataIndex = findStartIndex(lps, dataTemplate);

    if (dataTemplate && lps.length > 0) {
        nd = dataTemplate.map(t => {
        if (dataIndex >= lps.length) {
          return {
            dataTime: toDateComponent(t),
            val: null,
            demand: null,
            status: LPStatus.Success,
          };
        }
        // console.log(item.name, dataIndex);
        const lp = lps[dataIndex];
        if (t.getFullYear() === lp.dataTime.date.year &&
        t.getMonth() === lp.dataTime.date.month - 1 &&
        t.getDate() == lp.dataTime.date.day &&
        t.getHours() == lp.dataTime.time.hour && 
        t.getMinutes() == lp.dataTime.time.minute) {
          dataIndex++;
          return lp;
        }
        
        return {
          dataTime: toDateComponent(t),
          val: null,
          demand: null,
          status: LPStatus.Success,
        };
      }) as Array<LoadProfile>;
    }
    return nd;
  }

  function recompute(data: Array<Array<MeterPowerDemandStat>>, dateType: string, lpType: string, dt1: Date[], dt2: Date[]) {
    // filter case of holiday in 15 min, hour
    const isDay = (dateType as DatePickerType === DateRange.Time) || (dateType as DatePickerType === DateRange.Date);

    const nested = data.map((lps, i) => {
      const filteredLps = isDay && lps.length === 1 && lps[0].dataTime.time.hour === 0 && lps[0].dataTime.time.minute === 0 ? [] : lps;
      const converted = filteredLps.map<LoadProfile>(d => {
          return {
            dataTime: d.dataTime,
            val: getPowerDemandVal(d, lpType),
            demand: getPowerDemandVal(d, lpType),
            status: LPStatus.Success,
          };
      });
      return mapWithTemplate(converted, i === 0 ? dt1 : dt2);
  })


    const [current, previouse] = nested;
    let name1 = '';
    let name2 = '';
    let startDate1 = current.length > 0 ? current[0].dataTime: undefined;
    let startDate2 = previouse.length > 0 ? previouse[0].dataTime : undefined;

    switch(params.datePickType) {
      case DateRange.Time:
          name1 = 'Today';
          name2 = 'Yesterday';
          break;
      case DateRange.Date:
        name1 = 'Today';
        name2 = 'Yesterday';
          if (startDate1) {
            startDate1 = {
              ...startDate1,
              time: {
                ...startDate1.time,
                minute: 0,
              }
            }
          }
          if (startDate2) {
            startDate2 = {
              ...startDate2,
              time: {
                ...startDate2.time,
                minute: 0,
              }
            }
          }
          break;
      case DateRange.Week:
          name1 = 'This Week';
          name2 = 'Last Week';
          break;
      case DateRange.Month:
          name1 = 'This Month';
          name2 = 'Last Month';
          break;
      case DateRange.Year:
          name1 = 'This Year';
          name2 = 'Last Year';
          break;
    }
    const yesterdayTodayData = [
      {
        name: name1,
        data: current,
        startDate: startDate1 ? toDate(startDate1) : startDate1
      },
      {
        name: name2,
        data: previouse,
        startDate: startDate2 ? toDate(startDate2) : startDate2
      },
    ];
    return yesterdayTodayData;
  }

  const cprops = {
    ...props,
    data: (data ? yesterdayTodayData : []),
    error: error !== undefined ? "Something went wrong, please try again" : "",
    isFetching,
    isSuccess,
    refetch,
    // timeRange: dateTimeRange,
    showTableAsGroup: true,
    // forcedSumEveryBlock: true,
    noTransformData: true,
    // name: "TEST"
    useShowNoCaplicableMethod1: true,
  };
  return <LoadProfilePane {...cprops} paramChanged={(params) => setLpParams(params)}></LoadProfilePane>;
};
