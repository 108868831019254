import React from 'react';
import { Button } from 'antd';
import { ReloadOutlined } from '@ant-design/icons'


interface Props {
  showReload: boolean;
  reload: () => void;
}

const Reloader: React.FC<Props> = (props) => {
  return (
    <>
      {props.showReload ? <Button className="btn-reload" icon={<ReloadOutlined />} onClick={() => props.reload()}></Button> : null}
      {props.children}
    </>
  )
}

export default Reloader;
