import React, { useEffect } from 'react';
import ReactECharts from 'echarts-for-react';
import Reloader from './Reloader'
import {
    NodeParams,
    TimeDurationType,
    useListMeterDemand,
} from '../services/meterDataApi';
import {
  lineChartConfig,
  loadingConfig,
} from './chartConfig';
import {
  getMarkSize,
  getColor,
} from '../utils/Chart';
import { useDashboard } from '../Providers';
import { WidgetProps } from './types';


const PeakDemandChart: React.FC<WidgetProps> = (props) => {
    const dashboard = useDashboard();
    const params = { type: TimeDurationType.hour, ...dashboard.target } as NodeParams;
    const {
      data,
      error,
      isFetching,
      refetch,
    } = useListMeterDemand(params);


   useEffect(() => {
    if (props.reloadCount > 0) {
        refetch();
    }
   }, [props.reloadCount]);

    const getOption = () => {

        return {
            ...lineChartConfig,
            yAxis: {
                ...lineChartConfig.yAxis,
                name: 'kW',
            },
            color: [
                getColor(3),
            ],
            series: [
                {
                  name: 'Peak Demand Finder',
                  type: 'line',
                  smooth: true,
                  symbol: 'none',
                  data: isFetching || !data ? [] : data.map(item => [
                      new Date(
                          item.dataTime.date.year,
                          item.dataTime.date.month - 1,
                          item.dataTime.date.day,
                          item.dataTime.time.hour,
                          item.dataTime.time.minute,
                          item.dataTime.time.second),
                      item.totalMaxDemand,
                    ]).filter(([ts, _]) => {
                      // Interim solution, will be removed later after YTL fixed, (draw value after 15 mins of each hour)
                      const hour = (ts as Date).getHours();
                      const now = new Date();
                      if (hour === now.getHours() && now.getMinutes() < 15) {
                        return false;
                      }
                      return true;
                    }),
                  markPoint: {
                    data: [
                        { type: 'max', name: 'Max' },
                        { type: 'min', name: 'Min' }
                    ],
                    symbolSize: (value: number, params: Object) => {
                      return getMarkSize(value);
                    },
                  },
                }
              ],
              tooltip: {
                trigger: 'axis',
                appendToBody: true,
            },
           };
     }
    
     const onChartReadyCallback = () => {
     }
     
     return (
      <Reloader showReload={!!error && !isFetching} reload={refetch}>
        <ReactECharts
          key="widget-1"
          style={{ height: '100%', width: '100%' }}
          option={getOption()}
          notMerge={true}
          lazyUpdate={true}
          theme={"theme_name"}
          onChartReady={onChartReadyCallback}
          showLoading={isFetching}
          loadingOption={loadingConfig}
        />
      </Reloader>
      )

}

export default PeakDemandChart;
