import React, { useEffect } from 'react';
import { Table } from 'antd';
import { useDashboard } from '../Providers'
import { useListMeterByRegional, NodeParams } from '../services/meterDataApi';
import { TableCell, TableTitle } from './Table';
import Reloader from './Reloader';
import { WidgetProps } from './types';


const MeterNetworkMonitoring: React.FC<WidgetProps> = (props) => {
    const dashboard = useDashboard();
    const params = { ...dashboard.target } as NodeParams;
    const {
      data,
      error,
      isFetching,
      refetch,
    } = useListMeterByRegional(params);

    useEffect(() => {
        if (props.reloadCount > 0) {
            refetch();
        }
    }, [props.reloadCount]);

    const columns = [
        {
          title: () => <TableTitle>Name</TableTitle>,
          dataIndex: 'platformArea',
          key: 'platformArea',
          render: (text: string) => <TableCell>{text}</TableCell>
        },
        {
          title: () => <TableTitle>Count</TableTitle>,
          dataIndex: 'num',
          key: 'num',
          align: 'right' as const,
          render: (text: string) => <TableCell>{text}</TableCell>
        },
      ];
      
      
    return (
      <Reloader showReload={!!error && !isFetching} reload={refetch}>
        <Table
            loading={isFetching}
            style={{ width: '100%', height: '100%' }}
            className="chart"
            dataSource={data}
            columns={columns}
            pagination={{ position: ['bottomCenter'] }}
        />
      </Reloader>
    );
}

export default MeterNetworkMonitoring;
