import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';

import { meterDataApi } from './services/meterDataApi';
import { widgetApi } from './services/widgetsApi';
import { baseApi } from './services/baseApi';
import authReducer from './slices/authSlices';


const loadPreState = () => {
  const accString = localStorage.getItem('account');
  let authState = {
    user: null,
    token: null,
    isTokenExpired: null,
  };
  if (accString) {
    const account = JSON.parse(accString);
    authState = {
      user: account,
      token: account.HTTP_ACCESS_TOKEN,
      isTokenExpired: null,
    }
  }

  return {
    auth: authState,
  }
}

export const store = configureStore({
  reducer: {
    [meterDataApi.reducerPath]: meterDataApi.reducer,
    [widgetApi.reducerPath]: widgetApi.reducer,
    auth: authReducer,
  },
  preloadedState: loadPreState(),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(baseApi.middleware)
})

setupListeners(store.dispatch);


// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
