import React, { useEffect, useState } from 'react';
import { Grid } from 'antd';

const { useBreakpoint } = Grid;

const Clock: React.FC = () => {
    const [now, setNow] = useState("");
    const screens = useBreakpoint();

    const makeCurrentDatetimeString = (isShort: boolean) => {
        const now = new Date();
        const timestamp = [
            now.toLocaleDateString('en-GB', {
                    day: 'numeric',
                    year: 'numeric',
                    month: 'short',
            }),
            now.toLocaleTimeString('en-GB', {
                    hour: 'numeric',
                    minute: 'numeric',
            })
        ].slice(isShort ? 1 : 0).join('  ');
        setNow(timestamp)
    }

    useEffect(() => {
        const short = !screens.xl;
        makeCurrentDatetimeString(short);
        const intervalId = setInterval(() => {
            makeCurrentDatetimeString(short);
        }, 1000);
        return () => {
            clearInterval(intervalId);
        }
    }, [screens]);

    return (
        <div className='clock-container'>
            <span className='subtitle subtitle--bigger-1 clock'>
                {now}
            </span>
        </div>
    );
}

export default Clock;
