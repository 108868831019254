import React, { useEffect, useState } from 'react';
import moment from 'moment';
import {
  useListMeterPowerAndDemandByNode,
  LoadProfile,
  LPStatus,
  MeterPowerDemandStat,
} from '../services/meterDataApi';
import {
  DateRange,
  getDateRangeParam,
  loadProfileTypes,
  getDateRangeParam2,
  MeterProps,
  MeterData,
  getDateStringForInterval,
  getPowerDemandVal,
  dateRageMap,
  DatePickerType,
  findStartIndex,
} from './MeterProps';
import { LoadProfilePane } from './LoadProfilePane';
import { toDate, toDateComponent } from '../utils/converter';


export const YTLImportExportLoadProfilePane: React.FC<MeterProps> = (props) => {

  let [startToLoad, setStartToLoad] = useState(false);
  let [lpParams, setLpParams] = useState<Record<string, any>>({ lpType: 'kW' });
  let [importExportData, setImportExportData] = useState<Array<MeterData>>([]);
  let [dataTemplate, setDataTemplate] = useState<Array<Date>>([]);

  const params = {
    objid: props.meterId,
    objtype: 10,
    start: moment(lpParams.startDate).add([DateRange.Date, DateRange.Time].some(t => t === lpParams.datePickType) ? 1 : -1, 'second').format("YYYY-MM-DD HH:mm:ss"),
    end: moment(lpParams.endDate).add([DateRange.Date, DateRange.Time].some(t => t === lpParams.datePickType) ? 1 : 0, 'second').utcOffset(0, true).format("YYYY-MM-DD HH:mm:ss"),
    type: getDateRangeParam2(lpParams.datePickType),
    tariff: parseInt(lpParams.tariff),
  };

  const {
    data, error, isFetching, isSuccess, refetch,
  } = useListMeterPowerAndDemandByNode({ ...params  }, { skip: !startToLoad });


  useEffect(() => {
    if (isSuccess && !isFetching && data) {
      const transdataImport = recompute(data, lpParams.datePickType, lpParams.lpType, dataTemplate, false);
      const transdataExport = recompute(data, lpParams.datePickType, lpParams.lpType, dataTemplate, true);
      setImportExportData(
        [
          {
          name: "Import",
          data: transdataImport
        },
        {
          name: "Export",
          data: transdataExport
        }
      ]
      );
      setStartToLoad(false);
    }
  }, [isFetching, isSuccess, data, lpParams.lpType, lpParams.datePickType, dataTemplate]);

  useEffect(() => {
    setStartToLoad(lpParams.startDate !== undefined && lpParams.endDate !== undefined && lpParams.lpType !== undefined && lpParams.tariff !== undefined);
  }, [props.meterId, lpParams.startDate, lpParams.endDate, lpParams.lpType, lpParams.tariff]);

  useEffect(() => {
    if (data) {
      const transdataImport = recompute(data, lpParams.datePickType, lpParams.lpType, dataTemplate, false);
      const transdataExport = recompute(data, lpParams.datePickType, lpParams.lpType, dataTemplate, true);
      setImportExportData(
        [
          {
          name: "Import",
          data: transdataImport
        },
        {
          name: "Export",
          data: transdataExport
        }
      ]
      );
    }
  }, [lpParams.datePickType, lpParams.lpType, dataTemplate]);

  useEffect(() => {
    if (!lpParams.datePickType) {
      return;
    }

    const template = Array.from({ length: dateRageMap[lpParams.datePickType as DatePickerType](lpParams.startDate) }, (v, i) => {
      const ts = getDateStringForInterval(lpParams.datePickType, lpParams.startDate, i, false);
      return ts;
    });
    setDataTemplate(template);

  }, [lpParams.datePickType, lpParams.startDate]);

  function mapWithTemplate(lps: Array<LoadProfile>, dataTemplate: Array<Date>) {
    let nd: Array<LoadProfile> = []
    let dataIndex = findStartIndex(lps, dataTemplate);

    if (dataTemplate && lps.length > 0) {
        nd = dataTemplate.map(t => {
        if (dataIndex >= lps.length) {
          return {
            dataTime: toDateComponent(t),
            val: null,
            demand: null,
            status: LPStatus.Success,
          };
        }
        // console.log(item.name, dataIndex);
        const lp = lps[dataIndex];
        if (t.getFullYear() === lp.dataTime.date.year &&
        t.getMonth() === lp.dataTime.date.month - 1 &&
        t.getDate() == lp.dataTime.date.day &&
        t.getHours() == lp.dataTime.time.hour && 
        t.getMinutes() == lp.dataTime.time.minute) {
          dataIndex++;
          return lp;
        }
        
        return {
          dataTime: toDateComponent(t),
          val: null,
          demand: null,
          status: LPStatus.Success,
        };
      }) as Array<LoadProfile>;
    }
    return nd;
  }

  function recompute(data: Array<MeterPowerDemandStat>, dateType: string, lpType: string, dt: Date[], isExport: boolean) {
    // filter case of holiday in 15 min, hour
    const isDay = (dateType === DateRange.Time) || (dateType === DateRange.Date);

    const filteredLps = isDay && data.length === 1 && data[0].dataTime.time.hour === 0 && data[0].dataTime.time.minute === 0 ? [] : data;
    const converted = filteredLps.map<LoadProfile>(d => {
        return {
          dataTime: d.dataTime,
          val: getPowerDemandVal(d, `${isExport ? 'Export ' : ''}${lpType}`),
          demand: getPowerDemandVal(d, `${isExport ? 'Export ' : ''}${lpType}`),
          status: LPStatus.Success,
        };
    });
    return mapWithTemplate(converted, dt);
  }

  const cprops = {
    ...props,
    data: importExportData,
    error: error !== undefined ? "Something went wrong, please try again" : "",
    isFetching,
    isSuccess,
    refetch,
    forcedSumEveryBlock: true,
    noTransformData: true,
  };
  return <LoadProfilePane {...cprops} paramChanged={(params) => setLpParams(params)}></LoadProfilePane>;
};
