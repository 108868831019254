import React, { useEffect, useRef, useState } from 'react';
import {
  Alert,
  Spin,
  Popover,
  notification,
} from 'antd';
import { useNavigate } from 'react-router-dom';
import { useAuth, useMap } from '../Providers';
import { listAllMeter, Meter } from '../services/meterDataApi';
import { useListEvent, MeterEvent, EventLevel } from '../services/eventApi';
import {
  useListMeter,
  Node,
  NodeStatus,
} from '../services/projectApi';
import { getColorByStatus } from '../utils/Chart';
import GoogleMapReact from 'google-map-react';
import Supercluster, { PointFeature } from 'supercluster';
import { BBox } from 'geojson';
import useSupercluster from 'use-supercluster';
import {
  ExclamationIcon,
  PinFilledIcon,
} from '../icons';

import "./MapScene.css";


interface MeterInfoProps {
  event: MeterEvent | undefined;
}

const MeterInfo: React.FC<MeterInfoProps> = ({ event }) => {
  return (
    <div>
      {event ?
        <div className={`event-box meter-event ${event.level === EventLevel.Critical ? 'critical' : 'warning'}`}>
          {event.level === EventLevel.Info ? null :<ExclamationIcon size={20}/> }
          <div className="content">
            <span>{event.eventdisc}</span>
            <span className='caption'>{event.eventStartTime.toLocaleString('en-GB')}</span>
          </div>
        </div>
        : null}
    </div>
  );
}

interface MarkerProps {
  text: string;
  event?: string;
  lat: number;
  lng: number;
  ratio: number;
  isOnline: boolean;
  onClick?: () => void;
}

const MeterMarker: React.FC<MarkerProps> = ({ children, text, isOnline }) => {

  return (
    <div className="marker marker-meter">
      <Popover
        content={children}
        title={`Meter No. ${text}`}
        trigger='click'
        placement="top"
      >
        <div className='marker-content-wrapper'>
          <PinFilledIcon size={80} color={getColorByStatus(isOnline ? 'online' : 'offline')}/>
        </div>
      </Popover>
    </div>
  )
};

const MeterClusterMarker: React.FC<MarkerProps> = ({ text, ratio, isOnline, onClick }) => {
  const width = 30;
  const padding = 10;
  const markerSize = width * (1 + ratio);
  const markerRadarSize = markerSize + padding;

  return (
    <div className="marker marker-meter"
      onClick={onClick}>
      <div
          className="cluster-marker"
          style={{
            width: `${markerSize}px`,
            height: `${markerSize}px`,
            background: getColorByStatus(isOnline ? 'online' : 'offline'),
          }}
        >
          <div className="cluster-marker-radar"
              style={{
                width: `${markerRadarSize}px`,
                height: `${markerRadarSize}px`,
                background: getColorByStatus(isOnline ? 'online' : 'offline'),
              }}>
          </div>
          <span className='subtitle subtitle--bigger-2' style={{ fontSize: markerRadarSize / 3 }}>{text}</span>
        </div>
    </div>
  )
};

interface MeterProperties {
  cluster: boolean;
  disc: string;
  meterNo: string;
  meterId: string;
  point_count: number;
  curstatus: number;
}

const MapScene: React.FC = () => {
  const navigate = useNavigate();
  const auth = useAuth();
  const map = useMap();
  const mapRef = useRef<any>();

  const [eventDict, setEventDict] = useState<{ [k in string] : MeterEvent[] }>({});
  const [bounds, setBounds] = useState<BBox>();
  const [zoom, setZoom] = useState(10);
  const [points, setPoints] = useState<PointFeature<MeterProperties>[]>([]);
  const [validMeters, setValidMeters] = useState<Meter[]>();
  const [nodeDict, setNodeDict] = useState<Record<string, Node>>({});

  const { clusters, supercluster } = useSupercluster({
    points,
    bounds,
    zoom,
    options: { radius: 75, maxZoom: 20 }
  });


  let param: object = {
    accountId: auth.account?.accountid,
  };
  const {
    data: nodes,
    isFetching: isNodeFetching,
    isLoading: isNodeLoading,
    isSuccess: isNodeLoadSuccess,
    error,
    refetch,
  } = useListMeter(param);

  const {
    data: meters = [],
    isFetching,
    isSuccess,
  } =  listAllMeter(auth.account?.accountid, { skip: isNodeFetching });

  const {
    data,
    isFetching: isEventFetching,
    isSuccess: isEventSuccess,
  } = useListEvent({});

  useEffect(() => {
    if (!auth.account) {
      navigate('/', { replace: true });
    }
  }, [auth.account]);


  useEffect(() => {
    if (isSuccess) {
      const validMeters = meters.filter(meter => meter.latitude && meter.longitude);
      const points = validMeters.map<PointFeature<MeterProperties>>(meter => {
        const node = nodeDict[meter.pMeterId];
        if (node === undefined) {
          console.log(`${meter.pMeterId} - ${meter.meterNo} is missing`, meter.curstatus ? 'online': 'offline');
        }
        return {
          type: "Feature",
          properties: {
            cluster: false,
            meterNo: meter.meterNo,
            meterId: meter.pMeterId,
            disc: meter.disc,
            point_count: 1,
            curstatus: (node ? node.curstatus : meter.curstatus),
          },
          geometry: {
            type: "Point",
            coordinates: [
              meter.longitude,
              meter.latitude,
            ]
          }
      }
    }
      );
      setValidMeters(validMeters);
      setPoints(points);
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isEventSuccess && !isEventFetching && data) {

      const dict = data
      .map(item => ({
        ...item,
        eventStartTime: new Date(item.eventStartTime),
        eventEndTime: new Date(item.eventEndTime),
        reportTime: new Date(item.reportTime),
        isNew: false,
      }))
      .sort((a, b) => a.eventStartTime.valueOf() - b.eventStartTime.valueOf())
      .reduce<{ [k in string]: MeterEvent[] }>((dict, event) => {
        if (dict[event.meterno]) {
          dict[event.meterno].push(event);
        }
        else {
          dict[event.meterno] = [event];
        }
        return dict;
      }, {});
      setEventDict(dict);
    }
  }, [isEventFetching, isEventSuccess]);

  useEffect(() => {
    if (map.meterId && validMeters) {
      const meter = validMeters.find(meter => meter.pMeterId === String(map.meterId));
      if (meter) {
        mapRef.current.setZoom(22);
        mapRef.current?.panTo({ lat: meter.latitude, lng: meter.longitude });
      } else {
        notification['error']({
          message: 'Cannot Show Meter',
          description:
            'No Geolocation Data',
        });
      }
    }
  }, [map.meterId]);

  useEffect(() => {
    if (isNodeLoadSuccess && nodes) {
      setNodeDict(nodes.reduce((accu, cur) => ({...accu, [`${cur.objid}`] : cur }), {}));
    }
  }, [nodes, isNodeLoadSuccess]);

  function navigateToCluster(cluster: PointFeature<Supercluster.ClusterProperties & Supercluster.AnyProps> | PointFeature<MeterProperties> )  {
    if (!!!supercluster || !!!cluster.id) {
      return;
    }
    const clusterId = typeof cluster.id === 'string' ? parseInt(cluster.id) : cluster.id

    mapRef.current.panTo({ lat: cluster.geometry.coordinates[1], lng: cluster.geometry.coordinates[0] });
    mapRef.current.setZoom(supercluster.getClusterExpansionZoom(clusterId));
  }

  return (
    <Spin spinning={isFetching}>
    {false ?
    <Alert
      message="Save Failed"
      description={''}
      type="error"
      closable
    />
    : null}
    <div className='map-container'>
      <GoogleMapReact
        bootstrapURLKeys={{ key: map.key }}
        defaultCenter={map.center}
        defaultZoom={map.zoom}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map }) => {
          mapRef.current = map;
        }}
        onChange={({ zoom, bounds }) => {
          setZoom(zoom);
          setBounds([
            bounds.nw.lng,
            bounds.se.lat,
            bounds.se.lng,
            bounds.nw.lat
          ]);
        }}
      >
        {
          clusters.map(cluster => {
            const [longitude, latitude] = cluster.geometry.coordinates;

            if (cluster.properties.cluster) {
              const {
                point_count: pointCount
              } = cluster.properties;

              let isAllMeterOnline = false;
              if (supercluster) {
                isAllMeterOnline = supercluster.getLeaves(cluster.id as number).every(pf => pf.properties.curstatus === NodeStatus.Online);
              }


              return (
                <MeterClusterMarker
                  key={`cluster-${cluster.id}`}
                  lat={latitude}
                  lng={longitude}
                  ratio={pointCount / (validMeters?.length || 1) }
                  text={`${pointCount}`}
                  onClick={() => navigateToCluster(cluster)}
                  isOnline={isAllMeterOnline}
                  >
                </MeterClusterMarker>
              );
            }
            else {
              return (
                <MeterMarker
                  key={`meter-${cluster.properties.meterId}`}
                  lat={latitude}
                  lng={longitude}
                  ratio={1}
                  isOnline={cluster.properties.curstatus === 1}
                  text={cluster.properties.disc}>
                    <MeterInfo event={eventDict[cluster.properties.meterNo]?.at(-1)}/>
                </MeterMarker>
              );
            }
          })
        }
      </GoogleMapReact>
    </div>
  </Spin>
  );
}

export default MapScene;
