import React, { useEffect } from 'react';
import { Table } from 'antd';
import { TableCell, TableTitle } from './Table';
import Reloader from './Reloader'
import {
  getMeterTypeName,
  useListMeterCommType,
  NodeParams
} from '../services/meterDataApi';
import { useDashboard } from '../Providers';
import { WidgetProps } from './types';


const MeterNetworkMonitoring: React.FC<WidgetProps> = (props) => {

    const dashboard = useDashboard();
    const params = {...dashboard.target } as NodeParams;
    const {
      data,
      error,
      isFetching,
      refetch,
    }  = useListMeterCommType(params);
  
    useEffect(() => {
      if (props.reloadCount > 0) {
          refetch();
      }
    }, [props.reloadCount]);

    const columns = [

        {
          title: () => <TableTitle>Meter Type</TableTitle>,
          dataIndex: 'commTypeName',
          key: 'platformArea',
          render: (text: string) => <TableCell>{text}</TableCell>
        },
        {
          title: () => <TableTitle>Online Rate</TableTitle>,
          dataIndex: 'onlineRate',
          key: 'onlineRate',
          align: 'right' as const,
          render: (text: string) => <TableCell>{text}</TableCell>
        },
      ];

    return (
      <Reloader showReload={!!error && !isFetching} reload={refetch}>
        <Table
            loading={isFetching}
            style={{ width: '100%', height: '100%' }}
            className="chart"
            dataSource={data ? data.map(item => ({
              ...item,
              key: `xx-${item.commType}`,
              commTypeName: getMeterTypeName(item.commType),
              onlineRate: item.count ? `${Math.floor(item.onlineCount / item.count * 100)}%` : '-' })) : []}
            columns={columns}
            pagination={{ position: ['bottomCenter'] }}
        />
      </Reloader>
    );
}

export default MeterNetworkMonitoring;
