import moment from 'moment';
import React, {
    useEffect,
    useState
} from 'react';
import {
    Button,
    DatePicker,
    Select,
    Space
} from 'antd';
import type { RangePickerProps } from 'antd/es/date-picker';
import {
    DatePickerType,
    DateRange,
    loadProfileTypes,
    Tariff,
    tariffTypes,
    OptionItem,
    defaultTimeRange,
} from './MeterProps';

const { Option } = Select;


interface LoadProfileControlsProps {
    isEnabled: boolean;
    enableTariff?: boolean;
    enableLoadProfileType?: boolean;
    enableDateType?: boolean;
    timeRange?: Array<OptionItem<DateRange>>;
    onChange: (start: Date, end: Date, loadprofileType: string, timeRange: DateRange, tariffType: Tariff) => void;
    refetch?: () => void;
    lpTypes?: Array<string>;
}

export const LoadProfileControls: React.FC<LoadProfileControlsProps> = (props) => {
    let [lpType, setLpType] = useState(props.lpTypes?.at(0) ?? "kWh");
    let [tariff, setTariff] = useState(Tariff.All);
    let [selectedDate, setSelectedDate] = useState(moment().endOf('day').toDate());
    let [datePickType, setDatePickType] = useState<DatePickerType>(DateRange.Date);
    let [startDate, setStartDate] = useState(moment().startOf('day').toDate());
    let [endDate, setEndDate] = useState(moment().endOf('day').toDate());

    const enableDateType = props.enableDateType ?? true;
    const enableTariff = props.enableTariff ?? true;
    const enableLoadprofileType = props.enableLoadProfileType ?? true;

    useEffect(() => {
        const value = moment(selectedDate);
        let dateStr = '';

        switch (datePickType) {
            case DateRange.Time:
            case DateRange.Date:
                setStartDate(value.startOf('day').toDate());
                setEndDate(value.endOf('day').toDate());
                dateStr = value.format('YYYY-MM-DD');
                break;
            case DateRange.Week:
                setStartDate(value.startOf('week').toDate());
                setEndDate(value.endOf('week').toDate());
                dateStr = value.format('YYYY-ww');
                break;
            case DateRange.Month:
                setStartDate(value.startOf('month').toDate());
                setEndDate(value.endOf('month').toDate());
                dateStr = value.format('YYYY-MM');
                break;
            case DateRange.Year:
                setStartDate(value.startOf('year').toDate());
                setEndDate(value.endOf('year').toDate());
                dateStr = value.format('YYYY');
                break;
        }

    }, [datePickType, selectedDate]);

    useEffect(() => {
        if (startDate && endDate) {
            props.onChange(startDate, endDate, lpType, datePickType, tariff);
        }
    }, [startDate, endDate, lpType, tariff]);

    const renderTariffType = () => {
        return <Select
            disabled={props.isEnabled}
            value={tariff}
            size='small'
            showSearch
            style={{ width: 100 }}
            placeholder="Search to Select"
            optionFilterProp="children"
            filterOption={(input, option) => (option?.value as string).toLowerCase().indexOf(input.toLowerCase()) >= 0}
            filterSort={(optionA, optionB) => (optionA?.value as string).toLowerCase().localeCompare((optionB.value as string).toLowerCase())}
            onSelect={(val: string) => setTariff(val as Tariff)}
        >
            {Object.keys(tariffTypes).map(key => <Option key={key} value={tariffTypes[key].value}>{tariffTypes[key].name}</Option>)}
        </Select>;
    };

    const renderLoadProfileType = (lpTypes?: Array<string>) => {
        return <Select
            value={lpType}
            size='small'
            showSearch
            style={{ width: 100 }}
            disabled={props.isEnabled}
            placeholder="Search to Select"
            optionFilterProp="children"
            filterOption={(input, option) => (option?.value as string).toLowerCase().indexOf(input.toLowerCase()) >= 0}
            filterSort={(optionA, optionB) => (optionA?.value as string).toLowerCase().localeCompare((optionB.value as string).toLowerCase())}
            onSelect={(val: string) => setLpType(val)}
        >
            {Object.keys(loadProfileTypes).filter(name => lpTypes ? lpTypes.includes(name) : true).map(name => <Option key={name} value={name}>{name}</Option>)}
        </Select>;
    };

    const renderDateRangeType = () => {
        return (
            <Select
                value={datePickType}
                disabled={props.isEnabled}
                defaultValue={datePickType}
                onSelect={(val: DatePickerType) => setDatePickType(val)}
                size="small"
            >
                {(props.timeRange ?? defaultTimeRange).map(item => <Option key={`TR-${item.value}`} value={item.value}>{item.name}</Option>)}
            </Select>
        );
    };

    const disabledDate: RangePickerProps['disabledDate'] = current => {
        // Can not select days before today and today
        return current && current > moment().endOf('day');
      };

    return (
        <Space style={{ marginBottom: 0, marginLeft: 10, marginRight: 10, justifyContent: 'flex-end' }} wrap>
            {props.children}
            {enableTariff
                ? renderTariffType()
                : null}
            {enableLoadprofileType
                ? renderLoadProfileType(props.lpTypes)
                : null}
            {enableDateType
                ? renderDateRangeType()
                : null}
            <DatePicker
                size='small'
                picker={datePickType === 'time' ? 'date' : datePickType}
                disabled={props.isEnabled}
                defaultValue={moment(startDate)}
                disabledDate={disabledDate}
                onChange={(value, valStr) => {
                    if (value) {
                        setSelectedDate(value.toDate());
                    }
                }} />
            {props.refetch
                ? <Button size='small' onClick={props.refetch}>Reload</Button>
                : null}
        </Space>
    );
};
