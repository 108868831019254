import React, { useEffect, useRef, useState } from 'react'
import { Button, Spin } from 'antd';
import {
  LoadingOutlined,
  ReloadOutlined,
} from '@ant-design/icons';
import { useDebounce } from 'use-debounce';
import {
  SearchIcon,
} from '../icons';


interface Props {
  loading: boolean;
  onChange?: (text: string) => void;
  onRefresh?: () => void;
}

const antIcon = <LoadingOutlined style={{ fontSize: 20 }} spin />;

const SearchInput: React.FC<Props> = (props) => {
  const [text, setText] = useState('');
  const [value] = useDebounce(text, 500);
  const inputRef = useRef<any>();

  useEffect(() => {
    if (props.onChange) {
      props.onChange(value);
    }
  }, [value]);

  useEffect(() => {
    if (!props.loading) {
      inputRef.current.focus();
    }
  }, [props.loading]);

  return (
    <div className='my-input'>
      <i><SearchIcon /></i>
      <input
        placeholder='Name or ID'
        onChange={(e) => setText(e.target.value)}
        disabled={props.loading}
        ref={inputRef}
        />
      <Spin indicator={antIcon} spinning={props.loading} />
      {!props.loading
       ? <Button
          size="small"
          type='text'
          className="btn-reload"
          onClick={() => props.onRefresh && props.onRefresh()}
          icon={<ReloadOutlined />} />
       : null
      }
    </div>
  )
}

export default SearchInput;
