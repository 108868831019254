import React, {
  useEffect,
  useState,
} from 'react';
import { Button } from 'antd';


interface Props {
  options: string[];
  onChange?: (index: number) => void;
}


const Segmented: React.FC<Props> = (props) => {
  const [activateIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    if (props.onChange) {
      props.onChange(activateIndex);
    }
  }, [activateIndex]);

  return (
    <div className='segment'>
      {props.options.map((option, index) =>
        <Button
          key={option}
          type="text"
          className={activateIndex === index ? 'selected' : ''}
          onClick={() => setActiveIndex(index)}
        >
          {option}
        </Button>
      )}
    </div>
  )
}

export default Segmented;
