
export const lineChartConfig = {
  xAxis: {
    type: 'time',
    boundaryGap: false,
    axisLine: { show: false },
    axisTick: { show: false },
    axisLabel: {
      fontFamily: 'IBM Plex Sans Thai Looped',
      fontStyle: 'regular',
      fontWeight: '400',
      fontSize: 5,
      lineHeight: 15,
      color: "#433A31",
      show: true,
      rotate: 45,
    },
    splitLine: {
      show: false,
    },
  },
  grid: { show: false },
  yAxis: {
    type: 'value',
    boundaryGap: [0, '100%'],
    axisLine: { show: false },
    axisTick: { show: false },
    axisLabel: {
      fontFamily: 'IBM Plex Sans Thai Looped',
      fontStyle: 'regular',
      fontWeight: '400',
      fontSize: 9,
      lineHeight: 15,
      color: "#433A31",
      formatter: (val: number) => {
        let suffix = '';
        let newVal = `${val}`;
        if (val > 1000000) {
          suffix = 'M';
          newVal = (val / 100000).toFixed(1);
        } else if (val > 1000) {
          suffix = 'k';
          newVal = (val / 1000).toFixed(1);
        }
        return `${newVal}${suffix}`;
      },
      overflow: 'truncate',
    },
    splitLine: {
      lineStyle: {
        color: ['#D1CABC'],
        width: 0.38,
      }
    },
  },
}

export const barChartConfig = {
  xAxis: {
    type: 'category',
    boundaryGap: true,
    axisLine: { show: false },
    axisTick: { show: false },
    axisLabel: {
      fontFamily: 'IBM Plex Sans Thai Looped',
      fontStyle: 'regular',
      fontWeight: '400',
      fontSize: 5,
      lineHeight: 15,
      color: "#433A31",
      show: true,
    },
    splitLine: {
      show: false,
    },
  },
  grid: { show: false },
  yAxis: {
    type: 'value',
    boundaryGap: [0, '100%'],
    axisLine: { show: false },
    axisTick: { show: false },
    axisLabel: {
      fontFamily: 'IBM Plex Sans Thai Looped',
      fontStyle: 'regular',
      fontWeight: '400',
      fontSize: 9,
      lineHeight: 15,
      color: "#433A31",
      formatter: (val: number) => {
        let suffix = '';
        let newVal = `${val}`;
        if (val > 1000000) {
          suffix = 'M';
          newVal = (val / 100000).toFixed(1);
        } else if (val > 1000) {
          suffix = 'k';
          newVal = (val / 1000).toFixed(1);
        }
        return `${newVal}${suffix}`;
      },
      overflow: 'truncate',
    },
    splitLine: {
      lineStyle: {
        color: ['#D1CABC'],
        width: 0.38,
      }
    },
  },
}

export const loadingConfig = {
  text: '',
  color: '#FF9B00',
  textColor: '#000',
  maskColor: 'rgba(255, 255, 255, 0.8)',
  zlevel: 0,

  fontSize: 12,
  showSpinner: true,
  spinnerRadius: 10,
  lineWidth: 5,
  fontWeight: 'normal',
  fontStyle: 'normal',
  fontFamily: 'IBM Plex Sans Thai',
}
