import React from 'react';

import './Amount.css';

interface AmountProps {
    title: string;
    value: string;
    unit?: string;
    min?: string;
    max?: string;
    supplement?: string;
    amountStyle?: any;
    note?: string;
}
export const Amount: React.FC<AmountProps> = (props) => {
    return (
        <div className='amount-container'>
            <div className='amount-main'>
                <span className='subtitle'>{props.title}</span>
                <span className='amount-value-container'>
                    <span className='head head-large amout-value' style={props.amountStyle}>{props.value}</span>
                    {props.unit && <span className='chart-text-small chart-text-small--2 amount-unit'>{props.unit}</span>}
                </span>
            </div>
            <div className='amount-sub'>
                {props.min ?
                    <div className="item">
                        <label className='chart-text-small chart-text-small--2'>MIN</label>
                        <span className='chart-text-small chart-text-small--2'>{props.min}</span>
                    </div>
                    : null}
                {props.max ?
                    <div className="item">
                        <label className='chart-text-small chart-text-small--2'>MAX</label>
                        <span className='chart-text-small chart-text-small--2'>{props.max}</span>
                    </div>
                    : null}
                {props.supplement ?
                    <div className={`item ${props.note ? 'item-note' : ''}`}>
                        <span className='chart-text-small chart-text-small--2'>{props.supplement}</span>
                        {props.note ? <span className='chart-text-small chart-text-small--4 note-text'>{props.note}</span> : null}
                    </div>
                    : null}
            </div>
        </div>
    );
};
