import React, { ReactNode, useEffect, useState } from 'react';
import {
    Button,
} from 'antd';


interface Props {
    icon: ReactNode;
    onClick: (selected: boolean) => void;
}

const PushButton: React.FC<Props> = ({ onClick, icon }) => {
    const [isSelected, setSelected] = useState(false);

    useEffect(() => {
        onClick(isSelected);
    }, [isSelected]);

    return (
        <Button
            icon={icon}
            className={isSelected ? "btn btn--selected" : "btn"}
            onClick={() => setSelected(!isSelected)}/>
    )
}

export default PushButton;
