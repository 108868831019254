import React, { useEffect, useState } from 'react';
import ReactECharts from 'echarts-for-react';
import Reloader from './Reloader'
import {
    useGetMeterOnlineStats,
    NodeParams,
} from '../services/meterDataApi';
import {
    loadingConfig,
 } from './chartConfig';
import { useDashboard } from '../Providers';
import { WidgetProps } from './types';
import { getColorByStatus } from '../utils/Chart';


const OnlineRatioChart: React.FC<WidgetProps> = (props) => {
    const dashboard = useDashboard();
    const params = { ...dashboard.target } as NodeParams;
    const {
        data,
        error,
        isFetching,
        refetch,
    } = useGetMeterOnlineStats(params);

    const [items, setItems] = useState<Array<Record<string, any>>>([]);

    useEffect(() => {
        if (props.reloadCount > 0) {
            refetch();
        }
    }, [props.reloadCount]);

    useEffect(() => {
        if (!isFetching && data) {
            const stats: Record<string, number> = { online: data.online, offline: data.offline };
            setItems(Object.keys(stats).map<Record<string, any>>(key => ({ name: key, value: stats[key], itemStyle: { color: getColorByStatus(key), } })).filter(item => item.value > 0));
        }
    }, [isFetching, data]);

    const getOption = () => {
        return {
            tooltip: {
                trigger: 'item',
                appendToBody: true,
            },
            legend: {
                orient: 'vertical',
                left: 'right',
                bottom: 0,
                textStyle: {
                    fontFamily: 'IBM Plex Sans Thai Looped',
                    fontSize: 9,
                    fontStyle: 'normal',
                    fontHeight: 400,
                    lineHeight: 15,
                },
            },
            series: [
                {
                    name: 'Online Ratio',
                    type: 'pie',
                    radius: '70%',
                    data: items,
                    emphasis: {
                        itemStyle: {
                            shadowBlur: 10,
                            shadowOffsetX: 0,
                            shadowColor: 'rgba(0, 0, 0, 0.5)'
                        }
                    }
                }
            ]
        };
    }

    const onChartReadyCallback = () => {
    }

    return (
        <Reloader showReload={!!error && !isFetching} reload={refetch}>
            <ReactECharts
                key="widget-3"
                style={{ height: '100%', width: '100%' }}
                option={getOption()}
                notMerge={true}
                lazyUpdate={true}
                theme={"theme_name"}
                onChartReady={onChartReadyCallback}
                showLoading={isFetching}
                loadingOption={loadingConfig}
            />
        </Reloader>
    )

}

export default OnlineRatioChart;
