const transDict: Record<string, string> = {
    "Long Time Power Failure": "Long power outage",
    "Short Time Power Failure": "Short power outage",
    "Power Up": "Power restore",
    "VOLTSAG": "Sag voltage",
    "OVERVOLT": "Swell voltage",
    // "Over current": "Over current",
    "CURRENTOVER": "Over current",
    // "Demand over kW": "Demand over kW",
    "DEMANDOVER": "Demand over kW",
    // "Demaand over kVar": "Demand over kVar",
    "DEMANDOVER_KVAR": "Demand over kVar",
    "BILLING_RESET": "Billing reset",
    // "Meter cover open": "Meter cover open",
    "Front COVER OPEN": "Meter cover open",
    // "Meter cover close": "Meter cover close",
    "Front COVER CLOSED": "Meter cover close",
    "COVER": "Terminal cover open",
    "Terminal Cover Closed": "Terminal cover close",
    // "Module cover open":"Module cover open",
    "MODULECOVER":"Module cover open",
    "Module cover close": "Module cover close",
    "Module fail": "Module fail",
    "Module recovery": "Module recovery",
    "REVERSE_IA": "Current reverse",
    "VOLTAGE TOLERANCE": "Voltage tolerance",
    "BALANCE": "BALANCE",
};

export function translate(name: string): string {
    const value = transDict[name.trim()];
    console.log('Translate: name -> ', name, ' to value', value);
    return value ? value : name;
}