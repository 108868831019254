import React, { useEffect } from 'react';
import ReactECharts from 'echarts-for-react';
import Reloader from './Reloader'
import {
    getMeterTypeName,
    useListMeterCommType,
    NodeParams,
} from '../services/meterDataApi';
import {
    loadingConfig,
 } from './chartConfig';
import { useDashboard } from '../Providers';
import { WidgetProps } from './types';


const DataCommTypeChart: React.FC<WidgetProps> = (props) => {
    const dashboard = useDashboard();
    const params = { ...dashboard.target } as NodeParams;
    const {
        data,
        error,
        isFetching,
        refetch,
    } = useListMeterCommType(params);

    useEffect(() => {
        if (props.reloadCount > 0) {
            refetch();
        }
    }, [props.reloadCount]);

    const getOption = () => {
        return {
            tooltip: {
                trigger: 'item',
                appendToBody: true,
            },
            legend: {
                orient: 'vertical',
                left: 'right',
                bottom: 0,
                textStyle: {
                    fontFamily: 'IBM Plex Sans Thai Looped',
                    fontSize: 9,
                    fontStyle: 'normal',
                    fontHeight: 400,
                    lineHeight: 15,
                },
            },
            color: [
                '#FF9B00',
                '#FF6A00',
                '#D2DC44',
                '#47C4EB',
                '#5E2726',
            ],
            series: [
                {
                    name: 'Communicatio Type',
                    type: 'pie',
                    radius: '70%',
                    data: isFetching || !data ? [] : data.filter(item => item.count > 0).map(item => ({ value: item.count, name: getMeterTypeName(item.commType) })),
                    emphasis: {
                        itemStyle: {
                            shadowBlur: 10,
                            shadowOffsetX: 0,
                            shadowColor: 'rgba(0, 0, 0, 0.5)'
                        }
                    }
                }
            ]
        };
    }

    const onChartReadyCallback = () => {
    }

    return (
        <Reloader showReload={!!error && !isFetching} reload={refetch}>
            <ReactECharts
                key="widget-2"
                style={{ height: '100%', width: '100%' }}
                option={getOption()}
                notMerge={true}
                lazyUpdate={true}
                theme={"theme_name"}
                onChartReady={onChartReadyCallback}
                showLoading={isFetching}
                loadingOption={loadingConfig}
            />
        </Reloader>
    )

}

export default DataCommTypeChart;
