import {
    baseApi,
} from './baseApi';

export interface Summary {
    readonly today: number;
    readonly year: number;
}

export interface EnergyProduction {
    readonly exportEnergy: Summary;
    readonly saving: Summary;
    readonly currency: string;
}

export interface NodeParams {
    readonly objid: number;
    readonly objtype: number;
}

const prefix = '/energy';

export const energyApi = baseApi.injectEndpoints({
    endpoints: (build) => ({
        getEnergyProduction: build.query<EnergyProduction, NodeParams>({
            query: (params) => {
                return {
                    url:  `${prefix}/production`,
                    method: 'GET',
                    params: {
                        objectId: params.objid,
                        objectType: params.objtype,
                    },
                }
            },
            transformResponse: (response: EnergyProduction, meta, arg) => response,
        }),
    }),
    overrideExisting: false,
});

export const {
    useGetEnergyProductionQuery: useGetEnergyProduction,
} = energyApi;
