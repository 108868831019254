import React, { useEffect, useState } from 'react';
import {
    Space,
    Select,
    Button,
} from 'antd';
import { Node } from '../services/projectApi';
import { useListNodeRegions } from '../services/meterDataApi';
import { useComparison } from '../Providers';

export interface OptionType<T> {
    name: string;
    value: T;
  }


const { Option } = Select;


const CustomerGroupFilter2: React.FC = () => {
    let comparison = useComparison();
    const [regionCode, setRegionCode] = useState<string>();
    const [regionDict, setRegionDict] = useState<Record<string, Node>>({});

    const {
        data: regions,
        isFetching: isFetchingRegion,
        isSuccess: isSuccessRegion,
        refetch: refetchRegion
      } = useListNodeRegions({})


    useEffect(() => {
        if (isSuccessRegion && regions) {
          setRegionDict(
            regions.reduce((accu, r) => ({ ...accu, [String(r.objid)] : r }), {})
          )
        }
      }, [isFetchingRegion, isSuccessRegion, regions]);

    // useEffect(() => {
    //     comparison.addRate(rates[0]);
    // }, [rates]);
    useEffect(() => {
      if (!comparison.isRegionLoading) {

      }
    }, [comparison.isRegionLoading])
    return (
        <Space direction="vertical" size="large" style={{width: "100%"}}>
          <Select
            // mode="multiple"
            allowClear
            placeholder="Select a region"
            // disabled={isFetching || isFetching2 || isFetchingRegion}
            onSelect={(val: string) => setRegionCode(val)}
            // onDeselect={(val: string) => comparison.removeRegion(val)}
            onClear={() => setRegionCode("")}
            size="large"
            style={{width: 240}}
            disabled={comparison.isRegionLoading}
          >
            {(isSuccessRegion ? (regions ?? []) : []).map(region =>
              <Option
                key={`region-${region.objid}`}
                value={region.objid}
                disabled={comparison.regions.findIndex(v => v.value === String(region.objid)) !== -1}>
                  {region.disc}
              </Option>)
            }
          </Select>
          <Button
            disabled={comparison.isRegionLoading || regionCode === "" || comparison.regions.findIndex(v => v.value === regionCode) !== -1 }
            onClick={() => {
              if (regionCode) {
                comparison.addRegion( regionDict[regionCode].disc, String(regionCode));
                setRegionCode("");
              }
            }}>Add</Button>
        </Space>
    );
}

export default CustomerGroupFilter2;