import React, {
    useEffect,
    useState,
    useRef,
} from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import {
    Button,
    Badge,
    Descriptions,
    Spin,
    Space,
} from 'antd';
import {
    useGetInfo,
    useRetrieveFirmware,
} from '../services/meterApi';

interface MeterSettingsProps {
    objectId: number;
    name: string;
    meterNo: string;
}

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const MeterSettings: React.FC<MeterSettingsProps> = (props) => {

    const [startToLoad, setStartToLoad] = useState(false);
    const [isStartTimer, setIsStartTimer] = useState(false);
    const timerRef = useRef<any>(null);

    const {
        data: meterInfo,
        isSuccess: isGetInfoSuccess,
        isFetching: isGetInfoFetching,
        isError,
        refetch,
    } = useGetInfo({ id: props.objectId }, { skip: !startToLoad });

    const [
        retrieveFirmware,
        {
            isSuccess: isRetrieveSuccess,
            isLoading
        }
    ] = useRetrieveFirmware();

    useEffect(() => {
        if (isRetrieveSuccess && !isLoading) {
            setStartToLoad(true);
            setIsStartTimer(true);
        }
    }, [isRetrieveSuccess, isLoading]);

    useEffect(() => {
        if (!isStartTimer) return;

        console.log('start timer');
        const timer = setInterval(() => {
            console.log('refetch');
            refetch();
        }, 2000);
        
        refetch();

        return () => {
            console.log('clear timer');
            setStartToLoad(false);
            clearInterval(timer);
        }
    }, [isStartTimer]);

    useEffect(() => {
        if (isError) {
            setIsStartTimer(false);
            return;
        }

        if (!isGetInfoFetching && isGetInfoSuccess && meterInfo) {
            if (!meterInfo.isRetrieving) {
                setIsStartTimer(false);
            }
        }
    }, [isGetInfoFetching, isGetInfoSuccess, isError, isError]);

    useEffect(() => {
        if (timerRef.current !== null) {
            clearInterval(timerRef.current);
        }
        setStartToLoad(true);
        refetch();
    }, [props.objectId]);

    return (
        <Descriptions title="Meter Info" layout="horizontal" bordered column={{ xxl: 4, xl: 3, lg: 2, md: 1, sm: 1, xs: 1 }}>
            {/* <Descriptions.Item label="Name">{props.name ?? "-"}</Descriptions.Item> */}
            <Descriptions.Item label="Meter No.">{props.meterNo}</Descriptions.Item>
            <Descriptions.Item label="Firmware">
                <Space>
                {meterInfo?.isRetrieving ? <Badge status="processing" text="Reading" /> : (meterInfo?.firmware ?? "N/A")}
                {(isLoading)
                    ? <Spin indicator={antIcon} />
                    : <Button
                        disabled={meterInfo?.isRetrieving}
                        onClick={() => retrieveFirmware({ objectId: props.objectId, objectType: 10 })}>Read</Button>
                }
                </Space>
            </Descriptions.Item>
        </Descriptions>
    )
}

export default MeterSettings;
