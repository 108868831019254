import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import ReactECharts from 'echarts-for-react';
import {
  Alert,
  Button,
  Select,
  Table,
  Tag,
  Row,
  Col,
} from 'antd';
import {
  ExportOutlined,
} from '@ant-design/icons';
import {
  lineChartConfig,
  loadingConfig
} from './chartConfig';
import {
  TableCell,
  TableTitle
} from './Table';
import {
  getMarkSize,
  getAxisMaxValue,
  renderInfo,
  renderInfo2,
  startFromZero,
  formatLPValue,
} from '../utils/Chart';
import Reloader from './Reloader';
import {
  DatePickerType,
  DateRange,
  dateRageMap,
  getxLabel,
  groupLoadProfile,
  filterByTariff,
  MeterDataProps,
  Tariff,
  filterXAxis,
  extendForShowInday,
  getDateStringForInterval,
} from './MeterProps';
import { NodeType } from '../services/projectApi';
import { useContainerDimensions } from '../hooks';
import { LoadProfileControls } from './LoadProfileControls';
import { exportDataAsCSV } from '../utils/exportData';
import { EChartsOption } from 'echarts';
import { LPStatus, LoadProfile } from '../services/meterDataApi';
import { toDate } from '../utils/converter';


const colors = [
  '#FF4200',
  '#D2DC44',
  '#FF9B00',
  '#47C4EB',
  '#5E2726',
  '#FF6A00',
  '#D2DC44',
];

const getLineStyle = (objectType?: number) => {

  if (objectType === undefined) {
    return 'line';
  }

  switch (objectType) {
    case NodeType.StationArea:
      return 'line';
    case NodeType.Line:
      return 'dashed';
    case NodeType.PowerSupply:
      return 'dotted';
  }
}


export const LoadProfilePane: React.FC<MeterDataProps> = (props) => {

  let [lpType, setLpType] = useState("kWh");
  let [tariff, setTariff] = useState(Tariff.All);
  let [datePickType, setDatePickType] = useState<DatePickerType>(DateRange.Date);
  let [startDate, setStartDate] = useState(moment().startOf('day').toDate());
  let [endDate, setEndDate] = useState(moment().endOf('day').toDate());
  let [tableData, setTableData] = useState<(number | number | Date | null)[][]>([]);
  let [lpData, setLpData] = useState<{
    [k in string]: (number | Date)[][];
  }>({});
  let [title, setTitle] = useState('Load profile');
  let bodyRef = useRef<HTMLDivElement | null>();
  const { width, height } = useContainerDimensions(bodyRef);
  const {
    data, isFetching, isSuccess, error, refetch,
  } = props;

  let [columns, setColumns] = useState<any>();
  let [showNoDataForHoliday, setShowDataForHoliday] = useState(false);
  let [hasBeenLoadData, setHasBeenLoadData] = useState(false);
  let [now, setNow] = useState(new Date());

  useEffect(() => {
    if (isFetching && !isSuccess)
      return;
    const noTransformData = props.noTransformData !== undefined ? props.noTransformData : false;
    const useStartFromZero = false; //noTransformData ? false : lpType == 'kWh' || lpType == 'kVarh';

    // console.log(isFetching, isSuccess, data, tariff, datePickType)
    const onlyMaxDemand =  props.forcedSumEveryBlock ? false : lpType === 'kW';

    let lp: Record<string, (number | Date | null)[][]> = {};
    if (noTransformData) {
      lp = data.reduce<Record<string, (number | Date | null)[][]>>((acc, item) =>
      ({ ...acc, [item.name]: item.data.map(d => [toDate(d.dataTime), d.val, (d.ogDataTime ? toDate(d.ogDataTime) : null), d.demand ])}), {});
    }
    else {
      lp = data.reduce<Record<string, (number | Date | null)[][]>>((acc, item) =>
      ({ ...acc, [item.name]: groupLoadProfile(item.data, datePickType, item.startDate ?? startDate, lpType, onlyMaxDemand) }), {});
    }

    const needToApplyFilterByTariff = [DateRange.Date, DateRange.Time].some(t => t === datePickType);

    const preBlockDict = data.filter(item => item.data.length > 0).reduce<Record<string, LoadProfile>>((dict, item) => ( { ...dict, [item.name]: item.data.find(lp => !!lp.prevBlock)?.prevBlock! }), {});

    const lpByTariff = Object.keys(lp).reduce((accu, key) => {
      return {
        ...accu,
        [key]: lp[key]
        .map(useStartFromZero ? startFromZero(preBlockDict[key]?.val) : (r) => r)
        .filter(([ts]) =>  needToApplyFilterByTariff ? filterByTariff(ts as Date, tariff) : true)
        ,
      }
    }, {});

    setLpData(lpByTariff);

    const longestLps = Object.values(lp).sort((a, b) => b.length - a.length).at(0);
    let tableData: (number | Date | null)[][] = [];
    if (longestLps) {
      tableData = longestLps.map<(number | Date | null)[]>((item, index) => {
        const ret = data.map<(number | Date | null)[]>(({ name: key }) => {
          if (lp[key][index]) {
            let ret = [
              lp[key][index][0],
              lp[key][index][1]
            ];
            if (ret[0] && (ret[0].valueOf() > now.valueOf())) {
              ret[1] = null;
            }
            if (useStartFromZero) {
              return startFromZero(preBlockDict[key]?.val)(ret, index, lp[key])
            }
            return ret;
          }
          else {
            return [null, null]
          }

        });
        return ret.flatMap((item, i) => item).filter((_, index) => props.showTableAsGroup ? true : (index === 0 || index % 2 === 1));
      })

      if (tariff !== Tariff.All) {
        tableData = tableData.filter((rec) => {
          if (needToApplyFilterByTariff) {
            if (props.showTableAsGroup) { 
              return rec.filter((_, idx) => idx % 2 === 0).some(ts => {
                return filterByTariff(ts as Date, tariff);
              })
            }
            else {
              const [ts] = rec;
              return filterByTariff(ts as Date, tariff)
            }
          }
          else {
            return true;
          }
        });

        if (tariff === Tariff.Holiday && data.length > 1) {
          //TODO: Need refactor
          const holidayDays = new Set(
              [
                ...data[0].data,
                ...data[1].data,
              ].filter(i => i.status === LPStatus.Success).map(i => i.dataTime.date.day)
          );
          // tableData = tableData.filter(([ts]) => ts ? holidayDays.has((ts as Date).getDate()) : false);
          tableData = tableData.filter((rec) => {
            if (props.showTableAsGroup) { 
              return rec.filter((_, idx) => idx % 2 === 0).some(ts => {
                return ts !== null && holidayDays.has((ts as Date).getDate())
              })
            }
            else {
              const [ts] = rec;
              return holidayDays.has((ts as Date).getDate())
            }
          })
        }
      }
    }

    setTableData(
      tableData
    );
    setTitle(`Load Profile of ${props.meterNo ? `Meter No.${props.meterNo}` : props.name}`);

  }, [isFetching, isSuccess, data, tariff, datePickType, props.meterNo]);

  useEffect(() => {
    if (isFetching) {
      setHasBeenLoadData(true)
      setNow(new Date());
    }
  }, [isFetching])

  useEffect(() => {
    // Interim to show not capliable message
    if (props.useShowNoCaplicableMethod1) {
      if (isSuccess && !isFetching) {
        if (hasBeenLoadData) {
          setShowDataForHoliday(tableData.length === 0 && data.length > 0);
        }
      }
      return;
    }

    if (!isSuccess && !isFetching) {
      if (hasBeenLoadData) {
        setShowDataForHoliday(tableData.length === 0 && data.length > 0);
      }
    }
  }, [isSuccess, isFetching, tableData, data, hasBeenLoadData]);

  useEffect(() => {
    setLpData({});
  }, [props.meterId, startDate, endDate, lpType]);

  useEffect(() => {
    props.paramChanged({ startDate, endDate, lpType: lpType , datePickType, tariff });
  }, [startDate, endDate, lpType, datePickType, tariff]);

  useEffect(() => {
    if (data.length > 0) {
      const columns = props.showTableAsGroup
        ? buildColumnsForGroup(data)
        : buildColumnForFlat(data)
      setColumns(columns);
    }
  }, [props.showTableAsGroup, isSuccess, data]);

  function formatTimestamp(ts: Date, isFull: boolean) {
    if (!ts) {
      return '-';
    }

    return isFull ? ts.toLocaleString('en-GB') : ts.toLocaleDateString('en-GB')
  }

  function buildColumnsForGroup(data: Array<any>) {
    const displayAsDateTime = [DateRange.Date, DateRange.Time].some(dr => dr === datePickType);
    return data.map((item, index) => {
      return {
        title: <TableTitle>{item.name}</TableTitle>,
        children: [
          {
            title: () => <TableTitle>Timestamp</TableTitle>,
            render: (rec: Array<Date>) => <TableCell>{formatTimestamp(rec[index * 2], displayAsDateTime)}</TableCell>,
          },
          {
            title: () => <TableTitle>{lpType}</TableTitle>,
            render: (data: (Date | number | null)[]) => <TableCell>{formatLPValue(data[index * 2 + 1] as number, lpType)}</TableCell>,
            align: 'right' as const,
          }
        ]
      }
    })
  }

  function buildColumnForFlat(data: Array<any>) {
    const displayAsDateTime = [DateRange.Date, DateRange.Time].some(dr => dr === datePickType);
    return   [
      {
        title: () => <TableTitle>Timestamp</TableTitle>,
        render: ([ts, _]: [Date, number]) => <TableCell>{formatTimestamp(ts, displayAsDateTime)}</TableCell>,
        width: 140,
        fixed: 'left' as const,
      },
      ...data.map((item, index) => ({
        title: () => <TableTitle>{`${item.name}\n(${lpType})`}</TableTitle>,
        align: 'right' as const,
        render: (data: (Date | number | null)[]) => <TableCell>{formatLPValue(data[index + 1] as number, lpType)}</TableCell>
      }))
    ]
  }

  function exportLoadProfile(asGroup: boolean) {
    const content = tableData.map(row => row.map(item => (item instanceof Date) ? `"${item.toLocaleString('en-GB')}"` : (item === null ? '-' : String(item))));
    const header = asGroup
      ? data.flatMap(item => [`${item.name} Timestamp`, `${item.name}(${lpType})`])
      : ['Timestamp', ...data.map(item => `${item.name}(${lpType})`)];
    exportDataAsCSV([header, ...content], `loadprofile_${props.meterNo ? props.meterNo : 'nodes'}.csv`);
  }

  const getOption = (holidays?: Set<number>) => {

    const extendAtStart = false; //(datePickType ===  DateRange.Time || datePickType ===  DateRange.Date);

    const maxDemandFormatter = (params: any | Array<any>, ticket: string, callback: (ticket: string, html: string) => void) => {
      const getDetail = (serie: any) => {
        // TODO: Interrim to capture
        const data = lpData[serie.seriesName];
        let maxDemands = (extendAtStart ? [[0, 0], ...data] : data).filter(([ts]) => (holidays ? holidays.has((ts as Date).getDate()) : true));

        const last = data.at(-1)
        if (holidays && (datePickType === DateRange.Time || datePickType === DateRange.Date) && last) {
          if (holidays.has((last[0] as Date).getDate() - 1)) {
            // data = [ ...data, last[1] ];
            maxDemands = [...maxDemands, last];
          }
        }

        const maxDemandTs = maxDemands[serie.dataIndex][2] as Date;
        return maxDemandTs ? maxDemandTs.toLocaleTimeString('en-GB', { timeStyle: 'short' }) : '-';
      }
      if (Array.isArray(params)) {
        return renderInfo(params[0].axisValue, params, getDetail);
      }
      return renderInfo(params.axisValue, [params], getDetail);
    }

    const precisionFormatter = (params: any | Array<any>, ticket: string, callback: (ticket: string, html: string) => void) => {
      function wrapper(val: number) {
        return formatLPValue(val, lpType)
      }
      if (Array.isArray(params)) {
        return renderInfo2(params[0].axisValue, params, () => "", wrapper);
      }
      return renderInfo2(params.axisValue, [params], () => "", wrapper);
    }

    return {
      ...lineChartConfig,
      yAxis: {
        ...lineChartConfig.yAxis,
        name: lpType,
        // max: getAxisMaxValue(Object.values(lpData).flatMap(i => i).map(item => item[1] as number)),
      },
      xAxis: {
        ...lineChartConfig.xAxis,
        type: 'category',
        boundaryGap: false,
        data: Array.from({ length: dateRageMap[datePickType](startDate) }, (v, i) => {
          return getxLabel(datePickType, startDate, i, extendAtStart);
        }).filter((label, i) => {
          return filterXAxis(i, startDate, datePickType, tariff, holidays);
        })
      },
      legend: {
        data: data.map(d => d.name)
      },
      series: data.map((item, i) => {

        let markPointProps = {
          markPoint: {
            data: [
              {
                type: 'max',
                name: 'Max',
              },
            ],
            symbolSize: (value: Array<number>, params: Object) => {
              return getMarkSize(value ? value[1] : value);
            },
            // label: {
            //   formatter: (params: any) => formatLPValue(params.value, lpType),
            // }
          },
        };
        /*
        if (lpData[item.name] && lpData[item.name].length > 0) {
          let maxIndex = lpData[item.name]
            .filter(([ts]) => (holidays ? holidays.has((ts as Date).getDate()) : true))
            .filter(i => i[3] !== null && !Number.isNaN(i[3])).reduce<number>((maxIndex, rec, index, arr) => {
            const [ts, val, demandTs, demand] = rec;
            return demand > arr[maxIndex][3] ? index : maxIndex;
          }, 0)
          const maxItem = lpData[item.name][maxIndex];
          
          if (maxItem[1] !== null) {
            markPointProps = {
              markPoint: {
                data: [
                  {
                    coord: [
                      (extendAtStart ? maxIndex + 1 : maxIndex), maxItem[1],
                    ],
                    value: maxItem[1],
                  }
                ],
                symbolSize: (value: Array<number>, params: Object) => {
                  return getMarkSize(value ? value[1] : value);
                },
              },
            }
          }
        }*/

        let lps = !lpData[item.name] ? [] : lpData[item.name].filter(([ts]) => (holidays ? holidays.has((ts as Date).getDate()) : true))

        if (lpData[item.name]) {
          const last = lpData[item.name].at(-1)
          if (holidays && (datePickType === DateRange.Time || datePickType === DateRange.Date) && last) {
            // Show last of block that after holiday
            const lastTs = last[0] as Date;
            // if (holidays.has(lastTs.getDate() - 1) && lastTs.getHours() === 0 && lastTs.getMinutes() === 0) {
            //   // Hide first of block of holiday (00:00)
            //   lps = lps.slice(0, -1);
            // }
            // else 
            if (holidays.has(lastTs.getDate() - 1)) {
              lps = [ ...lps, last ];
            }
            // if (holidays.has((last[0] as Date).getDate())) {
          }
        }
        let lpsNum = lps.filter(([ts]) => ts.valueOf() <= now.valueOf()).map(([_, val]) => val);
        let dataStr = lpsNum.map(val => formatLPValue(val as number, lpType));
        let data = dataStr.map(d => parseFloat(d));
        // console.log('raw      ', lpsNum);
        // console.log('round str', dataStr);
        // console.log('round flo', data);
        if (extendAtStart) {
          data = [0, ...data];
        }
        // console.log('data', data);
        return {
          name: item.name,
          type: 'line',
          smooth: true,
          // symbol: 'none',
          data,
          ...markPointProps,
          lineStyle: {
            type: getLineStyle(item.objectType),
          },
          itemStyle: {
            color: colors.at(i),
          },
      }}),
      tooltip: {
        trigger: 'axis',
        appendToBody: true,
        // formatter: precisionFormatter,
      },
      animation: false,
    };
  };

  let holidayDays;

  if (tariff === Tariff.Holiday && data.length > 1) {
    //TODO: Need refactor
    holidayDays = new Set(
      [
        ...data[0].data,//.slice(0, data[0].data.length -1),
        ...data[1].data,//.slice(0, data[1].data.length -1),
      ].filter(i => i.status === LPStatus.Success).map(i => i.dataTime.date.day)
    );
  }

  return (
    <>
      <LoadProfileControls
          isEnabled={isFetching}
          enableDateType={true}
          enableTariff={props.enableTariff}
          enableLoadProfileType={true}
          onChange={(start: Date, end: Date, lpType: string, datePickerTypex: DatePickerType, tariffType: Tariff) => {
              setStartDate(start);
              setEndDate(end);
              setLpType(lpType);
              setDatePickType(datePickerTypex);
              setTariff(tariffType);
              }
          }
          refetch={refetch}
          timeRange={props.timeRange}
          lpTypes={props.lpTypes}
          >
        <span className='subtitle subtitle--bigger-2'>{props.name}</span>
        {(props.onRemoveMeterData || props.onRemoveMeterData2)
          ? data.map((d, i) =>
            <Tag
              key={`${d.objectType}-${d.objectId}-${d.key}`}
              color={colors.at(i)}
              closable
              onClose={() => {
                  props.onRemoveMeterData && props.onRemoveMeterData(d.objectId ?? 0, d.objectType ?? 0)
                  props.onRemoveMeterData2 && d.key && props.onRemoveMeterData2(d.key)}
                }>
                {d.name}
            </Tag>)
          : null
        }
      </LoadProfileControls>
      {showNoDataForHoliday ?
        <Alert message="Not applicable" type="warning" showIcon closable onClose={() => setShowDataForHoliday(false)} style={{ marginTop: 8, marginLeft: 10, marginRight: 10 }}/> : null}
      {!props.isFetching && props.error ?
        <Alert message={props.error} type="error" showIcon style={{ marginTop: 8, marginLeft: 10, marginRight: 10 }}/> : null}
      <Row className='tab-container'>
        <Col xs={24} sm={24} md={24} lg={12} xl={16}>
          <div className='widget' style={{minHeight: 'calc(100vh - 230px)'}}>
            <div className='header'>
              <span className='subtitle subtitle--bigger-1'>{title}</span>
            </div>
            <div className='body' ref={(ref) => bodyRef.current = ref}>
              {width > 0 && height > 0
              ? (
                <Reloader showReload={!!error && !isFetching} reload={refetch}>
                <ReactECharts
                  key="meter-demand"
                  style={{ width, height }}
                  option={getOption(holidayDays)}
                  notMerge={true}
                  lazyUpdate={false}
                  theme={"theme_name"}
                  showLoading={isFetching}
                  loadingOption={loadingConfig}
                  />
                  {isFetching ? <span style={{ position: 'absolute', zIndex: 10, paddingTop: 60 }}>Please wait, do NOT close </span>: null}
              </Reloader>
              )
              : null}
            </div>
          </div>
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={8}>
          <div className='widget'>
            <div className='header'>
              <span className='subtitle subtitle--bigger-1'>Load Profile</span>
              <Button
                hidden={true}
                size="small"
                type='text'
                onClick={() => exportLoadProfile(props.showTableAsGroup ?? false)}
                icon={<ExportOutlined />} />
            </div>
            <div className='body'>
              <Table
                style={{ alignSelf: 'flex-start', width: '100%', height: '100%'}}
                loading={isFetching}
                className="chart"
                dataSource={tableData}
                columns={columns}
                scroll={{ x: true }}
                pagination={{ position: ['bottomCenter'], showSizeChanger: false, pageSize: 8 }} />
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};
