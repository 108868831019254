import React, { useEffect } from 'react';
import ReactECharts from 'echarts-for-react';
import Reloader from './Reloader';
import {
    TimeDurationType,
    useListMeterEnergyConsumption,
    NodeParams,
} from '../services/meterDataApi';
import {
    lineChartConfig,
    loadingConfig,
 } from './chartConfig';
import {
    getAxisMaxValue,
    getMarkSize,
    getColor,
    getDivider,
    getPrefix,
} from '../utils/Chart';
import { useDashboard } from '../Providers'
import { WidgetProps } from './types';


const EnergyConsumptionChart: React.FC<WidgetProps> = (props) => {

    const dashboard = useDashboard();
    const params = { type: TimeDurationType.hour, ...dashboard.target } as NodeParams;
    const {
        data,
        error,
        isFetching,
        refetch,
    } = useListMeterEnergyConsumption(params);

    useEffect(() => {
        if (props.reloadCount > 0) {
            refetch();
        }
    }, [props.reloadCount]);

    const getOption = () => {
        const divider = getDivider(data?.map(item => item.totalEnergy) ?? []);
        const baseUnitMul = 1000;
        const yAxisName = `${getPrefix(divider * baseUnitMul)}Wh`;
        return {
            ...lineChartConfig,
            yAxis: {
                ...lineChartConfig.yAxis,
                name: yAxisName,
                max: getAxisMaxValue(data?.map(item => item.totalEnergy / divider) || []),
            },
            color: [
                getColor(0),
            ],
            series: [
                {
                  name: 'Energy Consumption',
                  type: 'line',
                  smooth: true,
                  symbol: 'none',
                  data: isFetching || !data ? [] : data.map(item => [
                      new Date(
                          item.dataTime.date.year,
                          item.dataTime.date.month - 1,
                          item.dataTime.date.day,
                          item.dataTime.time.hour,
                          item.dataTime.time.minute,
                          item.dataTime.time.second),
                      (item.totalEnergy / divider).toFixed(3)
                    ])
                    .filter(([ts, _]) => {
                        // Interim solution, will be removed later after YTL fixed, (draw value after 15 mins of each hour)
                        const hour = (ts as Date).getHours();
                        const now = new Date();
                        if (hour === now.getHours() && now.getMinutes() < 15) {
                          return false;
                        }
                        return true;
                      }),
                    markPoint: {
                        data: [
                            { type: 'max', name: 'Max' },
                            { type: 'min', name: 'Min' }
                        ],
                        symbolSize: (value: number, params: Object) => {
                            return getMarkSize(value);
                          }
                    },
                },
              ],
              tooltip: {
                trigger: 'axis',
                appendToBody: true,
            },
           };
    }

    return (
        <Reloader showReload={!!error && !isFetching} reload={refetch}>
            <ReactECharts
                key="widget-1"
                style={{ height: '100%', width: '100%' }}
                option={getOption()}
                notMerge={true}
                lazyUpdate={true}
                theme={"theme_name"}
                showLoading={isFetching}
                loadingOption={loadingConfig}
            />
        </Reloader>
    )

}

export default EnergyConsumptionChart;
