import {
    baseApi,
    Response,
    ListResponse,
    ObjectResponse,
} from './baseApi';
import { DateTimeComponent } from './models';
import type { FetchBaseQueryError } from '@reduxjs/toolkit/query'
import moment from 'moment';
import { DateRange } from '../components/MeterProps';
import { Node } from './projectApi';
import { toDateComponent } from '../utils/converter';

export const enum MeterCommunicationType {
    PLC = 1,
    t485 = 2,
    Zigbee = 3,
    Lora = 4,
    RF = 5,
    G3 = 6,
    GPRS = 7, 
}

export const getMeterTypeName = (typeId: number) => {
    const typeMap: { [key: string]: string; } = {
        '1': 'PLC',
        '2': '485',
        '3': 'Zigbee',
        '4': 'Lora',
        '5': 'RF',
        '6': '3G',
        '7': 'GPRS'
    };
    return typeMap[typeId.toString()] ?? 'Unknown'
}

export const enum TimeDurationType {
    hour = 1,
    day = 2,
    month = 3
}

export const enum MeterDataUsageTier {
    zeroToOneMegaByte = 1,
    oneToThreeMegaByte = 2,
    threeToFiveMegaByte = 3,
    overFiveMegaByte = 4,
}

export interface MeterCommTypeStat {
    readonly commType: MeterCommunicationType;
    readonly count: number;
    readonly onlineCount: number;
    readonly createTime: string;
    readonly updateTime: string;
}

export interface MeterEnergyStat {
    readonly dataTime: DateTimeComponent;
    readonly totalEnergy: number;
}

export interface MeterEnergyByType {
    readonly dataTime: DateTimeComponent;
    readonly production: number;
    readonly consumption: number;
}

export interface MeterDemandStat {
    readonly dataTime: DateTimeComponent;
    readonly totalMaxDemand: number;
}

export interface MeterDataUsage {
    readonly grade: MeterDataUsageTier;
    readonly count: number;
}

export interface MeterByArea {
    readonly platformArea: string;
    readonly num: number;
}

export interface MeterOnlineStats {
    readonly online: number;
    readonly offline: number;
}

export interface MeterPowerDemandStat {
    readonly dataTime: DateTimeComponent;
    readonly kW: number;
    readonly kWh: number;
    readonly kVar: number;
    readonly kVarh: number;
    readonly kWExport: number;
    readonly kWhExport: number;
    readonly kVarExport: number;
    readonly kVarhExport: number;
}

export interface MeterPowerDemandStatRaw {
    readonly dataTime: string;
    readonly kW: number;
    readonly kWh: number;
    readonly kVar: number;
    readonly kVarh: number;
    readonly kWExport: number;
    readonly kWhExport: number;
    readonly kVarExport: number;
    readonly kVarhExport: number;
}

export enum LPStatus {
    Success = 'Success',
    Invalid = 'Invalid',
}
export interface LoadProfile {
    readonly dataTime: DateTimeComponent;
    readonly ogDataTime?: DateTimeComponent;
    readonly val: number;
    readonly demand: number;
    readonly status: LPStatus;
    readonly prevBlock?: LoadProfile;
}

export interface LoadProfileRaw {
    readonly dataTime: DateTimeComponent;
    readonly val: string;
    readonly demand: string;
    readonly status: LPStatus;
}

export interface Meter {
    readonly pMeterId: string;
    readonly disc: string;
    readonly meterNo: string;
    readonly latitude: number;
    readonly longitude: number;
    readonly curstatus: number;
}


export interface TargetNode {
    readonly objid: number;
    readonly objtype: number;
    readonly meterNo?: string;
}

export interface NodeParams {
    readonly objid: number;
    readonly objtype: number;
    readonly type: TimeDurationType;
    readonly rate?: string;
}

export interface PDRateParams {
    readonly rate: string;
    readonly type: TimeDurationType;
    readonly start: string;
    readonly end: string;
    readonly tariff: number;
}

export interface PDNodeParams {
    readonly objid: number;
    readonly objtype: number;
    readonly type: TimeDurationType;
    readonly start: string;
    readonly end: string;
    readonly tariff: number;
}

const prefix = 'smartami/MeterDataService';

export const meterDataApi = baseApi.injectEndpoints({
    endpoints: (build) => ({
        listMeterCommType: build.query<Array<MeterCommTypeStat>, NodeParams>({
            query: (params) => ({
                url: `${prefix}/getAccountCommType`,
                method: 'POST',
                body: {
                    "objid": params.objid || 1,
                    "objtype": params.objtype || 98,
                  },
            }),
            transformResponse: (response: Response<ListResponse<MeterCommTypeStat>>, meta, arg) => response.data.listitem,
        }),
        listMeterEnergyConsumption: build.query<Array<MeterEnergyStat>, NodeParams>({
            query: (params) => ({
                url: `${prefix}/getAccountElectric`,
                method: 'POST',
                body: {
                    "objid": params.objid || 1,
                    "objtype": params.objtype || 98,
                    "datatype":params.type,
                  }
            }),
            transformResponse: (response: Response<ObjectResponse<MeterEnergyStat>>, meta, arg) => response.data.item,
        }),
        listMeterEnergyByType: build.query<Array<MeterEnergyByType>, NodeParams>({
            query: (params) => ({
                url: `${prefix}/energyByType`,
                method: 'POST',
                body: {
                    "objectId": params.objid,
                    "objectType": params.objtype,
                    "dataType":params.type
                  }
            }),
            transformResponse: (response: MeterEnergyByType[], meta, arg) => response,
        }),
        listMeterEnergyByMaxDemand: build.query<Array<MeterEnergyByType>, NodeParams>({
            query: (params) => ({
                url: `${prefix}/energyByMaxDemand`,
                method: 'GET',
                params: {
                    "objectId": params.objid,
                    "objectType": params.objtype,
                    "dataType":params.type
                  }
            }),
            transformResponse: (response: MeterEnergyByType[], meta, arg) => response,
        }),
        listMeterDemand: build.query<Array<MeterDemandStat>, NodeParams>({
            query:  (params) => ({
                url: `${prefix}/getAccountDemand`,
                method: 'POST',
                body: {
                    "objid": params.objid || 1,
                    "objtype": params.objtype || 98,
                    "datatype": params.type,
                  }
            }),
            transformResponse: (response: Response<ObjectResponse<MeterDemandStat>>, meta, arg) => response.data.item,
        }),
        listMeterDemandByRate: build.query<Array<MeterDemandStat>, NodeParams>({
            query:  (params) => ({
                url: `${prefix}/getAccountDemand`,
                method: 'POST',
                body: {
                    "objid": params.objid || 1,
                    "objtype": 44,
                    "disc": params.rate,
                    "datatype": params.type,
                  }
            }),
            transformResponse: (response: Response<ObjectResponse<MeterDemandStat>>, meta, arg) => response.data.item,
        }),
        listMeterEnergyByRate: build.query<Array<MeterEnergyStat>, NodeParams>({
            query:  (params) => ({
                url: `${prefix}/getAccountElectric`,
                method: 'POST',
                body: {
                    "objid": params.objid || 1,
                    "objtype": 44,
                    "disc": params.rate,
                    "datatype": params.type,
                  }
            }),
            transformResponse: (response: Response<ObjectResponse<MeterEnergyStat>>, meta, arg) => response.data.item,
        }),
        listMeterPowerAndDemandByRate: build.query<Array<MeterPowerDemandStat>, PDRateParams>({
            query: (params) => ({
                url: `${prefix}/getPowerAndDemand`,
                method: 'POST',
                body: {
                    "objectType": 44,
                    "disc": params.rate,
                    "dataType": params.type,
                    "startTime": params.start,
                    "endTime": params.end,
                    "tariff": params.tariff,
                  }
            }),
            transformResponse: (response: Response<ListResponse<MeterPowerDemandStatRaw>>, meta, arg) =>
                response.data.listitem
                .map<MeterPowerDemandStat>(item => ({
                    ...item,
                    dataTime: toDateComponent(new Date(item.dataTime)),
                })
            ),
        }),
        listMetersPowerAndDemandByRate: build.query<Array<Array<MeterPowerDemandStat>>, any>({
            queryFn: async (params, queryApi, _extraOptions, fetchWithBQ) => {
                const query =  (rate: string) => ({
                    url: `${prefix}/getPowerAndDemand`,
                    method: 'POST',
                    body: {
                        "objectType": 44,
                        "disc": rate,
                        "dataType": params.type,
                        "startTime": params.start,
                        "endTime": params.end,
                        "tariff": params.tariff,
                      }
                });

                const results = await Promise.all(params.rates.map((rate: string) => fetchWithBQ(query(rate))));
                const errors = results.filter(result => result.error)
                if (errors.length > 0) {
                    return {
                        error: errors.at(0) as FetchBaseQueryError,
                    }
                }

                const apiErrors = results.filter(result => (result as any).data.code !== 200)
                if (apiErrors.length > 0) {
                    const resp = apiErrors.at(0);
                    return {
                        error: {
                            status: 'CUSTOM_ERROR',
                            data: resp?.data,
                            error: (resp?.data as any)?.msg,
                        } as FetchBaseQueryError,
                    }
                }

                return {
                    data: results
                    .map(r => {
                        let x = ((r.data as any).data as ListResponse<MeterPowerDemandStatRaw>);
                        return x.listitem
                        .map<MeterPowerDemandStat>(item => ({
                            ...item,
                            dataTime: toDateComponent(new Date(item.dataTime)),
                        }))
                    })
                };
            },
        }),
        listMetersPowerAndDemandByNode: build.query<Array<Array<MeterPowerDemandStat>>, any>({
            queryFn: async (params, queryApi, _extraOptions, fetchWithBQ) => {
                const query =  (node: PDNodeParams) => ({
                    url: `${prefix}/getPowerAndDemand`,
                    method: 'POST',
                    body: {
                        "objectId": node.objid,
                        "objectType": node.objtype,
                        "dataType": params.type,
                        "startTime": params.start,
                        "endTime": params.end,
                        "tariff": params.tariff,
                      }
                });

                const results = await Promise.all(params.nodes.map((node: PDNodeParams) => fetchWithBQ(query(node))));
                const errors = results.filter(result => result.error)
                if (errors.length > 0) {
                    return {
                        error: errors.at(0) as FetchBaseQueryError,
                    }
                }

                const apiErrors = results.filter(result => (result as any).data.code !== 200)
                if (apiErrors.length > 0) {
                    const resp = apiErrors.at(0);
                    return {
                        error: {
                            status: 'CUSTOM_ERROR',
                            data: resp?.data,
                            error: (resp?.data as any)?.msg,
                        } as FetchBaseQueryError,
                    }
                }

                return {
                    data: results
                    .map(r => {
                        let x = ((r.data as any).data as ListResponse<MeterPowerDemandStatRaw>);
                        return x.listitem
                        .map<MeterPowerDemandStat>(item => ({
                            ...item,
                            dataTime: toDateComponent(new Date(item.dataTime)),
                        }))
                    })
                };
            },
        }),
        getTodayYesterdayPowerAndDemand: build.query<Array<Array<MeterPowerDemandStat>>, string | any>({
            queryFn: async (params, queryApi, _extraOptions, fetchWithBQ) => {
                const getParamForType = (start: string, end: string) => ({
                    url: `${prefix}/getPowerAndDemand`,
                    method: 'POST',
                    body: {
                        "objectId": params.objid,
                        "objectType": params.objtype,
                        "dataType": params.type,
                        "startTime": start,
                        "endTime": end,
                        "tariff": params.tariff,
                      }
                });

                let unit: any = 'day';
                switch(params.datePickType) {
                    case DateRange.Time:
                    case DateRange.Date:
                        unit = 'day';
                        break;
                    case DateRange.Week:
                        unit = 'week';
                        break;
                    case DateRange.Month:
                        unit = 'month';
                        break;
                    case DateRange.Year:
                        unit = 'year';
                        break;
                }
                const requests = [
                    getParamForType(params.start, params.end),
                    getParamForType(
                        moment(params.start).add(-1, unit).utcOffset(0, true).format("YYYY-MM-DD HH:mm:ss"),
                        moment(params.end).add(-1, unit).utcOffset(0, true).format("YYYY-MM-DD HH:mm:ss"),
                    ),
                ];

                const results = await Promise.all(requests.map(r => fetchWithBQ(r)))
                const errors = results.filter(result => result.error)
                if (errors.length > 0) {
                    return {
                        error: errors.at(0) as FetchBaseQueryError,
                    }
                }

                const apiErrors = results.filter(result => (result as any).data.code !== 200)
                if (apiErrors.length > 0) {
                    const resp = apiErrors.at(0);
                    return {
                        error: {
                            status: 'CUSTOM_ERROR',
                            data: resp?.data,
                            error: (resp?.data as any)?.msg,
                        } as FetchBaseQueryError,
                    }
                }

                return {
                    data: results
                        .map(r => {
                            let x = ((r.data as any).data as ListResponse<MeterPowerDemandStatRaw>);
                            return x.listitem
                            .map<MeterPowerDemandStat>(item => ({
                                ...item,
                                dataTime: toDateComponent(new Date(item.dataTime)),
                            }))
                        })
                };
            },
        }),
        listMeterPowerAndDemandByNode: build.query<Array<MeterPowerDemandStat>, PDNodeParams>({
            query: (params) => ({
                url: `${prefix}/getPowerAndDemand`,
                method: 'POST',
                body: {
                    "objectId": params.objid,
                    "objectType": params.objtype,
                    "dataType": params.type,
                    "startTime": params.start,
                    "endTime": params.end,
                    "tariff": params.tariff,
                  }
            }),
            transformResponse: (response: Response<ListResponse<MeterPowerDemandStatRaw>>, meta, arg) =>
                response.data.listitem
                .map<MeterPowerDemandStat>(item => ({
                    ...item,
                    dataTime: toDateComponent(new Date(item.dataTime)),
                })
            ),
        }),
        listRates: build.query<Array<string>, any>({
            query:  (params) => ({
                url: `${prefix}/getNodeList`,
                method: 'POST',
                body: {
                    "objtype": 44,
                  }
            }),
            transformResponse: (response: Response<ListResponse<string>>, meta, arg) => {
                const rateSet = response.data.listitem ? new Set(response.data.listitem) : []
                return Array.from(rateSet).sort();
            },
        }),
        listNodeRegions: build.query<Array<Node>, any>({
            query:  (params) => ({
                url: `${prefix}/getNodeList`,
                method: 'POST',
                body: {
                    "objtype": 33,
                  }
            }),
            transformResponse: (response: Response<ListResponse<Node>>, meta, arg) => {
                const sorted = response.data.listitem.sort((a ,b) => a.disc.localeCompare(b.disc))
                return sorted;
            },
        }),
        listMeterDataUsage: build.query<Array<MeterDataUsage>, NodeParams>({
            query:  (params) => ({
                url: `${prefix}/getAccountFlow`,
                method: 'POST',
                body: {
                    "objid": params.objid || 1,
                    "objtype": params.objtype || 98,
                  }
            }),
            transformResponse: (response: Response<ObjectResponse<MeterDataUsage>>, meta, arg) => response.data.item,
        }),
        listMeterByRegional: build.query<Array<MeterByArea>, NodeParams>({
            query:  (params) => ({
                url: `${prefix}/getAccountArea`,
                method: 'POST',
                body: {
                    "objid": params.objid || 1,
                    "objtype": params.objtype || 98,
                  }
            }),
            transformResponse: (response: Response<ListResponse<MeterByArea>>, meta, arg) => response.data.listitem,
        }),
        getMeterOnlineStats: build.query<MeterOnlineStats, NodeParams>({
            query:  (params) => ({
                url: `${prefix}/getOnlineStats`,
                method: 'GET',
                params: {
                    "objectId": params.objid || 1,
                    "objectType": params.objtype || 98,
                  }
            }),
            transformResponse: (response: MeterOnlineStats, meta, arg) => response,
        }),
        listAllMeter: build.query<Array<Meter>, string | any>({
            query:  (params) => ({
                url: `${prefix}/getAccountMeter`,
                method: 'POST',
                body: {
                    "objid": params.objid || 1,
                    "objtype": params.objtype || 98,
                  }
            }),
            transformResponse: (response: Response<ObjectResponse<Meter>>, meta, arg) => response.data.item,
        }),
        getLoadProfile: build.query<Array<LoadProfile>, string | any>({
            query:  (params) => ({
                url: `${prefix}/getLoadProfile`,
                method: 'GET',
                params: {
                    "dcuId": params.dcuId,
                    "meterId": params.meterId,
                    "start": params.start,
                    "end": params.end,
                    "type": params.type,
                    "timeType": params.timeType,
                    trafficType: parseInt(params.tariffType),
                  }
            }),
            transformResponse: (response: LoadProfileRaw[], meta, arg) => response.map(l => ({ ...l, val: parseFloat(l.val), demand: parseFloat(l.demand), }) ),
        }),
        getLoadProfileByNode: build.query<Array<Array<LoadProfile>>, string | any>({
            queryFn: async (params, queryApi, _extraOptions, fetchWithBQ) => {

                const query =  (node: TargetNode) => ({
                    url: `${prefix}/getLoadProfileByNode`,
                    method: 'GET',
                    params: {
                        "objectId": node.objid,
                        "objectType": node.objtype,
                        "start": params.start,
                        "end": params.end,
                        "type": params.type,
                        "timeType": params.timeType,
                        trafficType: parseInt(params.tariffType),
                    }
                });

                const results = await Promise.all(params.nodes.map((node: TargetNode) => fetchWithBQ(query(node))));
                const errors = results.filter(result => result.error)
                if (errors.length > 0) {
                    return {
                        error: errors.at(0) as FetchBaseQueryError,
                    }
                }
                return {
                    data: results
                        .map(r => (r.data as Array<LoadProfileRaw>)
                        .map<LoadProfile>(l => ({ ...l, val: parseFloat(l.val), demand: parseFloat(l.demand)}))),
                };

            },
        }),
        getLoadProfileByRate: build.query<Array<LoadProfile>, string | any>({
            query:  (params) => ({
                url: `${prefix}/getLoadProfileByProperties`,
                method: 'GET',
                params: {
                    "rate": params.rate,
                    "start": params.start,
                    "end": params.end,
                    "type": params.type,
                    "timeType": params.timeType,
                    trafficType: parseInt(params.tariffType),
                }
            }),
            transformResponse: (response: LoadProfileRaw[], meta, arg) => response.map(l => ({ ...l, val: parseFloat(l.val), demand: parseFloat(l.demand), }) ),
        }),
        getLoadProfileByRegions: build.query<Array<LoadProfile>, string | any>({
            query: (params) => ({
                url: `${prefix}/getLoadProfileByRegions`,
                method: 'GET',
                params: {
                    "region": params.region,
                    "start": params.start,
                    "end": params.end,
                    "type": params.type,
                    "timeType": params.timeType,
                    trafficType: parseInt(params.tariffType),
                }
            }),
        }),
        getImportExportLoadProfile: build.query<Array<Array<LoadProfile>>, string | any>({
            queryFn: async (params, queryApi, _extraOptions, fetchWithBQ) => {

                const getParamForType = (type: string) => ({
                    url: `${prefix}/getLoadProfile`,
                    method: 'GET',
                    params: {
                        "dcuId": params.dcuId,
                        "meterId": params.meterId,
                        "start": params.start,
                        "end": params.end,
                        "timeType": params.timeType,
                        type: `${params.type} ${type}`,
                        trafficType: parseInt(params.tariffType),
                    }
                });

                const requests = [
                    getParamForType('import'),
                    getParamForType('export'),
                ];

                const results = await Promise.all(requests.map(r => fetchWithBQ(r)))

                const errors = results.filter(result => result.error)
                if (errors.length > 0) {
                    return {
                        error: errors.at(0) as FetchBaseQueryError,
                    }
                }
                return {
                    data: results
                        .map(r => (r.data as Array<LoadProfileRaw>)
                        .map<LoadProfile>(l => ({ ...l, val: parseFloat(l.val), demand: parseFloat(l.demand)}))),
                };
            },
        }),
        getTodayYesterdayLoadProfile: build.query<Array<Array<LoadProfile>>, string | any>({
            queryFn: async (params, queryApi, _extraOptions, fetchWithBQ) => {

                const getParamForType = (start: string, end: string) => ({
                    url: `${prefix}/getLoadProfile`,
                    method: 'GET',
                    params: {
                        "dcuId": params.dcuId,
                        "meterId": params.meterId,
                        "start": start,
                        "end": end,
                        "timeType": params.timeType,
                        type: params.type,
                        trafficType: parseInt(params.tariffType),
                    }
                });

                let unit: any = 'day';
                switch(params.datePickType) {
                    case DateRange.Time:
                    case DateRange.Date:
                        unit = 'day';
                        break;
                    case DateRange.Week:
                        unit = 'week';
                        break;
                    case DateRange.Month:
                        unit = 'month';
                        break;
                    case DateRange.Year:
                        unit = 'year';
                        break;
                }
                const requests = [
                    getParamForType(params.start, params.end),
                    getParamForType(
                        moment(params.start).add(-1, unit).toISOString(),
                        moment(params.end).add(-1, unit).toISOString()
                    ),
                ];

                const results = await Promise.all(requests.map(r => fetchWithBQ(r)))

                const errors = results.filter(result => result.error)
                if (errors.length > 0) {
                    return {
                        error: errors.at(0) as FetchBaseQueryError,
                    }
                }
                return {
                    data: results
                        .map(r => (r.data as Array<LoadProfileRaw>)
                        .map<LoadProfile>(l => ({ ...l, val: parseFloat(l.val), demand: parseFloat(l.demand)}))),
                };
            },
        }),
        getSummary: build.query<any, string | any>({
            query:  (params) => ({
                url: `${prefix}/summary`,
                method: 'GET',
                params: {
                    "dcuId": params.dcuId,
                    "meterId": params.meterId,
                    "start": params.start,
                    "end": params.end,
                    "type": params.type,
                    "timeType": params.timeType,
                    trafficType: parseInt(params.tariffType),
                  }
            }),
            transformResponse: (response: any, meta, arg) => response,
        }),
        listMeter: build.query<Node[], { accountId?: string, meterNo?: string, onlineStatus?: number }>({
            query: (params = {}) => {
                return {
                    url:  `${prefix}/meters`,
                    method: 'GET',
                    body: {
                        accountid: params.accountId,
                        terms: params.meterNo,
                        status: params.onlineStatus,
                    },
                }
            },
            transformResponse: (response: Response<Node[]>, meta, arg) => response.data,
        }),
        getMeter: build.query<any, { meterId: number }>({
            query: (params) => {
                return {
                    url:  `${prefix}/meters/${params.meterId}`,
                    method: 'GET',
                }
            },
            transformResponse: (response: Response<Node>, meta, arg) => response,
        }),

    }),
    overrideExisting: false,
});

export const {
    useListMeterCommTypeQuery: useListMeterCommType,
    useListMeterEnergyConsumptionQuery: useListMeterEnergyConsumption,
    useListMeterEnergyByTypeQuery: listMeterEnergyByType,
    useListMeterEnergyByMaxDemandQuery: listMeterEnergyByMaxDemand,
    useListMeterDemandQuery: useListMeterDemand,
    useListMeterDemandByRateQuery: listMeterDemandByRate,
    useListMeterEnergyByRateQuery: listMeterEnergyByRate,
    useListMeterDataUsageQuery: useListMeterDataUsage,
    useListMeterByRegionalQuery: useListMeterByRegional,
    useListRatesQuery: useListRate,
    useListNodeRegionsQuery: useListNodeRegions,
    useListMeterPowerAndDemandByRateQuery: useListMeterPowerAndDemandByRate,
    useListMeterPowerAndDemandByNodeQuery: useListMeterPowerAndDemandByNode,
    useGetMeterOnlineStatsQuery: useGetMeterOnlineStats,
    useListAllMeterQuery: listAllMeter,
    useGetLoadProfileQuery: useGetLoadProfile,
    useGetSummaryQuery: useGetSummary,
    useGetLoadProfileByNodeQuery: useGetLoadProfileByNode,
    useGetLoadProfileByRateQuery: getLoadProfileByRate,
    useGetLoadProfileByRegionsQuery: getLoadProfileByRegions,
    useGetMeterQuery: useGetMeter,
    useGetImportExportLoadProfileQuery: useGetImportExportLoadProfile,
    useGetTodayYesterdayLoadProfileQuery: useGetTodayYesterdayLoadProfile,
    useGetTodayYesterdayPowerAndDemandQuery: getTodayYesterdayPowerAndDemand,
    useListMetersPowerAndDemandByNodeQuery: useListMetersPowerAndDemandByNode,
    useListMetersPowerAndDemandByRateQuery: useListMetersPowerAndDemandByRate,
} = meterDataApi;
