

import React, { useEffect } from 'react';
import ReactECharts from 'echarts-for-react';
import Reloader from './Reloader'
import {
    TimeDurationType,
    useListMeterDemand,
    NodeParams,
} from '../services/meterDataApi';
import {
    barChartConfig,
    loadingConfig,
} from '../components/chartConfig';
import {
    getAxisMaxValue,
    getColor,
} from '../utils/Chart';
import { useDashboard } from '../Providers';
import { WidgetProps } from './types';


const TotalMaxDemandChart: React.FC<WidgetProps> = (props) => {
    const dashboard = useDashboard();
    const params = { type: TimeDurationType.month, ...dashboard.target } as NodeParams;
    const {
        data,
        error,
        isFetching,
        refetch,
    } = useListMeterDemand(params);
    const filtered = data ? data.slice(data.length - 3).filter(d => d.dataTime) : [];
    const maxDemands = filtered.map(item => item.totalMaxDemand);


    useEffect(() => {
        if (props.reloadCount > 0) {
            refetch();
        }
    }, [props.reloadCount]);

    const getOption = () => {
        return {
          ...barChartConfig,
            yAxis: {
                ...barChartConfig.yAxis,
                name: 'kW',
                max: getAxisMaxValue(maxDemands),
            },
            xAxis:
                {
                  ...barChartConfig.xAxis,
                  type: 'category',
                  data: filtered.map(item => new Date(item.dataTime.date.year, item.dataTime.date.month - 1, item.dataTime.date.day) .toLocaleString('default', { month: 'short' })),
                  axisTick: {
                    alignWithLabel: true
                  }
                },
            color: [
                getColor(2),
            ],
            series: [
                {
                  name: 'Efficiency',
                  type: 'bar',
                  barWidth: '60%', // length - x = 4
                  data: maxDemands,
                }
            ],
            tooltip: {
                trigger: 'axis',
                appendToBody: true,
            },
        };
    }

    const onChartReadyCallback = () => {
        // console.log('chart is read');
    }

    return (
        <Reloader showReload={!!error && !isFetching} reload={refetch}>
        <ReactECharts
            key="widget-1"
            style={{ height: '100%', width: '100%' }}
            option={getOption()}
            notMerge={true}
            lazyUpdate={true}
            theme={"theme_name"}
            onChartReady={onChartReadyCallback}
            showLoading={isFetching}
            loadingOption={loadingConfig}
        />
        </Reloader>)

}

export default TotalMaxDemandChart;
