import React, { useCallback, useEffect, useState } from 'react';
import {
  Alert,
  Button,
  Popover,
  Space,
  Spin,
} from 'antd';
import {
  FilterOutlined,
  ReloadOutlined,
} from '@ant-design/icons';
import WidgetGrid, { GridMode, WidgetItem, WidgetTypeDirection } from '../components/Grid';
import EnergyConsumptionChart from '../components/EnergyConsumptionChart';
import DataCommTypeChart from '../components/DataCommTypeChart';
import EnergyEfficiencyChart from '../components/EnergyEfficiencyChart';
import PeakDemandChart from '../components/PeakDemandChart';
import TotalMaxDemandChart from '../components/TotalMaxDemandChart';
import DataUsageChart from '../components/DataUsageChart';
import MeterByRegionalTable from '../components/MeterByRegionalTable';
import MeterNetworkMonitoring from '../components/MeterNetworkMonitoring';
import EnergyChart from '../components/EnergyChart';
import EnergyChartByMaxDemand from '../components/EnergyChartByMaxDemand';
import EnergyProduction from '../components/EnergyProduction';
import { DashboardEditMode, useAuth, useDashboard } from '../Providers';
import { useListWidgets, Widget } from '../services/widgetsApi';
import OnlineRatioChart from '../components/OnlineRatioChart';
import { LoadProfileControls } from '../components/LoadProfileControls';
import { TriangleIcon } from '../icons';


const DashboardScene: React.FC = () => {
  const auth = useAuth();
  const dashboard = useDashboard();
  
  const { data: widgets = [], isFetching, isSuccess } =  useListWidgets(auth.account?.accountid);
  const [widgetItems, setWidgetItems] = useState<WidgetItem[]>([]);
  const [addedWidgetItems, setAddedWidgetItems] = useState<WidgetItem[]>([]);
  const [maxPos, setMaxPos] = useState({ x: 0, y: 0});

  const widegetChangedHandler = (items: WidgetItem[]) => {
    const widgetList = items.map(item => ({ ...item, id: parseInt(item.key), view: '' }));
    dashboard.setWidgets(widgetList);

    if (widgetList.length === 0) {
      return;
    }

    const lastWidget = widgetList.reduce((lastWidget, widget) => widget.y > lastWidget.y || widget.x > lastWidget.y ? widget : lastWidget);
    const newX = lastWidget.x + lastWidget.w;
    const newY = lastWidget.y + lastWidget.h;
    const smallWidth = 4;
    const numberOfCol = 12;
    const isNewRow = newX <= numberOfCol - smallWidth;
    setMaxPos({
      x: isNewRow ? newX : 0,
      y: isNewRow ? lastWidget.y : newY,
    });
  }

  const reloadHandler = (item: WidgetItem) => {
    setWidgetItems(val => val.map(w =>
        w.key === item.key
        ? ({ ...w, props: { ...w.props, reloadCount: (w.props?.reloadCount ?? 0) + 1} })
        : w ))
  };
  
  const collapseHandler = (item: WidgetItem) => {
    setWidgetItems(val => val.map(w =>
        w.key === item.key
        ? ({ ...w, props: { ...w.props, collapsed: !w.props.collapsed }, h: !w.props.collapsed ? 1 : (w.hOriginal ?? 3) * 1 })
        : w ))
  };

  const filterChangeHandler = (item: WidgetItem, start: Date, end: Date) => {
    setWidgetItems(val => val.map(w =>
        w.key === item.key
        ? ({ ...w, props: { ...w.props, start, end } })
        : w ))
  };

  const renderWidgetControl = (item: WidgetItem, index: number) => {
    const showFilter = false;
    return (
      <Space className="widget-controls">
        {showFilter ? <Popover
          content={
            <LoadProfileControls
              isEnabled={isFetching}
              enableDateType={false}
              enableLoadProfileType={false}
              enableTariff={false}
              onChange={(start: Date, end: Date) => {
                filterChangeHandler(item, start, end);
                }
            }/>}
          title="Filter"
          trigger="click"
          placement="left">
          <Button
            size="small"
            type='text'
            onClick={() => {}}
            icon={<FilterOutlined />}
          />
        </Popover> : null}
        {!item.props.collapsed
          ? <Button
              size="small"
              type='text'
              onClick={() => reloadHandler(item)}
              icon={<ReloadOutlined />} />
          : null
        }
        <Button
          className='btn-collapse'
          size="small"
          type='text'
          onClick={() => collapseHandler(item)}
          icon={<TriangleIcon />} />
      </Space>
    );
  }

  useEffect(() => {
    if (isSuccess) {
      setWidgetItems(widgets.map(widget => mapWidgetItem(widget, renderWidgetControl)))
    }
  }, [isSuccess, widgets]);

  useEffect(() => {
    if (dashboard.expandWidgetCount > 0) {
      setWidgetItems(widgets => widgets.map(w => ({ ...w, props: { ...w.props, collapsed: false }, h: w.hOriginal ?? 3 })));
    }
  }, [dashboard.expandWidgetCount]);
  
  useEffect(() => {
    if (dashboard.collapseWidgetCount > 0) {
      setWidgetItems(widgets => widgets.map(w => ({ ...w, props: { ...w.props, collapsed: true }, h: 1 })));
    }
  }, [dashboard.collapseWidgetCount]);

  useEffect(() => {
    if (dashboard.addedWidgetId) {
      let widget: Widget = {
        id: parseInt(dashboard.addedWidgetId),
        name: 'dummy',
        view: 'dummy',
        x: maxPos.x,
        y: maxPos.y,
        w: 4,
        h: 2,
      }
      setAddedWidgetItems([
        mapWidgetItem(widget, renderWidgetControl, false),
      ])
    }
  }, [dashboard.addedWidgetId])

  let mode: GridMode;
  switch (dashboard.mode) {
    case DashboardEditMode.view:
      mode = GridMode.view;
      break;
    case DashboardEditMode.resize:
      mode = GridMode.resizeMove
      break;
    case DashboardEditMode.remove:
      mode = GridMode.remove;
      break;
  }

 return (
  <Spin spinning={dashboard.isSavingWidgets || isFetching}>
    {dashboard.errorMessage ?
    <Alert
      message="Save Failed"
      description={dashboard.errorMessage}
      type="error"
      closable
    />
    : null}
    <WidgetGrid
      mode={mode}
      resetCount={dashboard.discardCount}
      addedItems={addedWidgetItems}
      items={widgetItems}
      onDelete={(item) => console.log('Delete', item.name)}
      onChanged={widegetChangedHandler}
    >
    </WidgetGrid>
  </Spin>
  );
}


const mapWidgetItem = (widget: Widget, renderControl: any, overrideSize: boolean = true): WidgetItem => {

  let newSize = {};
  if (overrideSize) {
    newSize = {
      w: widget.w,
      h: widget.h,
    }
  }

  return {
    ...widgetMap[widget.id],
    hOriginal: widgetMap[widget.id]['h'],
    renderControl: renderControl,
    x: widget.x,
    y: widget.y,
    ...newSize,
    props: { collapsed: false },
  };
}

const widgetMap: Record<string, WidgetItem> = {
  "1" : {
    key: "1",
    name: "Energy Consumption",
    render: (props) => <EnergyConsumptionChart { ...props }/>,
    x: 0,
    y: 0,
    w: 4,
    h: 2,
    direction: WidgetTypeDirection.horizontal,
  },
  "2" : {
    key: "2",
    name: "Communication Types",
    render: (props) => <DataCommTypeChart { ...props }/>,
    x: 4,
    y: 0,
    w: 4,
    h: 2,
    direction: WidgetTypeDirection.horizontal,
  },
  "3": {
    key: "3",
    name: "Max Energy",
    render: (props) => <EnergyEfficiencyChart { ...props }/>,
    x: 8,
    y: 0,
    w: 4,
    h: 2,
    direction: WidgetTypeDirection.horizontal,
  },
  "4": {
    key: "4",
    name: "Peak Demand Finder",
    render: (props) => <PeakDemandChart { ...props }/>,
    x: 0,
    y: 1,
    w: 4,
    h: 2,
    direction: WidgetTypeDirection.horizontal,
  },
  "5": {
    key: "5",
    name: "Max Demand",
    render: (props) => <TotalMaxDemandChart { ...props }/>,
    x: 4,
    y: 1,
    w: 4,
    h: 2,
    direction: WidgetTypeDirection.horizontal,
  },
  "6": {
    key: "6",
    name: "Data Usage System",
    render: (props) => <DataUsageChart  { ...props }/>,
    x: 8,
    y: 1,
    w: 4,
    h: 2,
    direction: WidgetTypeDirection.horizontal,
  },
  "7": {
    key: "7",
    name: "Total Meter Of Regional",
    render: (props) => <MeterByRegionalTable  { ...props }/>,
    x: 0,
    y: 2,
    w: 4,
    h: 4,
    direction: WidgetTypeDirection.vertical,
  },
  "8": {
    key: "8",
    name: "Network Monitoring",
    render: (props) => <MeterNetworkMonitoring { ...props }/>,
    x: 4,
    y: 2,
    w: 4,
    h: 4,
    direction: WidgetTypeDirection.vertical,
  },
  "9": {
    key: "9",
    name: "Energy",
    render: (props) => <EnergyChart { ...props }/>,
    x: 4,
    y: 2,
    w: 4,
    h: 2,
    direction: WidgetTypeDirection.horizontal,
  },
  "10": {
    key: "10",
    name: "Production",
    render: (props) => <EnergyProduction { ...props }/>,
    x: 4,
    y: 2,
    w: 4,
    h: 2,
    direction: WidgetTypeDirection.vertical,
  },
  "11" : {
    key: "11",
    name: "Online Ratio",
    render: (props) => <OnlineRatioChart { ...props }/>,
    x: 4,
    y: 0,
    w: 4,
    h: 2,
    direction: WidgetTypeDirection.horizontal,
  },
  "12": {
    key: "12",
    name: "Energy by Max Demand",
    render: (props) => <EnergyChartByMaxDemand { ...props }/>,
    x: 4,
    y: 2,
    w: 4,
    h: 2,
    direction: WidgetTypeDirection.horizontal,
  },
}


export default DashboardScene;
