

import React, { useEffect } from 'react';
import ReactECharts from 'echarts-for-react';
import { WidgetProps } from './types';
import Reloader from './Reloader';
import {
    TimeDurationType,
    useListMeterEnergyConsumption,
    NodeParams,
} from '../services/meterDataApi';
import {
  barChartConfig,
  loadingConfig,
} from '../components/chartConfig';
import {
  getAxisMaxValue,
  getColor,
  getDivider,
  getPrefix,
} from '../utils/Chart';
import { useDashboard } from '../Providers'


const EnergyEfficiencyChart: React.FC<WidgetProps> = (props) => {
    const dashboard = useDashboard();
    const params = { type: TimeDurationType.month, ...dashboard.target } as NodeParams;
    const {
      data,
      error,
      isFetching,
      refetch,
  } = useListMeterEnergyConsumption(params);

    useEffect(() => {
      if (props.reloadCount > 0) {
          refetch();
      }
    }, [props.reloadCount]);

    const getOption = () => {
      const filtered = data ? data.slice(data.length - 3).filter(d => d.dataTime) : [];
      const energyEffs = filtered.map(item => item.totalEnergy);
      const divider = getDivider(energyEffs);
      const baseUnitMul = 1000;
      const yAxisName = `${getPrefix(divider * baseUnitMul)}Wh`;

      return {
        ...barChartConfig,
        yAxis: {
          ...barChartConfig.yAxis,
          name: yAxisName,
          max: getAxisMaxValue(energyEffs.map(i => i / divider)),
        },
        xAxis: {
          ...barChartConfig.xAxis,
          type: 'category',
          data: filtered.map(item => new Date(item.dataTime.date.year, item.dataTime.date.month - 1, item.dataTime.date.day).toLocaleString('default', { month: 'short' })),
          axisTick: {
            alignWithLabel: true
          },
        },
        color: [
          getColor(1),
        ],
        series: [
          {
            name: 'Efficiency',
            type: 'bar',
            barWidth: '60%',
            data: energyEffs.map(i => (i / divider).toFixed(2)),
          }
        ],
        tooltip: {
          trigger: 'axis',
          appendToBody: true,
        },
      };
    }

    const onChartReadyCallback = () => {
    }

    return (
      <Reloader showReload={!!error && !isFetching} reload={refetch}>
        <ReactECharts
            key="widget-1"
            style={{ height: '100%', width: '100%' }}
            option={getOption()}
            notMerge={true}
            lazyUpdate={true}
            theme={"theme_name"}
            onChartReady={onChartReadyCallback}
            showLoading={isFetching}
            loadingOption={loadingConfig}
        />
        </Reloader>
      )

}

export default EnergyEfficiencyChart;
