import moment from 'moment'
import React, {
    useEffect,
    useState,
} from 'react';
import {
    Col,
    Row,
    Spin,
} from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { useGetSummary } from '../services/meterDataApi';
import {
    DatePickerType,
    DateRange,
    MeterProps,
    getDateRangeParam,
    Tariff,
    tariffTypes,
} from './MeterProps';
import Reloader from './Reloader';
import { Amount } from './Amount';
import { LoadProfileControls } from './LoadProfileControls';
import { Widget } from './Widget';

import './LoadProfileSummary.css';


const LoadProfileSummary: React.FC<MeterProps> = (props) => {

    let [tariff, setTariff] = useState(Tariff.All);
    let [datePickType, setDatePickType] = useState<DatePickerType>(DateRange.Date);
    let [startDate, setStartDate] = useState(moment().startOf('day').toDate());
    let [endDate, setEndDate] = useState(moment().endOf('day').toDate());
    let [startToLoad, setStartToLoad] = useState(false);
    let [kwh, setKwh] = useState<number | undefined>();
    let [kvarh, setKvarh] = useState<number | undefined>();
    let [maxImportKw, setMaxImportKw] = useState<number | undefined>();
    let [maxImportKvar, setMaxImportKvar] = useState<number | undefined>();
    let [maxExportKw, setMaxExportKw] = useState<number | undefined>();
    let [maxExportKvar, setMaxExportKvar] = useState<number | undefined>();
    let [note, setNote] = useState('');

    const params = {
        dcuId: props.dcuId,
        meterId: props.meterId,
        start: moment(startDate).utcOffset(0, true).format(),
        end: moment(endDate).add(datePickType === DateRange.Date ? 1 : 0, 'second').utcOffset(0, true).format(),
        timeType: getDateRangeParam(datePickType),
        type: 'kwh+import',
        tariffType: tariff,
    };

    const {
        data,
        error,
        isSuccess,
        isFetching,
        refetch,
    } = useGetSummary(params, { skip: !startToLoad });

    const optionsForkWh = {
        minimumFractionDigits: 3,
        maximumFractionDigits: 3,
    };

    useEffect(() => {
        if (isSuccess && !isFetching && data) {
            setKwh(data.accuImportKwh);
            setKvarh(data.accuImportKvarh);
            setMaxImportKw(data.maxImportKw);
            setMaxImportKvar(data.maxImportKvar);
            setMaxExportKw(data.maxExportKw);
            setMaxExportKvar(data.maxExportKvar);
            setStartToLoad(false);

            const item = Object.values(tariffTypes).find(item => item.value === tariff);
            if (item) {
                let dt;
                if (data.dataTime) {
                dt = new Date(
                    data.dataTime.date.year,
                    data.dataTime.date.month - 1,
                    data.dataTime.date.day,
                    data.dataTime.time.hour,
                    data.dataTime.time.minute,
                    data.dataTime.time.second,
                    )
                }
                setNote(`${item.name} - ${dt ? dt.toLocaleString('en-GB') : ''}`)
            }
        }
      },
    [isFetching, isSuccess, data]);

    useEffect(() => {
        if (props.meterId !== undefined && props.dcuId !== undefined) {
            setKwh(undefined);
            setKvarh(undefined);
            setMaxImportKw(undefined);
            setMaxImportKvar(undefined);
            setMaxExportKw(undefined);
            setMaxExportKvar(undefined);
            setStartToLoad(true);
        }
    }, [props.meterId, props.dcuId])

    return (
        <>
            <LoadProfileControls
                isEnabled={isFetching}
                enableDateType={false}
                enableTariff={true}
                enableLoadProfileType={false}
                onChange={(start: Date, end: Date, lpType: string, dateRangeType: DateRange, tariffType: Tariff) => {
                    setStartDate(start);
                    setEndDate(end);
                    setTariff(tariffType);
                    setStartToLoad(true);
                    }
                }/>
            <Row className='grid-container' style={{ marginTop: 10 }}>
                <Col xs={24} sm={24} md={12} lg={12} xl={10}>
                    <Widget title='Energy'>
                        <Reloader showReload={!!error && !isFetching} reload={refetch}>
                            <div className='chart amount-wrap'>
                                <Amount
                                    title="Accumulative Active Energy"
                                    value={kwh !== undefined ? kwh.toLocaleString(undefined, optionsForkWh) : "-"}
                                    unit="kWh"
                                    supplement={note}
                                />
                            </div>
                            <Spin spinning={isFetching} className="amount-spin" indicator={<LoadingOutlined style={{ fontSize: 28 }} spin />}/>
                        </Reloader>
                    </Widget>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={10}>
                    <Widget title='Demand'>
                        <Reloader showReload={!!error && !isFetching} reload={refetch}>
                            <div className='chart amount-wrap'>
                                <Amount
                                    title="Max Import Active Demand"
                                    value={maxImportKw !== undefined ? maxImportKw.toLocaleString(undefined, optionsForkWh) : "-"}
                                    unit="kW"
                                    supplement={note}
                                />
                                <Amount
                                    title="Max Export Active Demand"
                                    value={maxExportKw !== undefined ? maxExportKw.toLocaleString(undefined, optionsForkWh) : "-"}
                                    unit="kW"
                                    supplement={note}
                                />
                            </div>
                            <Spin spinning={isFetching} className="amount-spin" indicator={<LoadingOutlined style={{ fontSize: 28 }} spin />}/>
                        </Reloader>
                    </Widget>
                </Col>
            </Row>
        </>
    );
}

export default LoadProfileSummary;
