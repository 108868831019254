import React, { useEffect, useState, useRef } from 'react';
import moment from 'moment';
import ReactECharts from 'echarts-for-react';
import {
  Alert,
  Button,
  DatePicker,
  Select,
  Space,
  notification,
} from 'antd';
import {
  lineChartConfig,
  loadingConfig,
} from './chartConfig';
import {
  useListMeterPowerAndDemandByNode,
  LPStatus,
  LoadProfile,
  MeterPowerDemandStat,
} from '../services/meterDataApi';
import {
  getMarkSize,
  renderInfo,
  renderInfo2,
  formatLPValue,
} from '../utils/Chart';
import Reloader from './Reloader';
import { Plus2Icon } from '../icons';
import {
  MeterProps,
  DatePickerType,
  DateRange,
  groupLoadProfile,
  dateRageMap,
  getxLabel,
  LoadProfileType,
  tariffTypes,
  Tariff,
  getDateRangeParam2,
  filterXAxis,
  getDateStringForInterval,
  getPowerDemandVal,
  filterByTariff,
  findStartIndex,
} from './MeterProps';
import { useContainerDimensions } from '../hooks';
import { EChartsOption } from 'echarts';
import { toDate, toDateComponent } from '../utils/converter';


const { Option } = Select;

const loadProfileTypesByDirection: {
  [Key in string]: LoadProfileType;
} = {
  'Import kWh': { name: 'Import kWh', value: "kwh import" },
  'Import kVarh': { name: 'Import kVar', value: "kvarh import" },
  'Import kW': { name: 'Import kW', value: "kw import" },
  'Import kVar': { name: 'Import kVar', value: "kvar import" },
  'Export kWh': { name: 'Export kWh', value: "kwh export" },
  'Export kVarh': { name: 'Export kVar', value: "kvarh export" },
  'Export kW': { name: 'Export kW', value: "kw export" },
  'Export kVar': { name: 'Export kVar', value: "kvar export" },
};

export const Comparison: React.FC<MeterProps> = (props) => {

  let [lpType, setLpType] = useState("Import kWh");
  let [tariff, setTariff] = useState(Tariff.All);
  let [datePickType, setDatePickType] = useState<DatePickerType>(DateRange.Date);
  let [name, setName] = useState(moment().startOf('day').format('YYYY-MM-DD'));
  let [startDate, setStartDate] = useState(moment().startOf('day').toDate());
  let [endDate, setEndDate] = useState(moment().endOf('day').toDate());
  let [selectedDate, setSelectedDate] = useState(moment().endOf('day').toDate());
  let [lpData, setLpData] = useState<{
    [k in string]: Array<Array<number | Date | null>>;
  }>({});
 
  let [startToLoad, setStartToLoad] = useState(false);
  let [title, setTitle] = useState('Comparison');
  let bodyRef = useRef<HTMLDivElement | null>();
  const { width, height } = useContainerDimensions(bodyRef);
  let [echartOption, setEchartOption] = useState<EChartsOption>({});
  let [showNoDataForHoliday, setShowDataForHoliday] = useState(false);
  let [dataTemplate, setDataTemplate] = useState<Array<Date>>([]);

  const params = {
    objid: props.meterId,
    objtype: 10,
    start: moment(startDate).add([DateRange.Date, DateRange.Time].some(t => t === datePickType) ? 1 : -1, 'second').utcOffset(0, true).format("YYYY-MM-DD HH:mm:ss"),
    end: moment(endDate).add([DateRange.Date, DateRange.Time].some(t => t === datePickType) ? 1 : 0, 'second').utcOffset(0, true).format("YYYY-MM-DD HH:mm:ss"),
    type: getDateRangeParam2(datePickType),
    tariff: parseInt(tariff.valueOf()),
  };

  const {
    data, error, isFetching, isSuccess, refetch,
  } = useListMeterPowerAndDemandByNode({ ...params }, { skip: !startToLoad });

  useEffect(() => {
    setLpData({});
  }, [lpType, datePickType, tariff, props.meterNo]);

  useEffect(() => {
    if (isSuccess && !isFetching && data) {
      const transdata = recompute(data, datePickType, lpType, dataTemplate)

      // const useStartFromZero = false; //['Import kWh', 'Import kVarh', 'Export kWh', 'Export kVarh'].some(n => lpType == n);
      // const preBlockDict = transdata.find(lp => !!lp.prevBlock)?.prevBlock;
      // const loadProfileData = groupLoadProfile(data, datePickType, startDate, lpType.split(' ')[1])
      //   .filter(([ts]) => filterByTariff(ts as Date, tariff))
      //   .map(useStartFromZero && preBlockDict ? startFromZero(preBlockDict.val) : (r) => r);
      // setRawLpData({
      //   ...rawLpData,
      //   [`${name}(${Object.values(tariffTypes).find(a => a.value === tariff)?.name})`]: data
      // });
      const now = new Date();
      const needToApplyFilterByTariff = [DateRange.Date, DateRange.Time].some(t => t === datePickType);
      setLpData({
        ...lpData,
        [`${name}(${Object.values(tariffTypes).find(a => a.value === tariff)?.name})`]: transdata.map(lp => [toDate(lp.dataTime), lp.val]).filter(([ts]) => needToApplyFilterByTariff ? filterByTariff(ts as Date, tariff) : true).filter(([ts]) => (ts as Date).valueOf() <= now.valueOf())
      });
      setStartToLoad(false);

      setShowDataForHoliday(transdata.length === 0);
    }
  }, [isFetching, isSuccess, data, datePickType, lpType, dataTemplate, tariff]);


  useEffect(() => {
    if (!datePickType) {
      return;
    }

    const template = Array.from({ length: dateRageMap[datePickType](startDate) }, (v, i) => {
      const ts = getDateStringForInterval(datePickType, startDate, i, false);
      return ts;
    });
    setDataTemplate(template);

  }, [datePickType, startDate]);

  useEffect(() => {
    if (isSuccess) {
      setTitle(`Comparison of Meter No.${props.meterNo}`);
    }
  }, [isSuccess, props.meterNo]);

  useEffect(() => {
    setStartToLoad(true);
  }, [props.meterNo]);

  useEffect(() => {
    const value = moment(selectedDate);
    let dateStr = '';

    switch (datePickType) {
      case DateRange.Time:
      case DateRange.Date:
        setStartDate(value.startOf('day').toDate());
        setEndDate(value.endOf('day').toDate());
        dateStr = value.format('YYYY-MM-DD');
        break;
      case DateRange.Week:
        setStartDate(value.startOf('week').toDate());
        setEndDate(value.endOf('week').toDate());
        dateStr = value.format('YYYY-ww');
        break;
      case DateRange.Month:
        setStartDate(value.startOf('month').toDate());
        setEndDate(value.endOf('month').toDate());
        dateStr = value.format('YYYY-MM');
        break;
      case DateRange.Year:
        setStartDate(value.startOf('year').toDate());
        setEndDate(value.endOf('year').toDate());
        dateStr = value.format('YYYY');
        break;
    }

    setName(dateStr);

  }, [datePickType, selectedDate]);

  function mapWithTemplate(lps: Array<LoadProfile>, dataTemplate: Array<Date>) {
    let nd: Array<LoadProfile> = []
    let dataIndex = findStartIndex(lps, dataTemplate);
    if (dataTemplate && lps.length > 0) {
        nd = dataTemplate.map(t => {
        if (dataIndex >= lps.length) {
          return {
            dataTime: toDateComponent(t),
            val: null,
            demand: null,
            status: LPStatus.Success,
          };
        }
        // console.log(item.name, dataIndex);
        const lp = lps[dataIndex];
        if (t.getFullYear() === lp.dataTime.date.year &&
        t.getMonth() === lp.dataTime.date.month - 1 &&
        t.getDate() == lp.dataTime.date.day &&
        t.getHours() == lp.dataTime.time.hour && 
        t.getMinutes() == lp.dataTime.time.minute) {
          dataIndex++;
          return lp;
        }
        
        return {
          dataTime: toDateComponent(t),
          val: null,
          demand: null,
          status: LPStatus.Success,
        };
      }) as Array<LoadProfile>;
    }
    return nd;
  }

  function recompute(data: Array<MeterPowerDemandStat>, dateType: string, lpType: string, template: Date[]) {
    // filter case of holiday in 15 min, hour
    const isDay = (dateType === DateRange.Time) || (dateType === DateRange.Date);

    const filteredLps = isDay && data.length === 1 && data[0].dataTime.time.hour === 0 && data[0].dataTime.time.minute === 0 ? [] : data;
    const converted = filteredLps.map<LoadProfile>(d => {
        return {
          dataTime: d.dataTime,
          val: getPowerDemandVal(d, lpType),
          demand: getPowerDemandVal(d, lpType),
          status: LPStatus.Success,
        };
    });
    return mapWithTemplate(converted, template);
  }


  const renderTariffType = () => {
    return <Select
      disabled={isFetching}
      value={tariff}
      size='small'
      showSearch
      style={{ width: 100 }}
      placeholder="Search to Select"
      optionFilterProp="children"
      filterOption={(input, option) => (option?.value as string).toLowerCase().indexOf(input.toLowerCase()) >= 0}
      filterSort={(optionA, optionB) => (optionA?.value as string).toLowerCase().localeCompare((optionB.value as string).toLowerCase())}
      onSelect={(val: string) => setTariff(val as Tariff)}
    >
      {Object.keys(tariffTypes).map(key => <Option key={key} value={tariffTypes[key].value}>{tariffTypes[key].name}</Option>)}
    </Select>;
  };

  const renderLoadProfileType = () => {
    return <Select
      value={lpType}
      size='small'
      showSearch
      style={{ width: 200 }}
      disabled={isFetching}
      placeholder="Search to Select"
      optionFilterProp="children"
      filterOption={(input, option) => (option?.value as string).toLowerCase().indexOf(input.toLowerCase()) >= 0}
      filterSort={(optionA, optionB) => (optionA?.value as string).toLowerCase().localeCompare((optionB.value as string).toLowerCase())}
      onSelect={(val: string) => setLpType(val)}
    >
      {Object.keys(loadProfileTypesByDirection).map(name => <Option key={name} value={name}>{name}</Option>)}
    </Select>;
  };

  const onClickAdd = () => {
    if (Object.keys(lpData).length >= 5) {
      notification['error']({
        message: 'Cannot Compare',
        description: 'Reach maximum number of load profile, 5',
      });
      return;
    }
    setStartToLoad(true);
  };


  const getOption = (holidays?: Set<number>) => {

    const extendAtStart = false;// (datePickType ===  DateRange.Time || datePickType ===  DateRange.Date);

    const maxDemandFormatter = (params: any | Array<any>, ticket: string, callback: (ticket: string, html: string) => void) => {
      const getDetail = (serie: any) => {
        // TODO: Interrim to capture
        const data = lpData[serie.seriesName];
        const maxDemandTs = (extendAtStart ? [[0, 0], ...data] : data).filter(([ts]) => (holidays ? holidays.has((ts as Date).getDate()) : true))[serie.dataIndex][2] as Date;
        return maxDemandTs ? maxDemandTs.toLocaleTimeString('en-GB', { timeStyle: 'short' }) : '-';
      }
      if (Array.isArray(params)) {
        return renderInfo(params[0].axisValue, params, getDetail);
      }
      return renderInfo(params.axisValue, [params], getDetail);
    }

    const precisionFormatter = (params: any | Array<any>, ticket: string, callback: (ticket: string, html: string) => void) => {
      function wrapper(val: number) {
        return formatLPValue(val, lpType)
      }
      if (Array.isArray(params)) {
        return renderInfo2(params[0].axisValue, params, () => "", wrapper);
      }
      return renderInfo2(params.axisValue, [params], () => "", wrapper);
    }

    return {
      ...lineChartConfig,
      yAxis: {
        ...lineChartConfig.yAxis,
        name: lpType,
        // max: getAxisMaxValue(Object.values(lpData).flatMap(a => a).map(([ts, val, ogTs]) => {
        //   return val as unknown as number;
        // })),
      },
      xAxis: {
        ...lineChartConfig.xAxis,
        type: 'category',
        boundaryGap: false,
        data: Array.from({ length: dateRageMap[datePickType](startDate) }, (v, i) => {
          return getxLabel(datePickType, startDate, i, extendAtStart);
        }).filter((label, i) => {
          return filterXAxis(i, startDate, datePickType, tariff, holidays);
        })
      },
      color: [
        '#FF9B00',
        '#DBE369',
        '#102B4A',
        '#47C4EB',
        '#5E2726',
      ],
      series: Object.keys(lpData).map(key => {

        let markPointProps = {
          markPoint: {
            data: [
              {
                type: 'max',
                name: 'Max'
              },
            ],
            symbolSize: (value: Array<number>, params: Object) => {
              return getMarkSize(value ? value[1] : value);
            },
            // label: {
            //   formatter: (params: any) => formatLPValue(params.value, lpType),
            // }
          },
        };
        /*
        if (lpData[key]) {
          const maxIndex = lpData[key]
            .filter(([ts]) => (holidays ? holidays.has((ts as Date).getDate()) : true))
            .filter(i => i[3] !== null && !Number.isNaN(i[3])).reduce<number>((maxIndex, rec, index, arr) => {
            const [ts, val, demandTs, demand] = rec;
            return demand !== null && (demand > (arr[maxIndex][3] ?? 0)) ? index : maxIndex;
          }, 0);
          const maxItem = lpData[key][maxIndex];

          markPointProps = {
            markPoint: {
              data: [
                {
                  coord: [
                    (extendAtStart ? maxIndex + 1 : maxIndex), maxItem[1],
                  ],
                  value: maxItem[1],
                }
              ],
              symbolSize: (value: Array<number>, params: Object) => {
                return getMarkSize(value ? value[1] : value);
              },
            },
          }
        }*/

        let lpsNum = (isFetching || !lpData[key]) ? [] : lpData[key].filter(([ts]) => (holidays ? holidays.has((ts as Date).getDate()) : true)).map<number>(([_, val]) => val as number);
        let dataStr = lpsNum.map(val => formatLPValue(val as number, lpType));
        let data = dataStr.map(d => parseFloat(d));

        // console.log('raw      ', lpsNum);
        // console.log('round str', dataStr);
        // console.log('round flo', data);

        if (extendAtStart) {
          data = [0, ...data];
        }
        return {
          name: key,
          type: 'line',
          smooth: true,
          data,
          ...markPointProps,
        };
      }),
      tooltip: {
        trigger: 'axis',
        appendToBody: true,
        // formatter: precisionFormatter,
      },
      legend: {
        data: Object.keys(lpData)
      },
    };
  };

  let holidayDays;

  if (tariff === Tariff.Holiday && data !== undefined) {
    //TODO: Need refactor
    holidayDays = new Set(
      [
        ...recompute(data, datePickType, lpType, dataTemplate),
      ].filter(i => i.status === LPStatus.Success).map(i => i.dataTime.date.day)
    );
  }

  return (
    <>
      <Space style={{ marginBottom: 0, marginLeft: 10, marginRight: 10, justifyContent: 'flex-end' }} wrap>
        {renderTariffType()}
        {renderLoadProfileType()}
        <Select
          disabled={isFetching}
          defaultValue={datePickType}
          onSelect={(val: DatePickerType) => setDatePickType(val)}
          size="small"
        >
          <Option value="time">Date(15-minutes)</Option>
          <Option value="date">Date(Hour)</Option>
          <Option value="week">Week</Option>
          <Option value="month">Month</Option>
          <Option value="year">Year</Option>
        </Select>
        <DatePicker
          size='small'
          picker={datePickType === 'time' ? 'date' : datePickType}
          disabled={isFetching}
          defaultValue={moment(startDate)}
          onChange={(value, valStr) => {
            if (value) {
              setSelectedDate(value.toDate());
            }
          }} />
        <Button
          type='text'
          size='small'
          disabled={isFetching}
          icon={<Plus2Icon size={30} />}
          onClick={() => onClickAdd()} />
      </Space>
      {showNoDataForHoliday ?
          <Alert message="Not applicable" type="warning" showIcon closable onClose={() => setShowDataForHoliday(false)} style={{ marginTop: 8, marginLeft: 10, marginRight: 10 }}/> : null}
      { error !== undefined ?
        <Alert message={"Something went wrong"} type="error" showIcon style={{ marginTop: 8, marginLeft: 10, marginRight: 10 }}/> : null}
      <div className='widget' style={{ flex: 1, margin: 10, height: '100vh' }}>
        <div className='header'>
          <span className='subtitle subtitle--bigger-1'>{title}</span>
        </div>
        <div className='body' ref={(ref) => bodyRef.current = ref}>
          {width > 0 && height > 0 ? (
            <Reloader showReload={!!error && !isFetching} reload={refetch}>
            <ReactECharts
              key="meter-demand"
              style={{ height, width }}
              option={getOption(holidayDays)}
              notMerge={true}
              lazyUpdate={true}
              theme={"theme_name"}
              showLoading={isFetching}
              loadingOption={loadingConfig} />
          </Reloader>
          ) : null}
        </div>
      </div>
    </>
  );
};
