import React, { useEffect } from 'react';
import ReactECharts from 'echarts-for-react';
import Reloader from './Reloader';
import { useDashboard } from '../Providers'
import {
    useListMeterDataUsage,
    NodeParams,
} from '../services/meterDataApi';
import {
    loadingConfig,
 } from './chartConfig';
 import { WidgetProps } from './types'

const DataUsageChart: React.FC<WidgetProps> = (props) => {
    const dashboard = useDashboard();
    const params = { ...dashboard.target } as NodeParams;
    const {
        data,
        error,
        isFetching,
        refetch,
    } = useListMeterDataUsage(params);

    useEffect(() => {
        if (props.reloadCount > 0) {
            refetch();
        }
    }, [props.reloadCount]);

    const getUsageTierName = (typeId: number) => {
        const typeMap: { [key: string]: string; } = {
            '1': '0-1M',
            '2': '1.0-3.0M',
            '3': '3.0-5.0M',
            '4': '>5.0M',
        };
        return typeMap[typeId.toString()] ?? 'Unknown'
    }

    const getOption = () => {
        return {
            tooltip: {
                trigger: 'item',
                appendToBody: true,
            },
            legend: {
                orient: 'vertical',
                left: 'right',
                bottom: 0,
                textStyle: {
                    fontFamily: 'IBM Plex Sans Thai Looped',
                    fontSize: 9,
                    fontStyle: 'normal',
                    fontHeight: 400,
                    lineHeight: 15,
                },
            },
            color: [
                '#FF9B00',
                '#FF6A00',
                '#D2DC44',
                '#47C4EB',
                '#5E2726',
            ],
            series: [
                {
                    name: 'Data Usage',
                    type: 'pie',
                    radius: '70%',
                    data: isFetching || !data ? [] : data.map(item => ({ value: item.count, name: getUsageTierName(item.grade) })).filter(item => item.value > 0),
                    emphasis: {
                        itemStyle: {
                            shadowBlur: 10,
                            shadowOffsetX: 0,
                            shadowColor: 'rgba(0, 0, 0, 0.5)'
                        }
                    }
                }
            ]
        };
    }

    const onChartReadyCallback = () => {
    }

    return (
        <Reloader showReload={!!error && !isFetching} reload={refetch}>
        <ReactECharts
            key="widget-2"
            style={{ height: '100%', width: '100%' }}
            option={getOption()}
            notMerge={true}
            lazyUpdate={true}
            theme={"theme_name"}
            onChartReady={onChartReadyCallback}
            showLoading={isFetching}
            loadingOption={loadingConfig}
        />
        </Reloader>)

}

export default DataUsageChart;
